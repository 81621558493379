import { Component, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import {
  UserService,
  ProductService,
  ContentService,
  EventsService,
  EventBusService,
  OrderService,
  WindowService,
  StorageService,
  StorageKeys,
  environment,
  CheckoutService,
} from "@mypxplat/xplat/core";
import { filter, take, takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService, AvoService, BetaService, FirebaseService } from "@mypxplat/xplat/web/core";
import { SphereHomeBaseComponent } from "@mypxplat/xplat/features";
import { NgxSpinnerService } from "ngx-spinner";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  RecentOrderComponent,
  RedeemProductsComponent,
  RegisterProductComponent,
  TermsofuseComponent,
  RegisterSuccessSplashComponent,
  StudiooneGracePeriodComponent,
  SphereSubscribeStripeComponent,
  MissedPaymentModalComponent,
} from "../modals";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment/moment";
import { AtomextrasRedeemComponent } from "../atomextras-redeem/atomextras-redeem.component";

@Component({
  selector: "myp-dashboard-home",
  templateUrl: "dashboard-home.component.html",
})
export class DashboardHomeComponent extends SphereHomeBaseComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public displayedSection: string = "News & Updates";
  public needsMoreData: boolean;
  public selectedVimeoID: string;
  public gettingS1Demo: boolean;
  public gettingNotionDemo: boolean;
  public hasS1App: any = false;
  public shouldSeeS1Demo: any = false;
  public hasFree: any = false;
  public hasProDemo: any = false;
  public hasNotion: any = false;
  public hasNotionDemo: any = false;
  public daysLeft: any = false;
  public redeemingMB: boolean = false;
  public termsResult: any = {};
  @ViewChild("atomExtrasCmp") atomExtrasCmp: AtomextrasRedeemComponent;

  constructor(
    userService: UserService,
    private _router: Router,
    public appService: AppService,
    public productService: ProductService,
    public contentService: ContentService,
    public eventsService: EventsService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    private spinner: NgxSpinnerService,
    private _translate: TranslateService,
    public eventBusService: EventBusService,
    public orderService: OrderService,
    public windowService: WindowService,
    public avoService: AvoService,
    public storage: StorageService,
    public betaService: BetaService,
    public fbService: FirebaseService,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService, productService, contentService, eventsService);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.appService.title = this.user.active_subscription ? this.env.studioOnePlusBrandName + ": Home" : "PreSonus: Home";
    if (!this.user) this.spinner.show();
    if (!this.userService.gettingUserDetails) {
      this.userService.getUserDetails(false).subscribe({
        next: (result) => {
          this.spinner.hide();
          if (result.subscription?.status == "canceled" && result.subscription?.cancel_reason == "terminate bluesnap" && !result.subscription?.schedule_subscription_id) {
            if (this._router.url != "/account/plan") {
              const modal = this.appService.showModal(MissedPaymentModalComponent, { size: "lg", backdrop: "static" });
              modal.componentInstance.type = "terminate bluesnap";
            }
          } else if (result.subscription?.missed_payment) {
            if (this._router.url != "/account/plan") {
              const modal = this.appService.showModal(MissedPaymentModalComponent, { size: "lg", backdrop: "static" });
              modal.componentInstance.missedPaymentDetails = result.subscription.missed_payment;
            }
          }
        },
        error: (err) => {
          this.logout();
        },
      });
    } else {
      this.userService.userDataRefreshed$.pipe(take(1)).subscribe((result) => {
        this.spinner.hide();
      });
    }

    if (this.activatedRoute.snapshot.queryParams.register == "mustangmicro") {
      this.registerProduct(true);
      this._router.navigate(["."], { relativeTo: this.activatedRoute, queryParams: {} });
    }
    if (this.activatedRoute.snapshot.queryParams.showSplash) {
      this._router.navigate([], {
        queryParams: { showSplash: null, splashProductId: null, splashProductName: null },
        queryParamsHandling: "merge",
      });
      this.fbService
        .handleFirestorePromise(() => this.db.collection("product_splashes").doc(this.activatedRoute.snapshot.queryParams.showSplash).ref.get())
        .then((result) => {
          if (result.exists) {
            let splash: any = result.data();
            this.showSplash(splash, this.activatedRoute.snapshot.queryParams.splashProductId, this.activatedRoute.snapshot.queryParams.splashProductName);
          }
        })
        .catch(() => {});
    }

    if (!this.storage.checkTimestamp("logged_in_event_triggered")) {
      this.avoService.avoInitted$.pipe(filter((result) => !!result)).subscribe((result) => {
        this.avoService.trackEvent().loggedIn({
          userId_: this.user.id,
          prsId: this.user.id,
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        });
        this.storage.setTimestamp("logged_in_event_triggered", 24);
      });
    }
    this.retrieveUpgradableProducts();
    this.productService.redeemS17ElligibilityRetrieved$
      .pipe(
        filter((result) => !!result),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productService.products$
          .pipe(
            filter((products) => !!products),
            takeUntil(this.destroy$)
          )
          .subscribe((result) => {
            if (result) {
              this.productsLoading = false;
              this.recentProducts = this.productService.productsByAcquired.filter((item, index) => index < (this.productService.elligleToRedeemStudioOneSeven ? 2 : 3));
              this.setupDemoData();
            }
          });
      });
    this.checkDataWeNeed();
    this.checkShopRoundTrip();
    if (!this.storage.checkTimestamp("checked_terms")) {
      this.userService.checkTerms().subscribe((result) => {
        this.storage.setTimestamp("checked_terms", 168);
        this.termsResult = result;
      });
    }
  }

  retrieveUpgradableProducts() {
    if (!this.checkoutService.upgradableStudioOneProducts) {
      this.checkoutService.getUpgradableProducts().subscribe();
    }
  }

  tileClick(args, prod) {
    let className = args.srcElement?.className?.baseVal ? args.srcElement.className.baseVal : args.srcElement?.className;
    if (className && className.indexOf && className.indexOf("tile-action-btn") < 0) {
      this._router.navigate(["products/detail/", prod.id]);
    }
  }

  private getUrlParameter(url, name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  showTerms(html, showAccept = true) {
    const modalRef = this.appService.showModal(TermsofuseComponent, { size: "lg" });
    modalRef.componentInstance.termsHtml = html;
    modalRef.componentInstance.showAcceptButton = showAccept;
  }

  checkShopRoundTrip() {
    if (this._router.url == "/thankyou") {
      this.orderService.recentOrders$
        .pipe(
          filter((result) => !!result),
          take(1)
        )
        .subscribe((result) => {
          if (result && result.length) {
            const modalRef = this.appService.showModal(RecentOrderComponent, { size: "lg" });
            modalRef.componentInstance.orders = result;
            modalRef.result.then(
              (result) => {
                if (result) {
                  this._router.navigate(["/products/detail", result]);
                } else {
                  this._router.navigate(["/home"]);
                }
              },
              (error) => {
                this._router.navigate(["/home"]);
              }
            );
          }
        });
      this.orderService.getOrders(true, true);
    }
  }

  showVideo(video) {
    if (this.user.active_subscription) {
      let parts = video.uri.split("/");
      this.eventBusService.emit(this.eventBusService.types.playVideo, {
        id: parts[parts.length - 1],
        title: video.name,
        type: "vimeo",
        thumb: video.pictures.sizes[3].link,
      });
    } else {
      this.upgrade();
    }
  }

  openLivestream(id) {
    let video = this.contentService.videosByPlatformId[id];
    if (video) {
      this.eventBusService.emit(this.eventBusService.types.playVideo, {
        id: video.platform_id,
        title: video.title,
        type: video.platform,
        thumb: video.image,
        presonus_id: video.id,
        presonusVideo: video,
      });
    } else {
      this.eventBusService.emit(this.eventBusService.types.playVideo, {
        id: id,
        type: "vimeo",
      });
    }
  }

  checkDataWeNeed() {
    let hasAll = true;
    this.userService.dataWeNeed.forEach((item) => {
      if (!this.user[item.key]) {
        hasAll = false;
      }
    });

    this.needsMoreData = !hasAll;
  }

  // resendActivationEmail() {
  //   this.userService.resendActivationEmail().subscribe((result: any) => {
  //     if (result.success) {
  //       this._translate.get("profile.new_activation_sent").subscribe((string) => alert(string));
  //     }
  //   });
  // }

  openUrl(url) {
    window.open(url);
  }

  getFreeProduct(method) {
    if (method == "request_notion_demo") {
      this.gettingNotionDemo = true;
    } else {
      this.gettingS1Demo = true;
    }
    this.productService.getFreeProduct(method, "mypresonus").subscribe((result: any) => {
      if (method == "request_demo") {
        this.avoService.trackEvent().orderCompleted({
          prsId: this.user.id,
          subProvider: this.user.subscription?.provider || undefined,
          subStartDate: this.user.subscription?.start_date || undefined,
          subEndDate: this.user.subscription?.end_date || undefined,
          subType: this.user.subscription?.type || undefined,
          prsSubStatus: this.user.subscription?.status || undefined,
          locale: (this.user?.language_code || "en") + "-" + (this.user?.country || "US"),
          subtotal: 0,
          price: 0.0,
          quantity: 1,
          products: [
            {
              productId: method == "request_notion_demo" ? "395" : "1582",
              price: 0.0,
              sku: method == "request_notion_demo" ? "395" : "1582",
              name: method == "request_notion_demo" ? "Notion 6 Demo" : "Studio One 6 Demo",
            },
          ],
          userId_: this.user.id,
        });
      }
      this.productService.getProducts(true).subscribe(() => {
        this.gettingS1Demo = false;
        this.gettingNotionDemo = false;
        if (result.licenseId) {
          if (method != "request_notion_demo") {
            this.checkForSplash().then(() => {
              this._router.navigate(["products/detail", result.licenseId]);
            });
          } else {
            this._router.navigate(["products/detail", result.licenseId]);
          }
        }
      });
    });
  }

  checkForSplash() {
    return new Promise((resolve, reject) => {
      this.fbService
        .handleFirestorePromise(() => this.db.collection("product_splashes").doc("studioapp6.demo").ref.get())
        .then((regularResult) => {
          if (regularResult.exists) {
            let regularData: any = regularResult.data();
            regularData.string_id = regularResult.id;
            this.showSplash(regularData, "1582", "Studio One 6 Demo", true);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {});
    });
  }

  showS16Redeem() {
    const modalRef = this.appService.showModal(StudiooneGracePeriodComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
  }

  showSplash(splash, productId?, productName?, isNewRegistration?) {
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    if (productId) modalRef.componentInstance.productId = productId;
    if (productName) modalRef.componentInstance.productName = productName;
    if (isNewRegistration) modalRef.componentInstance.isNewRegistration = true;
  }

  setupDemoData() {
    this.hasS1App = this.productService.productsByErpIDs["STUDIOONE6"];
    this.shouldSeeS1Demo =
      !this.user.active_subscription &&
      !this.productService.productsByStringIDs["studioapp6.demo"] &&
      !this.productService.productsByStringIDs["studioapp6.pro"] &&
      !this.env.features.studio_one_seven;
    this.hasFree = this.productService.productsByStringIDs["studioapp6.free"];
    this.hasProDemo = this.productService.productsByStringIDs["studioapp6.demo"];
    this.hasNotion = this.productService.productsByStringIDs["notion6"];
    this.hasNotionDemo = this.productService.productsByStringIDs["notion6.demo"];
    if (this.hasProDemo) {
      if (this.hasProDemo.expirationDate) {
        const expires = moment(this.hasProDemo.expirationDate);
        const today = moment();
        this.daysLeft = today.diff(expires, "days") * -1;
        if (this.daysLeft < 0) {
          this.daysLeft = 0;
        } else {
          this.daysLeft = 30;
        }
      }
    }
  }

  upgrade() {
    this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  logout() {
    this._router.navigate(["/logout"]);
  }

  showRedeemableProducts() {
    const modalRef = this.appService.showModal(RedeemProductsComponent, { size: "lg" });
  }

  registerProduct(mustang?) {
    let regInProcessString;
    this._translate.get("products.register.registration_in_process").subscribe((string) => {
      regInProcessString = string;
    });
    const modalRef = this.appService.showModal(RegisterProductComponent, {
      size: mustang ? "md" : "lg",
      ariaLabelledBy: "modal-title",
      container: this.appService.appContainer,
      beforeDismiss: () => {
        if (modalRef.componentInstance.registering) {
          alert(regInProcessString);
          return false;
        }
      },
    });
    if (mustang) modalRef.componentInstance.mustang = true;
  }

  redeemS17() {
    const modalRef = this.appService.showModal(StudiooneGracePeriodComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.asset = this.productService.s16ProductToUpgradeTo7;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.appService.fullscreenHeader = undefined;
  }
}
