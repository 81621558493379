<div class="modal-header">
  <h4 class="modal-title">{{ folder ? "Edit" : "Create" }} Folder</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="moveFileLoader"></myp-inline-loader>
  <div class="form-group" *ngIf="!folder && !loading">
    <span class="t-14">
      New folder in
      <code>{{ parentFolder?.filename || "Home" }}</code>
    </span>
    <small class="form-text text-muted">Name</small>
    <input type="text" class="form-control" (keyup.enter)="submit()" [(ngModel)]="name" />
  </div>
  <div class="form-group" *ngIf="folder && !loading">
    <small class="form-text text-muted">Name</small>
    <input type="text" class="form-control" (keyup.enter)="submit()" [(ngModel)]="name" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="loading" (click)="submit()">{{ folder ? "Save" : "Create" }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
