import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DomSanitizer } from "@angular/platform-browser";
import { StorageService, UserService, CommunityService, WindowService, HelperService, ContentService, ProductService, MessageEmbed } from "@mypxplat/xplat/core";
import firebase from "firebase/compat/app";
import { BehaviorSubject, forkJoin, interval, Subject } from "rxjs";
import { map, skip, takeUntil, throttle } from "rxjs/operators";
import { BetaService } from "./beta.service";
import { FirebaseService } from "./firebase.service";

@Injectable()
export class WebCommunityService extends CommunityService {
  private scripts: any = {};

  public attachingExistingFile: any;
  public attachingPublicFileLink: any;

  public activeThreadMessages: Array<any>;
  public activeThreadMessagesSubscription: any;
  public threadMessageEvent$: Subject<string> = new Subject();

  // cache profile results so we can display them when navigating back.
  public activeProfileResults: Array<any>;
  public activeThread: any;
  public missedMessageSubscription: any;
  public unreadCounts: any = {
    threads: {},
    total: 0,
  };

  public viewing: any = {};

  public userVideoData: Array<any>;
  public userVideoDataMap: any = {};

  public channelSplashes: any = {
    learning_studio_one:
      '<div class="trans-bg p-10 m-t-10 m-b-10 border-radius10" style="max-width: 600px; margin-left: auto; margin-right: auto;">Welcome to the Learning Studio One channel. Use this space to ask questions or share your knowledge about the world\'s most versatile DAW.</div></div>',
    collaboration:
      '<div style="text-align: center; margin-bottom: 30px;"><div class="trans-bg p-10 m-t-10 m-b-10 border-radius10" style="max-width: 600px; margin-left: auto; margin-right: auto;">Welcome to the Collaboration channel. Use this space to post work in progress and invite others to collaborate. Studio One Pro+ lets you manage everything in one place with workspaces, find out how: <br /><br /><span class="btn btn-primary" id="youtubelink" data-youtube-id="WzFkWEzpiNs">Watch a Video</span></div></div>',
  };

  public notificationSubscription: any;
  public migratingPostMessage: string;
  public cachedDisplayType: string = "detailed";
  public betaAuthorList: any;
  public betaTagCountMap: any;
  public selectedChannel: any;

  constructor(
    http: HttpClient,
    private _storageService: StorageService,
    private _windowService: WindowService,
    private _sanitizer: DomSanitizer,
    public helperService: HelperService,
    public contentService: ContentService,
    public db: AngularFirestore,
    public storageService: StorageService,
    public userService: UserService,
    public betaService: BetaService,
    public productService: ProductService,
    public fbService: FirebaseService
  ) {
    super(http, _storageService, _windowService, helperService, userService);
  }

  updateProfile(args) {
    return new Promise((resolve, reject) => {
      if (this.profile) {
        this.fbService
          .handleFirestorePromise(() =>
            this.db
              .collection("user_profiles")
              .doc(this.userService.user.id)
              .update({
                ...args,
              })
          )
          .then((result) => {
            this.profile = this.profile ? { ...this.profile, ...args } : args;

            satelliteDocumentUpdates();
          })
          .catch((err) => {
            reject(new Error(err?.message || "An error occurred while updating your profile."));
          });
      } else {
        this.fbService
          .handleFirestorePromise(() => this.db.collection("user_profiles").doc(this.userService.user.id).ref.get())
          .then((result) => {
            let method = "update";
            if (!result.exists) method = "set";
            this.db
              .collection("user_profiles")
              .doc(this.userService.user.id)
              [method]({
                ...args,
              })
              .then((result) => {
                this.profile = this.profile ? { ...this.profile, ...args } : args;
                if (!this.hasSetupCommunity) this.hasSetupCommunity = true;
                satelliteDocumentUpdates();
              })
              .catch((err) => {
                reject(new Error(err?.message || "An error occurred while updating your profile."));
              });
          })
          .catch((err) => {
            reject(new Error(err?.message || "An error occurred while updating your profile."));
          });
      }

      const satelliteDocumentUpdates = () => {
        if (args.firstName || args.lastName || args.photo) {
          this.fbService
            .handleFirestorePromise(() => this.db.collection("user_profiles").doc(this.userService.user.id).collection("linked_users").ref.get())
            .then((result) => {
              if (result.docs.length) {
                let promises = [];
                let updateArgs: any = {};
                if (args.firstName || args.lastName) updateArgs.name = (args.firstName || this.userService.user.firstName) + " " + (args.lastName || this.userService.user.lastName);
                if (args.photo) updateArgs.photo = args.photo;
                let batch = this.db.firestore.batch();
                result.docs.forEach((item) => {
                  batch.update(this.db.collection("user_profiles").doc(item.id).collection("linked_users").doc(this.userService.user.id).ref, updateArgs);
                });
                batch.commit().then((result) => {
                  resolve(true);
                });
              }
            });
        } else {
          resolve(true);
        }
        resolve(true);
      };
    });
  }

  updateCommunityStatus(id, status) {
    return this.fbService.handleFirestorePromise(() => this.db.collection("user_profiles").doc(id).update({ status: status }));
  }

  getFirebaseProfile(id?) {
    return this.fbService
      .handleFirestorePromise(() =>
        this.db
          .collection("user_profiles")
          .doc(id || this.userService.user.id)
          .ref.get()
      )
      .then((result) => {
        if (result.exists) {
          let userData: any = result.data();
          this.profile = this.buildCommunityUserData(userData);
          this.hasSetupCommunity = userData.hasSetupCommunity;
          this.firebaseProfile$.next(this.profile);
          return userData;
        } else {
          return false;
        }
      });
  }

  addUserSkills(data, string_ids?) {
    this.fbService.handleFirestorePromise(() => this.db.collection("user_profiles").doc(this.userService.user.id).update({ skillsQueryMap: string_ids }));
    return super.addUserSkills(data);
  }

  getUserProfile(userId?) {
    return super.getUserProfile(userId, this.db.collection("user_profiles").doc(userId).ref);
  }

  refreshUserProfile(userId?) {
    return super.getUserProfile(userId, this.db.collection("user_profiles").doc(userId).ref, true);
  }

  findMembersBySkills(skills, limit = 2000) {
    return super.findMembersBySkills(skills, limit, this.db.collection("user_profiles").ref);
  }

  findMembersByName(skills) {
    return super.findMembersByName(skills, this.db.collection("user_profiles"));
  }

  findMembers(skills?, nameQuery?, emailQuery?, limit = 5, experts?) {
    return super.findMembers(this.db.collection("user_profiles").ref, skills, nameQuery, emailQuery, limit, experts);
  }

  findModeratedUsers() {
    return super.findModeratedUsers(this.db.collection("user_profiles").ref);
  }

  addPost(data, collection: string = "community_posts") {
    return super.addPost(data, this.db.collection(collection));
  }

  getThreadMessages(type, thread?, limit = 100, page = 1) {
    return new Promise((resolve, reject) => {
      let messages = [];
      let method;
      if (type == "connection_thread" && thread) {
        method = this.db.collection("connection_threads").doc(thread.id).collection("messages").ref.orderBy("timestamp").limitToLast(100);
      }
      this.fbService.handleFirestorePromise(() => {
        return method.get().then((result) => {
          result.docs.forEach((doc) => {
            let msg: any = doc.data();
            msg.key = doc.id;
            msg.id = doc.id;
            if (msg.file) msg.file.url = this.buildFileUrl(msg.file.user_id, msg.file);
            if (msg.message) this.buildHtmlComment(msg);
            messages.push(msg);
          });
          this.activeThreadMessages = messages;

          resolve(messages);
        });
      });
    });
  }

  public justRecievedAddedMessage: any;
  watchThreadMessages(type, thread?) {
    // Unsubscribe from any existing subscription
    if (this.activeThreadMessagesSubscription) {
      this.activeThreadMessagesSubscription.unsubscribe();
    }

    this.activeThreadMessagesSubscription = this.fbService
      .handleFirestoreObservable(() =>
        this.db
          .collection("connection_threads")
          .doc(thread.id)
          .collection("messages", (ref) => ref.orderBy("timestamp", "desc"))
          .stateChanges()
          .pipe(
            map((data) => {
              data.forEach((itemDoc) => {
                let item: any = itemDoc.payload.doc.data();
                item.key = itemDoc.payload.doc.id;
                item.id = itemDoc.payload.doc.id;

                // Helper function to update a message
                const updateMessage = (msg) => {
                  msg.message = item.message;
                  if (msg.message) this.buildHtmlComment(msg);
                  if (item.edited) msg.edited = true;
                  return msg;
                };

                // Handle "added" messages
                if (itemDoc.type === "added") {
                  if (!item.timestamp) {
                    // Skip items without a `timestamp`
                    return;
                  }

                  // Add to activeThreadMessages if not already present
                  if (!this.activeThreadMessages.some((msg) => msg.id === item.id)) {
                    this.activeThreadMessages.push(updateMessage(item));
                    this.threadMessageEvent$.next("added");
                  }
                } else if (itemDoc.type === "removed") {
                  // Handle "removed" messages
                  const removeIndex = this.activeThreadMessages.findIndex((msg) => msg.id === item.id);
                  if (removeIndex !== -1) {
                    this.activeThreadMessages.splice(removeIndex, 1);
                    this.threadMessageEvent$.next("removed");
                  }
                } else if (itemDoc.type === "modified") {
                  // Handle "modified" messages
                  const existingMessage = this.activeThreadMessages.find((msg) => msg.id === item.id);

                  if (existingMessage) {
                    // Update existing message
                    updateMessage(existingMessage);
                    this.threadMessageEvent$.next("modified");
                  } else if (item.timestamp) {
                    // Add the message if it's not already present and has a valid timestamp
                    this.activeThreadMessages.push(updateMessage(item));
                    this.threadMessageEvent$.next("added");
                  }
                }
              });

              return this.activeThreadMessages;
            })
          )
      )
      .subscribe();
  }

  getPostByID(id, ignoreReplies = false, rejectWhenNotFound = true, collection: string = "community_posts") {
    return new Promise((resolve, reject) => {
      let posts = [];
      this.fbService
        .handleFirestorePromise(() => this.db.collection(collection).doc(id).ref.get())
        .then((result) => {
          let post: any = result.data();
          if (post && !post.deleted) {
            post.key = result.id;
            post.id = result.id;
            if (ignoreReplies) {
              resolve(this.buildHtmlComment(post));
            } else {
              let promises = [];
              posts.push(post);
              promises.push(
                this.db
                  .collection(collection)
                  .ref.where("parentID", "==", result.id)
                  .get()
                  .then((replies) => {
                    replies.docs.forEach((replyDoc) => {
                      let reply: any = replyDoc.data();
                      if (!reply.deleted) {
                        reply.key = replyDoc.id;
                        reply.id = replyDoc.id;
                        posts.push(reply);
                      }
                    });
                  })
              );
              posts.forEach((item) => {
                this.buildHtmlComment(item);
              });
              return Promise.all(promises).then((result) => {
                // this.processPostsAndReplies(posts);
                resolve(posts);
              });
            }
          } else {
            if (rejectWhenNotFound) {
              reject(new Error("Post not found."));
            } else {
              resolve(null);
            }
          }
        });
    });
  }

  watchPostReplies(id, collection: string = "community_posts") {
    return this.fbService.handleFirestoreObservable(() =>
      this.db
        .collection(collection, (ref) => {
          return ref.where("parentID", "==", id).orderBy("created", "asc");
        })
        .snapshotChanges()
        .pipe(
          skip(1),
          map((result) => {
            let posts = [];
            result.forEach((itemDoc) => {
              let item: any = itemDoc.payload.doc.data();
              if (item.employee_only && !this.userService.isEmployee) {
                // dont add because its employee only
              } else if (!item.deleted) {
                item.key = itemDoc.payload.doc.id;
                item.id = itemDoc.payload.doc.id;
                this.buildHtmlComment(item);
                posts.push(item);
              }
            });
            return posts;
          })
        )
    );
  }

  unwatchPostReplies() {
    this.activePostDetailSubscription.unsubscribe();
  }

  getBetaCommunityDetails(betaStringId) {
    // gets tag counts and author list

    return this.db
      .collection("beta_community_posts")
      .ref.where("beta_string_id", "==", betaStringId)
      .where("parentID", "==", null)
      .get()
      .then((result) => {
        this.betaTagCountMap = {};
        this.betaAuthorList = [];
        result.docs.forEach((doc) => {
          let data: any = doc.data();
          if (!data.deleted) {
            if (data.channels?.length) {
              data.channels.forEach((channel) => {
                if (!this.betaTagCountMap[channel]) this.betaTagCountMap[channel] = 0;
                this.betaTagCountMap[channel]++;
              });
            }
            this.betaAuthorList.push(data.author);
            this.betaAuthorList = Array.from(new Set(this.betaAuthorList.map((item) => item.id))).map((id) => this.betaAuthorList.find((item) => item.id === id));
            // now sort it by author.name
            this.betaAuthorList.sort((a, b) => (a.name > b.name ? 1 : -1));
          }
        });
      });
  }

  getPostsWithReplies(args: {
    channel?: any;
    topic?: any;
    postsAccountID?: string;
    moderationAccountID?: string;
    pins?: boolean;
    mostPopular?: boolean;
    limit?: number;
    clear?: boolean;
    collection?: string;
    betaStringId?: string;
  }) {
    if (!args.collection) args.collection = "community_posts";
    return super.getPostsWithReplies(args, this.db.collection(args.collection).ref);
  }

  public watchingCollectionRef: any;
  watchPostsWithReplies(channel?, topic?, postsAccountID?, pins?, collection: string = "community_posts", betaStringId?: string, watchPostsFrom?: Date) {
    if (this.activePostsSubscription) this.activePostsSubscription.unsubscribe();
    if (this.watchingCollectionRef) this.watchingCollectionRef.unsubscribe();
    this.activePostsSubscription = undefined;
    this.activePostsSubscription = this.fbService
      .handleFirestoreObservable(() => {
        return this.db
          .collection(collection, (ref) => {
            if (betaStringId) {
              if (channel && channel.title != "General" && channel.title != "All Posts" && channel.title != "My Pinned Posts") {
                return ref.where("channels", "array-contains-any", [channel.string_id]).where("beta_string_id", "==", betaStringId).orderBy("created", "asc");
              } else {
                if (watchPostsFrom) {
                  return ref.where("created", ">=", watchPostsFrom).where("beta_string_id", "==", betaStringId).orderBy("created", "asc");
                } else {
                  return ref.where("beta_string_id", "==", betaStringId).orderBy("created", "asc");
                }
              }
            } else {
              if (channel && channel.title != "General" && channel.title != "All Posts" && channel.title != "My Pinned Posts") {
                return ref.where("channels", "array-contains-any", [channel.string_id]).orderBy("created", "asc");
              } else if (topic) {
                return ref.where("topics", "array-contains-any", [topic.string_id]).orderBy("created", "asc");
              } else {
                let threeDaysAgo = new Date();
                threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
                return ref.where("created", ">=", threeDaysAgo).orderBy("created", "asc");
              }
            }
          })
          .stateChanges()
          .pipe(
            skip(1),
            map((result) => {
              if (result.length) {
                let posts = [];
                // this is admittedly odd, but when we initially watch posts we get an added event for all posts in the set. we only want to get updates on operations on individual posts, so
                // if its more than 5 its likely the first get. the skip for some reason is only working on page load (the first time we get the posts).
                if (result.length < 5) {
                  result.forEach((itemDoc) => {
                    let item: any = itemDoc.payload.doc.data();
                    item.key = itemDoc.payload.doc.id;
                    item.id = itemDoc.payload.doc.id;
                    if ((itemDoc.type == "modified" && item.deleted) || itemDoc.type == "removed") {
                      if (item.parentID) {
                        let removeIndex;
                        if (this.activeParentThreadMap[item.parentID] && this.activeParentThreadMap[item.parentID].length) {
                          this.activeParentThreadMap[item.parentID].forEach((msg, i) => {
                            if (msg.id == item.id) removeIndex = i;
                          });
                          if (removeIndex || removeIndex === 0) this.activeParentThreadMap[item.parentID].splice(removeIndex, 1);
                        }
                      } else {
                        let removeIndex;
                        this.activeParentPosts.forEach((msg, i) => {
                          if (msg.id == item.id) removeIndex = i;
                        });
                        if (removeIndex || removeIndex === 0) this.activeParentPosts.splice(removeIndex, 1);
                      }
                    } else if (itemDoc.type == "added") {
                      if (pins && !item.parentID) {
                        // we are watching pins, and theres no way a new post could already be pinned, so ignore.
                      } else {
                        if (!postsAccountID || item.author.id == postsAccountID || item.parentID) {
                          if (item.employee_only && !this.userService.isEmployee) {
                            // dont add because its employee only
                          } else if (item.pinned) {
                            // dont add because its a pinned post in a beta program
                          } else if (!topic && item.topics && item.topics.length) {
                            // dont add if we arent watching a topic, and this post has topics
                          } else {
                            this.buildHtmlComment(item);
                            if (!item.parentID) {
                              if (!this.activeParentThreadMap[item.key]) this.activeParentThreadMap[item.key] = [];
                              this.displayedPostsMap[item.key] = item;
                              // check if the post is already in activeParentPosts before adding it.
                              let alreadyAdded = false;
                              this.activeParentPosts.forEach((msg) => {
                                if (msg.id == item.id) alreadyAdded = true;
                              });
                              if (!alreadyAdded) this.activeParentPosts.unshift(item);
                            } else {
                              if (!this.activeParentThreadMap[item.parentID]) this.activeParentThreadMap[item.parentID] = [];
                              this.displayedPostsMap[item.key] = item;
                              // check if the post is already in the thread map array before adding it
                              let alreadyAdded = false;
                              this.activeParentThreadMap[item.parentID].forEach((msg) => {
                                if (msg.id == item.id) alreadyAdded = true;
                              });
                              if (!alreadyAdded) this.activeParentThreadMap[item.parentID].push(item);
                            }
                          }
                        }
                      }
                    } else if (itemDoc.type == "modified") {
                      if (!itemDoc.payload.doc.metadata.hasPendingWrites) {
                        const update = (msg) => {
                          msg.message = item.message;
                          msg.htmlMessage = item.htmlMessage;
                          this.buildHtmlComment(msg);
                          if (msg.file) msg.file.url = this.buildFileUrl(msg.file.user_id, msg.file);
                          if (item.subject) msg.subject = item.subject;
                          msg.beta_type = item.beta_type || "none";
                          msg.beta_status = item.beta_status || "none";
                          msg.beta_review_details = item.beta_review_details;
                          if (item.likes) msg.likes = item.likes;
                          if (item.pins) msg.pins = item.pins;
                          if (item.flags) msg.flags = item.flags;
                          if (item.cleared || item.cleared === false) msg.cleared = item.cleared;
                          if (item.edited) msg.edited = true;
                          if (item.articles) msg.articles = item.articles;
                          if (item.learn_content) msg.learn_content = item.learn_content;
                          if (item.categories_or_sections) msg.categories_or_sections = item.categories_or_sections;
                        };
                        if (item.parentID) {
                          if (this.activeParentThreadMap[item.parentID] && this.activeParentThreadMap[item.parentID].length) {
                            this.activeParentThreadMap[item.parentID].forEach((msg) => {
                              if (msg.id == item.id) msg = update(msg);
                            });
                          }
                        } else {
                          this.activeParentPosts.forEach((msg) => {
                            if (msg.id == item.id) update(msg);
                          });
                        }
                      }
                    }
                    for (var x in this.activeParentThreadMap) {
                      this.activeParentThreadMap[x].sort((a, b) => {
                        return a.created > b.created ? 1 : -1;
                      });
                    }
                  });
                }
                return posts;
              }
            })
          );
      })
      .subscribe();
  }

  unwatch() {
    this.displayedPostsMap = {};
    this.activeParentPosts = [];
    this.activeParentThreadMap = {};

    this.activeThreadMessages = [];
    if (this.activeThreadMessagesSubscription) this.activeThreadMessagesSubscription.unsubscribe();
    if (this.activePostsSubscription) this.activePostsSubscription.unsubscribe();
  }

  public comments: Array<any> = [];
  public replies: Array<any> = [];
  public parentThreadMap: any = {};
  public processedCommentMap: any = {};
  public commentSubscription: any;
  public subscribeToComments(ref, sortOrder?) {
    return ref.stateChanges().pipe(
      map((data: any) => {
        data.forEach((itemDoc) => {
          let item: any = itemDoc.payload.doc.data();
          item.key = itemDoc.payload.doc.id;
          item.id = itemDoc.payload.doc.id;

          // Ensure parent thread map is initialized
          if (!item.parentID && !this.parentThreadMap[item.id]) this.parentThreadMap[item.id] = [];

          // Build HTML for the comment
          item = this.buildHtmlComment(item);

          // Handle "added" comments
          if (itemDoc.type === "added") {
            if (!item.created && !item.timestamp) {
              // Skip items without a `created` timestamp
              return;
            }

            if (!item.deleted && !item.parentID) {
              // Add to comments if not already present
              if (!this.comments.some((msg) => msg.id === item.id)) {
                this.comments.push(item);
              }
            } else if (!item.deleted && item.parentID) {
              // Add to replies if not already present
              if (!this.replies.some((msg) => msg.id === item.id)) {
                this.replies.push(item);
              }
            }
          } else if (itemDoc.type === "removed") {
            // Handle "removed" comments
            this.performDeleteComment(item);
          } else if (itemDoc.type === "modified") {
            // Handle "modified" comments
            const updateComment = (msg) => {
              msg.message = item.message;
              msg.htmlMessage = item.htmlMessage;
              msg.likes = item.likes;
              msg.flags = item.flags;
              this.buildHtmlComment(msg);
              if (item.edited) msg.edited = true;
            };

            if (item.deleted) {
              this.performDeleteComment(item);
            } else {
              let found = false;

              // Update or add to comments
              if (!item.parentID) {
                this.comments.forEach((msg) => {
                  if (msg.id === item.id) {
                    found = true;
                    updateComment(msg);
                  }
                });
              }

              // Update or add to replies
              else if (this.parentThreadMap[item.parentID]) {
                this.parentThreadMap[item.parentID].forEach((msg) => {
                  if (msg.id === item.id) {
                    found = true;
                    updateComment(msg);
                  }
                });
              }

              if (!found) {
                if (!item.deleted && !item.parentID) {
                  this.comments.push(item);
                } else if (!item.deleted && item.parentID) {
                  this.replies.push(item);
                }
              }
            }
          }
        });

        // Process replies
        this.replies.forEach((item) => {
          if (!this.processedCommentMap[item.id]) {
            this.processedCommentMap[item.id] = true;
            if (this.parentThreadMap[item.parentID]) {
              this.parentThreadMap[item.parentID].push(item);
            }
          }
        });

        // Sort comments
        let sortInt = sortOrder === "asc" ? -1 : 1;
        this.comments.sort((a, b) => {
          return b.created > a.created ? sortInt : -sortInt;
        });

        return this.comments;
      })
    );
  }

  performDeleteComment(item) {
    if (item.parentID) {
      let deleteIndex;
      this.parentThreadMap[item.parentID].forEach((msg, index) => {
        if (msg.id == item.id) deleteIndex = index;
      });
      this.parentThreadMap[item.parentID].splice(deleteIndex, 1);
    } else {
      let deleteIndex;
      this.comments.forEach((msg, index) => {
        if (msg.id == item.id) deleteIndex = index;
      });
      if (deleteIndex || deleteIndex === 0) this.comments.splice(deleteIndex, 1);
    }
  }

  deletePost(id, admin?, collection: string = "community_posts") {
    return super.deletePost(id, this.db.collection(collection), admin);
  }

  likeUnlikePost(post, isLiking?, collection: string = "community_posts") {
    if (isLiking) {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          likes: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
          likeCount: post.likes && post.likes.length ? post.likes.length + 1 : 1,
        });
    } else {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          likes: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
          likeCount: post.likes.length - 1,
        });
    }
  }

  pinUnpinPost(post, collection: string = "community_posts") {
    if (post.pins && post.pins.includes(this.userService.user.id)) {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          pins: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
        });
    } else {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          pins: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
        });
    }
  }

  markUnmarkSphereOnly(post, collection: string = "community_posts") {
    return this.db
      .collection(collection)
      .doc(post.key)
      .update({
        sphere_only: post.sphere_only ? false : true,
      });
  }

  flagUnflagPost(post, reason?, collection: string = "community_posts") {
    if (post.flags && post.flags.includes(this.userService.user.id)) {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          flags: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
        });
    } else {
      return this.db
        .collection(collection)
        .doc(post.key)
        .update({
          latest_flag_reason: reason,
          cleared: false,
          flags: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
        });
    }
  }

  clearFlag(id, collection: string = "community_posts") {
    return this.db.collection(collection).doc(id).update({ cleared: true });
  }

  updatePost(id, args, collection: string = "community_posts") {
    return super.updatePost(this.db.collection(collection).doc(id), args);
  }

  getConnectedUserMessageThreads() {
    return super.getConnectedUserMessageThreads(this.db.collection("connection_threads").ref);
  }

  watchMissedMessageCounts() {
    if (this.missedMessageSubscription) this.missedMessageSubscription.unsubscribe();
    this.missedMessageSubscription = this.fbService
      .handleFirestoreObservable(() =>
        this.db
          .collection("connection_threads", (ref) => {
            return ref.where("users", "array-contains-any", [this.userService.user.id]);
          })
          .snapshotChanges()
          .pipe(
            throttle(
              () => {
                return interval(500);
              },
              { leading: true, trailing: true }
            )
          )
      )
      .subscribe((result: any) => {
        if (!this.userService.user) {
          if (this.missedMessageSubscription) this.missedMessageSubscription.unsubscribe();
        } else {
          this.unreadCounts.total = 0;
          this.unreadCounts.threads = {};
          result.forEach((item) => {
            let data: any = item.payload.doc.data();
            if (!data.inactive || !data.inactive.length) {
              let docID = item.payload.doc.id;
              if (data.viewing) {
                let obj = {};
                data.viewing.forEach((id) => {
                  obj[id] = true;
                });
                this.viewing[docID] = obj;
              }
              if (data.missedMessages) {
                if (!this.activeThread || this.activeThread.id != docID) {
                  this.unreadCounts.total += data.missedMessages[this.userService.user.id] ? data.missedMessages[this.userService.user.id] : 0;
                  this.unreadCounts.threads[docID] = data.missedMessages[this.userService.user.id];
                }
              }
            }
          });
        }
      });
  }

  getUnreadNotificationCount() {
    return super.getUnreadNotificationCount(
      this.db
        .collection("user_profiles")
        .doc(this.userService.user.id)
        .collection("notifications", (ref) => {
          return ref.limit(100).orderBy("created");
        }).ref
    );
  }

  listenNotificationUpdates() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }

    this.notificationSubscription = this.fbService
      .handleFirestoreObservable(() =>
        this.db
          .collection("user_profiles")
          .doc(this.userService.user.id)
          .collection("notifications")
          .snapshotChanges()
          .pipe(
            map((changes: any) =>
              changes.map((c) => {
                let rtn = { ...c.payload.doc.data(), key: c.payload.doc.id };
                return rtn;
              })
            )
          )
      )
      .subscribe((result: any) => {
        if (!this.userService.user) {
          this.notificationSubscription.unsubscribe();
        } else {
          this.notificationCount = result.length;
          this.unreadNotificationCount = 0;
          result.forEach((data) => {
            if (!data.seen) this.unreadNotificationCount++;
          });
        }
      });
  }

  getNotifications() {
    return super.getNotifications(
      this.db
        .collection("user_profiles")
        .doc(this.userService.user.id)
        .collection("notifications", (ref) => {
          return ref.limit(100).orderBy("created");
        }).ref
    );
  }

  deleteNotification(id) {
    return super.deleteNotification(this.db.collection("user_profiles").doc(this.userService.user.id).collection("notifications").doc(id)).then(() => {
      return super
        .updateNotification(this.db.collection("user_profiles").doc(this.userService.user.id), {
          notificationsTag: firebase.firestore.FieldValue.increment(1),
        })
        .then(() => {
          this.getUnreadNotificationCount();
        });
    });
  }

  batchDeleteNotifications(ids: Array<string>) {
    let batch = this.db.firestore.batch();
    ids.forEach((id) => {
      batch.delete(this.db.collection("user_profiles").doc(this.userService.user.id).collection("notifications").doc(id).ref);
    });
    return batch.commit().then(() => {
      return this.db
        .collection("user_profiles")
        .doc(this.userService.user.id)
        .update({
          notificationsTag: firebase.firestore.FieldValue.increment(1),
        })
        .then(() => {
          this.getUnreadNotificationCount();
        });
    });
  }

  batchUpdateNotifications(ids: Array<string>, data: any) {
    let batch = this.db.firestore.batch();
    ids.forEach((id) => {
      batch.update(this.db.collection("user_profiles").doc(this.userService.user.id).collection("notifications").doc(id).ref, data);
    });
    return batch.commit().then(() => {
      this.getUnreadNotificationCount();
    });
  }

  updateNotification(id, data) {
    super.updateNotification(this.db.collection("user_profiles").doc(this.userService.user.id).collection("notifications").doc(id), data).then(() => {
      this.getUnreadNotificationCount();
    });
  }

  getTopic(string_id) {
    return this.fbService
      .handleFirestorePromise(() => this.db.collection("community_topics").doc(string_id).ref.get())
      .then((result) => {
        if (result.exists) {
          return result.data();
        } else {
          return false;
        }
      });
  }

  getTopics(all?, includeArchived = true) {
    let query: firebase.firestore.Query;

    if (all) {
      query = this.db.collection("community_topics").ref;
    } else if (includeArchived) {
      query = this.db.collection("community_topics").ref.where("start_date", "<", new Date());
    } else {
      query = this.db.collection("community_topics").ref.where("archived", "==", false).where("start_date", "<", new Date());
    }

    return this.fbService
      .handleFirestorePromise(() => query.get())
      .then((result) => {
        let rtn = [];
        if (!result.empty) {
          result.docs.forEach((topic) => {
            let data: any = topic.data();
            this.topicMap[data.string_id] = data;
            rtn.push(data);
          });
        }
        this.topics = rtn;
        return rtn;
      });
  }

  addTopic(data) {
    data.created_by = this.userService.user.id;
    data.created = new Date();
    data.archived = false;
    return this.fbService
      .handleFirestorePromise(() => this.db.collection("community_topics").doc(data.string_id).set(data))
      .then(() => {
        this.getTopics();
      });
  }

  updateTopic(string_id, data) {
    return this.db
      .collection("community_topics")
      .doc(string_id)
      .update(data)
      .then(() => {
        this.getTopics();
      });
  }

  toggleArchiveTopic(string_id, archived) {
    return this.db
      .collection("community_topics")
      .doc(string_id)
      .update({ archived: archived, date_archived: new Date(), archived_by: this.userService.user.id })
      .then(() => {
        this.getTopics();
      });
  }

  createConnectedUserMessageThread(user_id) {
    return super.createConnectedUserMessageThread(this.db.collection("connection_threads").ref, user_id);
  }

  removeConnection(connectionId, user_id) {
    return super.removeConnection(connectionId).pipe(
      map((result) => {
        this.db
          .collection("connection_threads")
          .ref.where("users", "in", [[this.userService.user.id, user_id]])
          .get()
          .then((threadResult) => {
            if (threadResult.docs && threadResult.docs.length) {
              threadResult.docs.forEach((doc) => {
                let data: any = doc.data();
                let connection = data.users.filter((id) => id == user_id)[0];
                if (connection) {
                  doc.ref.update({
                    inactive: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
                  });
                }
              });
            }
          });
        return result;
      })
    );
  }

  getAnnouncements() {
    return this.fbService
      .handleFirestorePromise(() => this.db.collection("sphere_announcements").ref.get())
      .then((result) => {
        let announcements = [];
        result.docs.forEach((doc: any) => {
          let data = doc.data();
          announcements.push({
            ...data,
            id: doc.id,
          });
        });
        return announcements;
      });
  }

  deleteAnnouncement(id) {
    return this.db.collection("sphere_announcements").doc(id).delete();
  }

  addAnnouncement(id, args) {
    return this.db.collection("sphere_announcements").doc(id).set(args);
  }

  addBetaAnnouncement(betaStringId, announcementStringId, args) {
    return this.db.collection(`beta_communities/${betaStringId}/announcements`).doc(announcementStringId).set(args);
  }

  getSplashes() {
    return this.fbService
      .handleFirestorePromise(() => this.db.collection("product_splashes").ref.get())
      .then((result) => {
        let splashes = [];
        result.docs.forEach((doc: any) => {
          let data = doc.data();
          splashes.push({
            ...data,
            id: doc.id,
          });
        });
        return splashes;
      });
  }

  deleteSplash(id) {
    return this.db.collection("product_splashes").doc(id).delete();
  }

  addSplash(id, args) {
    return this.db.collection("product_splashes").doc(id).set(args);
  }

  addNotification(user_id, notificationData) {
    notificationData.created = new Date();
    notificationData.seen = false;
    return this.db.collection("user_profiles").doc(user_id).collection("notifications").add(notificationData);
  }

  getStats(collection: string = "community_posts") {
    let promises = [];
    let stats = [];
    promises.push(
      this.db
        .collection(collection)
        .ref.where("parentID", "==", null)
        .get()
        .then((result) => {
          stats.push({
            title: "Original Posts",
            value: result.size,
          });
          let authors: any = {};
          let uniqueAuthorCount = 0;
          result.docs.forEach((doc) => {
            let data: any = doc.data();
            if (!authors[data.author.id]) {
              authors[data.author.id] = 0;
              uniqueAuthorCount++;
            }
            authors[data.author.id]++;
          });
          stats.push({
            title: "Unique Authors",
            value: uniqueAuthorCount,
            data: {
              description: "Post count per user ID.",
              values: authors,
            },
          });
        }),
      this.db
        .collection(collection)
        .ref.where("parentID", "!=", null)
        .get()
        .then((result) => {
          stats.push({
            title: "Replies",
            value: result.size,
          });
        }),
      this.db
        .collection("user_profiles")
        .ref.where("hasSetupCommunity", "==", true)
        .get()
        .then((result) => {
          stats.push({
            title: "Total Community Members",
            value: result.size,
          });
        }),
      this.db
        .collection("user_profiles")
        .ref.where("hasSetupCommunity", "==", true)
        .where("public", "==", true)
        .get()
        .then((result) => {
          stats.push({
            title: "Public Members",
            value: result.size,
          });
        }),
      this.db
        .collection("video_comments")
        .ref.get()
        .then((result) => {
          let totalComments = 0;
          let subPromises = [];
          let videoComments: Array<any> = [];
          result.docs.forEach((doc) => {
            subPromises.push(
              doc.ref
                .collection("comments")
                .get()
                .then((result) => {
                  if (result.docs.length) {
                    videoComments.push({
                      comments: result.docs.length,
                      title: (<any>doc.data()).title,
                      id: doc.id,
                    });
                    totalComments += result.docs.length;
                  }
                })
            );
          });
          return Promise.all(subPromises).then(() => {
            videoComments.sort((a, b) => {
              return a.comments > b.comments ? -1 : 1;
            });
            stats.push({
              title: "Total Video Comments",
              value: totalComments,
              data: {
                description: "Comment counts by video",
                values: videoComments,
              },
            });
          });
        })
    );
    return new Promise((resolve, reject) => {
      Promise.all(promises).then((result) => {
        resolve(stats);
      });
    });
  }

  parseEmbedUrls(text: string, compareArray?: MessageEmbed[]): MessageEmbed[] {
    const embeds = super.parseEmbedUrls(text, compareArray);
    if (embeds) {
      return embeds.map((embed) => ({
        ...embed,
        embedSrc: this._sanitizer.bypassSecurityTrustResourceUrl(embed.embedSrc as string),
      }));
    } else {
      return [];
    }
  }
}
