import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, ProductService, environment, EventsService, WorkspaceService, OrderService, SupportService, CommunityService } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppBaseComponent, AppService } from "@mypxplat/xplat/web/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
@Component({
  selector: "myp-shared-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent extends AppBaseComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public sso: string;
  public env = environment;
  public returnTo: string;

  constructor(
    userService: UserService,
    public appService: AppService,
    public productService: ProductService,
    private router: Router,
    public fbAuth: AngularFireAuth,
    public eventsService: EventsService,
    public workspaceService: WorkspaceService,
    public orderService: OrderService,
    public supportService: SupportService,
    public communityService: CommunityService
  ) {
    super(userService);
  }
  ngOnInit() {
    if (this.router.url == "/logout") {
      this.logout();
    } else {
      this.appService.setTokensFromCookies();
      this.appService.signInFromSavedTokens();
      fetchAuthSession({ forceRefresh: true })
        .then((user) => {
          if (user?.tokens?.accessToken) {
            this.router.navigate(["/home"]);
          } else {
            this.appService.redirectToConnect(true, false, this.appService.app == "admin" ? "admin" : false);
          }
        })
        .catch((err) => {
          this.appService.redirectToConnect(true, false, this.appService.app == "admin" ? "admin" : false);
        });
    }
  }

  public logout() {
    const redirect = () => {
      if (this.sso) {
        this.appService.redirectToConnect(true, false, this.appService.app == "admin" ? "admin" : false);
      } else {
        this.appService.redirectToConnect(false, true, this.appService.app == "admin" ? "admin" : false);
      }
    };
    this.productService.clearCache();
    this.eventsService.clearCache();
    this.orderService.clearCache();
    this.supportService.clearCache();
    this.communityService.clearCache();
    this.workspaceService.clearCache();
    this.userService.logout().subscribe({
      next: (result) => {
        signOut()
          .then(() => {
            this.appService.deleteCookies();
            this.fbAuth
              .signOut()
              .then(() => redirect())
              .catch(() => redirect());
          })
          .catch(() => redirect());
      },
      error: (err) => {
        redirect();
      },
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("full-page");
    body.style.backgroundImage = "";
  }
}
