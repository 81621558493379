<div class="chat-container comments" [style]="fullHeight ? 'height: 100%' : ''">
  <div class="messages" #chatWindow [ngStyle]="{ height: height ? height + 'px' : '100%' }">
    <ul class="notes-list">
      <ng-container *ngIf="videoNotes && videoNotes.length">
        <li class="video-note" [class.active]="note.active" *ngFor="let note of videoNotes">
          <div>
            <div class="video-note-text t-14" [innerHTML]="note.htmlMessage | safe: 'html'"></div>
            <button class="link t-12 font-weight-bold d-inline-block" (click)="noteOptions(note)">Options</button>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="timedVideoNotes && timedVideoNotes.length">
        <li class="video-note" [class.active]="note.active" *ngFor="let note of timedVideoNotes">
          <div class="video-timestamp" *ngIf="note.video_timestamp">{{ helperService.hhmmss(note.video_timestamp) }}</div>
          <div>
            <div class="video-note-text t-14" [innerHTML]="note.htmlMessage | safe: 'html'"></div>
            <button class="link t-12 font-weight-bold d-inline-block m-r-10" *ngIf="note.video_timestamp" (click)="playVideoFrom.next(note.video_timestamp)">Play Video From Here</button>
            <button class="link t-12 font-weight-bold d-inline-block" (click)="noteOptions(note)">Options</button>
          </div>
        </li>
      </ng-container>
    </ul>
    <span
      *ngIf="(!videoNotes || !videoNotes.length) && (!timedVideoNotes || !timedVideoNotes.length)"
      class="c-white"
      style="display: flex; align-items: center; height: 100%; text-align: center; justify-content: center"
    >
      You haven't added any notes yet.
    </span>
  </div>

  <div class="input-container text-left">
    <label id="comment-label" class="comment-label" for="video-notes-input">Add a note...</label>
    <div
      class="note-timestamp"
      *ngIf="shouldShowActiveTime || isHovering || note"
      (mouseenter)="isHovering = true"
      (mouseleave)="isHovering = false"
      [ngStyle]="{ bottom: noteInputHeight + 25 + 'px' }"
    >
      <input id="note-timestamp-check" type="checkbox" [(ngModel)]="linkToTimestamp" class="m-r-5" />
      <label for="note-timestamp-check">Link note to timestamp {{ activeTime?.display }}</label>
    </div>
    <div class="msg-input">
      <myp-message-input
        #msgInput
        [type]="'span'"
        inputID="video-notes-input"
        (valueChanged)="noteValueChange($event)"
        (messageSent)="addNote()"
        [placeholder]="'Ctrl + Enter to send...'"
        (inputHeightChange)="inputHeightChange($event)"
        (blur)="shouldShowActiveTime = false"
        (focus)="shouldShowActiveTime = true"
        [includeSendButton]="true"
        ariaLabelBy="video-label"
      ></myp-message-input>

      <!-- <button class="send-btn" (click)="addNote()" [disabled]="!note" [class.disabled]="!note">Submit</button> -->
    </div>
  </div>
</div>
