<div class="d-flex d-md-flex justify-space-between align-items-center m-b-10 m-t-5">
  <div class="channel-title text-center" *ngIf="postType == 'channels'">
    <h1 class="text-truncate content-title justify-content-center">{{ betaStringId ? selectedChannel.title : ("com.skills." + selectedChannel.string_id | translate) }}</h1>
  </div>
  <div class="channel-title text-center" *ngIf="postType == 'topics'">
    <h2 class="text-truncate content-title justify-content-center">{{ selectedTopic.title }}</h2>
    <span class="t-14">Added on {{ selectedTopic.start_date.toDate() | date }}</span>
    <span *ngIf="selectedTopic.archived">&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
    <span *ngIf="selectedTopic.archived && selectedTopic.date_archived" class="t-14 c-red">Archived on {{ selectedTopic.date_archived.toDate() | date }}</span>
  </div>
</div>
<div
  class="channel-slug"
  *ngIf="selectedChannel && communityService.channelSplashes[selectedChannel.string_id] && !betaStringId"
  [innerHTML]="communityService.channelSplashes[selectedChannel.string_id] | safe: 'html'"
></div>
<div class="topic-slug" *ngIf="selectedTopic && selectedTopic.description" [innerHTML]="selectedTopic.description | safe: 'html'"></div>
<div class="posts">
  <ng-container
    *ngIf="(!communityService.profile || !communityService.profile.status || communityService.profile.status == 'active') && (selectedChannel || (selectedTopic && !selectedTopic.archived))"
  >
    <myp-create-post-input #createPostInput [selectedChannel]="selectedChannel" [topic]="selectedTopic" [betaStringId]="betaStringId"></myp-create-post-input>
  </ng-container>
  <div class="m-t-20">
    <ng-container *ngIf="createPostInput && createPostInput.queuedPosts">
      <div *ngFor="let queuedPost of createPostInput.queuedPosts">
        <myp-comment [comment]="queuedPost" [isCommunityPost]="true" [index]="i" [queued]="true" [displayedSection]="postType"></myp-comment>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ul class="list-group" *ngIf="displayType == 'detailed' || !betaStringId">
        <li class="list-group-item border-0 p-0 bg-transparent" *ngFor="let post of communityService.activeParentPosts; let i = index">
          <myp-comment
            [betaStringId]="betaStringId"
            [comment]="post"
            [isCommunityPost]="true"
            [replies]="communityService.activeParentThreadMap[post.key]"
            (replied)="sendReply($event)"
            (deleteIndexSet)="postDeleteIndex = $event"
            [index]="i"
            (channelSelected)="channelSelected.next($event)"
            (topicSelected)="topicSelected.next($event)"
            [displayedSection]="postType"
          ></myp-comment>
        </li>
      </ul>
      <ul class="list-group" *ngIf="displayType == 'list' && betaStringId">
        <li class="list-group-item border-0 p-0 bg-transparent" *ngFor="let post of communityService.activeParentPosts; let i = index">
          <myp-list-item
            [photo]="post.author.photo ? post.author.photo : './assets/images/default_avatar.jpg'"
            [title]="helperService.stripHtml(post.subject, true)"
            [subData]="[(post.created.toDate() | date: 'MMM Do [at] hh:mm' : true) + ' by ' + post.author.name, getBetaItemStats(post), getChannelString(post.channels)]"
            [item]="post"
            [alignTop]="true"
            (itemClicked)="goToPost(post)"
          >
            <div class="button-content">
              <button class="fa-button m-r-20" (click)="copyLink(post, $event)">
                <fa-icon aria-label="Copy" icon="copy" ngbTooltip="Copy Link"></fa-icon>
              </button>
            </div>
          </myp-list-item>
          <hr />
        </li>
      </ul>

      <div class="text-center m-y-30" *ngIf="!communityService.activePostsLimitReached">
        <button type="button" class="btn btn-transparent small-btn" *ngIf="user.active_subscription || betaStringId" (click)="showMore()">{{ "com.show_more_posts" | translate }}</button>
        <button type="button" class="btn btn-transparent small-btn" *ngIf="!user.active_subscription && !betaStringId" (click)="upgrade()">{{ "com.upgrade_more_posts" | translate }}</button>
      </div>

      <div class="text-center m-y-20 gothic" *ngIf="communityService.activeParentPosts && !communityService.activeParentPosts.length">
        <span>
          {{ "com.no_posts_found" | translate: { channel: selectedChannel ? (betaStringId ? selectedChannel.title : ("com.skills." + selectedChannel.string_id | translate)) : selectedTopic.title } }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
