<div [class]="productStringID" style="top: 0px; left: 0px; width: 100%; height: 100%; position: fixed"><canvas id="confetti"></canvas></div>
<button
  type="button"
  ngbAutofocus
  *ngIf="!splash.modalBackground || !splash.headerText"
  class="fs-close-btn right border-0 bg-transparent p-0"
  (click)="close()"
  data-cy="close-success-splash-button"
  aria-label="close"
  id="close-success-splash-button"
>
  <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
</button>
<div class="modal-header" *ngIf="splash.modalBackground && splash.headerText">
  <h1 class="modal-title">{{ splash.headerText }}</h1>

  <button ngbAutofocus class="close" aria-label="Close" (click)="close()" data-cy="close-success-splash-button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clear product-success-splash-content" data-cy="product-success-splash" [innerHTML]="splash.description | safe: 'html'"></div>
