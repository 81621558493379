<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <div class="menu-top d-flex justify-content-between align-items-center">
      <h1 class="menu-title">
        {{ "sphere.account.account" | translate }}
      </h1>
    </div>

    <ul class="menu-options">
      <li class="menu-option" [class.active]="displayedSection == 'general'">
        <a class="primary-option" tabindex="0" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('general')" (click)="appService.mobileMenuShown = false; changeCategory('general')">
          {{ "sphere.your_profile" | translate }}
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'skills'" *ngIf="user.active_subscription">
        <a class="primary-option" tabindex="0" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('skills')" (click)="appService.mobileMenuShown = false; changeCategory('skills')">
          {{ "com.skills_word" | translate }}
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'showcases'" *ngIf="user.active_subscription">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('showcases')"
          (click)="appService.mobileMenuShown = false; changeCategory('showcases')"
        >
          {{ "com.showcases" | translate }}
        </a>
      </li>
      <li class="menu-option" data-cy="plan-nav-link" [class.active]="displayedSection == 'plan'">
        <a class="primary-option" tabindex="0" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('plan')" (click)="appService.mobileMenuShown = false; changeCategory('plan')">
          {{ "sphere.account.membership_billing" | translate }}
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'notifications'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('notifications')"
          (click)="appService.mobileMenuShown = false; changeCategory('notifications')"
        >
          {{ "sphere.account.email_prefs" | translate }}
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'notification_preferences'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('notification_preferences')"
          (click)="appService.mobileMenuShown = false; changeCategory('notification_preferences')"
        >
        {{ "account.acount_notifications" | translate }}
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'collaboration'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('collaboration')"
          (click)="appService.mobileMenuShown = false; changeCategory('collaboration')"
        >
          {{ "sphere.account.collaboration" | translate }}
        </a>
      </li>
      <li class="menu-option" *ngIf="user.active_subscription" [class.active]="displayedSection == 'storage'">
        <a class="primary-option" tabindex="0" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('storage')" (click)="appService.mobileMenuShown = false; changeCategory('storage')">
          {{ "sphere.account.storage" | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="content animate-in-from-right p-t-10" [class.mobile-shown]="!appService.mobileMenuShown">
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      {{ "com.account_options" | translate }}
    </div>
    <div class="content-constrained">
      <myp-account-general *ngIf="displayedSection == 'general'"></myp-account-general>
      <myp-account-skills *ngIf="displayedSection == 'skills'"></myp-account-skills>
      <myp-account-showcases *ngIf="displayedSection == 'showcases'"></myp-account-showcases>
      <myp-account-plan
        *ngIf="
          displayedSection == 'plan' &&
          userService.subDetails &&
          userService.subDetails.active &&
          (userService.subDetails.subscription.provider === 'bluesnap' || userService.subDetails.subscription.provider === 'xchange')
        "
      ></myp-account-plan>
      <ng-container *ngIf="displayedSection == 'plan' && !userService.subDetails">
        <myp-skeleton-loader type="line" width="20%" height="30px" extraContainerClass="m-b-20"></myp-skeleton-loader>
        <myp-skeleton-loader [number]="12" extraContainerClass="m-y-10" [useRandomWidth]="true" type="line"></myp-skeleton-loader>
      </ng-container>
      <myp-account-plan-stripe
        *ngIf="
          (displayedSection == 'plan' && userService.subDetails && !userService.subDetails.active) ||
          (displayedSection == 'plan' && userService.subDetails?.active && (userService.subDetails?.subscription.provider === 'stripe' || userService.subDetails?.subscription.provider === 'presonus'))
        "
      ></myp-account-plan-stripe>
      <myp-account-plan-generic *ngIf="displayedSection == 'plan' && userService.subDetails?.subscription?.provider === 'recharge'"></myp-account-plan-generic>

      <ng-container
        *ngIf="
          (displayedSection == 'plan' && userService.subDetails && !userService.subDetails.active) ||
          (displayedSection == 'plan' && (userService.subDetails?.subscription.provider === 'stripe' || userService.subDetails?.subscription.provider === 'presonus'))
        "
      ></ng-container>

      <myp-account-notifications *ngIf="displayedSection == 'notifications'"></myp-account-notifications>
      <myp-notification-preferences *ngIf="displayedSection == 'notification_preferences'"></myp-notification-preferences>
      <myp-account-collaboration *ngIf="displayedSection == 'collaboration'"></myp-account-collaboration>
      <myp-account-file-management *ngIf="displayedSection == 'storage'"></myp-account-file-management>
    </div>
  </div>
</main>
