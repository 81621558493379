<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "profile.edit.delete_data" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="text-center t-50 c-red"><fa-icon icon="exclamation-triangle"></fa-icon></div>
  <div>
    <p>{{ "account.presonus_stores_information" | translate }}</p>
    <p>{{ "account.erase_personal_data_warning" | translate }}</p>
    <ul>
      <li>{{ "account.lose_support_tickets_access" | translate }}</li>
      <li>{{ "account.lose_registered_products" | translate }}</li>
      <li>{{ "account.studio_one_will_stop" | translate }}</li>
      <li>{{ "account.no_software_updates_access" | translate }}</li>
      <li>{{ "account.no_forum_access" | translate }}</li>
      <li>{{ "account.account_deleted_forever" | translate }}</li>
    </ul>
    <p>{{ "account.data_irrecoverable_warning" | translate }}</p>
    <p>{{ "account.action_not_recommended" | translate }}</p>
    <p>
      {{ "account.zendesk_data_management" | translate }}
      <a href="https://www.zendesk.com/contact/" target="_blank">{{ "account.zendesk_contact_link" | translate }}</a>
    </p>
    <p>
      {{ "account.bluesnap_data_management" | translate }}
      <a href="https://home.bluesnap.com/contact-us/" target="_blank">{{ "account.zendesk_contact_link" | translate }}</a>
    </p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="deleteData()">
    <span>{{ "account.delete_my_data" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Close</button>
</div>
