<div class="modal-header">
  <h4 class="modal-title">Move File</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-left" ngbAutofocus>
  <myp-inline-loader [loading]="moving" height="200px" spinnerName="moveFileLoader"></myp-inline-loader>

  <div *ngIf="!moving && !filesExistInDestination.length">
    <div class="text-center m-b-20">
      Select where you'd like to move
      <ng-container *ngIf="!folderToMove">{{ files.length == 1 ? files[0].filename : files.length + " files" }}</ng-container>
      <ng-container *ngIf="folderToMove">{{ folderToMove.filename }}</ng-container>
    </div>
    <div class="move-folder-option" (click)="select('Home')" [class.selected]="selectedFolder == 'Home'">
      <fa-icon icon="home"></fa-icon>
      Home
    </div>
    <!-- The top-level folders. Initial level is 1, as home is the top. -->
    <ng-container *ngTemplateOutlet="recursiveTemplate; context: { $implicit: folders, level: 1 }"></ng-container>
  </div>

  <!-- 
    This hidden template is used recursively for any array of folders.
    We pass along a "level" context variable to indent child folders.
  -->
  <ng-template #recursiveTemplate let-folders let-level="level">
    <ng-container *ngFor="let folder of folders">
      <div class="folder-item" [style.padding-left.px]="20" *ngIf="(!folderToMove || folderToMove.id != folder.id) && !folder.synced">
        <div class="move-folder-option" (click)="select(folder)" [class.selected]="selectedFolder?.id == folder.id">
          <fa-icon icon="folder"></fa-icon>
          {{ folder.filename }}
        </div>

        <!-- If there are children, recurse with level+1 -->
        <ng-container *ngIf="folder.children?.length">
          <ng-container *ngTemplateOutlet="recursiveTemplate; context: { $implicit: folder.children, level: level + 1 }"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="!moving && filesExistInDestination.length">
    <div>The destination folder contains files that match the names of files you are trying to move. Please rename the following files before moving:</div>
    <div class="font-weight-bold p-10" *ngFor="let file of filesExistInDestination">{{ file.filename }}</div>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="!moving">
  <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!filesExistInDestination.length">Move</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
