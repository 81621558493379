import { WaveformService } from "libs/xplat/core/src/lib/services/waveform.service";
import { AfterViewInit, Component, ElementRef, Input, ViewChild, inject, EventEmitter, Output } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { Subscription } from "rxjs";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";

@Component({
  selector: "myp-waveform-container",
  templateUrl: "waveform-container.component.html",
  host: {
    "(document:keyup)": "keyupEvent($event)",
  },
})
export class WaveformContainerComponent extends BaseComponent implements AfterViewInit {
  constructor(
    private waveformService: WaveformService,
    public userService: UserService,
    public communityService: WebCommunityService,
    public appService: AppService
  ) {
    super(userService);
  }

  @Input() selectedWaveformFile: any;
  @Input() onTrackRendered: Function;
  @Input() waveformClicked: Function;
  @Input() onTimeUpdate: Function;
  @Input() playAtTime: Function;
  @Input() setIsPlaying: Function;
  @Input() trackRendered: boolean;
  @Input() keyupEvent: Function;
  @Input() isPlaying: boolean;
  @Input() audioPaused: EventEmitter<any> = new EventEmitter();
  @Output() audioPlayed: EventEmitter<any> = new EventEmitter();
  @ViewChild("waveformContainer", { static: false }) waveformEl: ElementRef;
  private waveform: WaveSurfer;
  private toggleAudioSubscription: Subscription;
  private playAtTimeSubscription: Subscription;
  private pauseSubscription: Subscription;

  ngAfterViewInit(): void {
    this.createWaveformContainer();
    this.toggleAudioSubscription = this.waveformService.toggleAudio$.subscribe((filename) => {
      if (filename == this.selectedWaveformFile.filename) this.toggleAudio();
    });

    this.playAtTimeSubscription = this.waveformService.playAtTime$.subscribe((time: number) => {
      this.waveform.setTime(time);
      this.waveform.play();
    });

    this.pauseSubscription = this.waveformService.pause$.subscribe(() => {
      this.waveform.pause();
    });
  }

  createWaveformContainer() {
    try {
      const topTimeline = TimelinePlugin.create({
        insertPosition: "beforebegin",
        style: {
          color: "#2D5B88",
        },
      });
      this.waveform = WaveSurfer.create({
        container: this.waveformEl.nativeElement,
        height: 150,
        waveColor: "#E51A89",
        url: this.selectedWaveformFile.url,
        plugins: [topTimeline],
      });
      this.waveform.on("click", () => {
        this.waveformClicked();
      });
      const wsRegions = this.waveform.registerPlugin(RegionsPlugin.create());

      this.waveform.on("decode", () => {
        wsRegions.addRegion({
          start: 0,
          color: "rgba(35, 35, 35, 0.5)",
          drag: false,
          resize: false,
        });
      });

      this.waveform.on("timeupdate", (currentTime) => {
        this.onTimeUpdate(currentTime, this.waveform);
      });

      this.waveform.on("ready", () => {
        this.onTrackRendered();
      });
    } catch (error) {
      console.error(error);
    }
  }

  onPlayButtonClick = (start?: number) => {
    this.setIsPlaying(true);
    if (start) {
      this.waveform.setTime(start);
    }
    this.waveform.play();
  };

  onPauseButtonClick() {
    this.setIsPlaying(false);
    this.waveform.pause();
  }

  toggleAudio = () => {
    if (this.waveform) {
      if (this.isPlaying) {
        this.onPauseButtonClick();
        this.audioPaused.next({
          file: this.selectedWaveformFile.url,
          audio: this.waveform,
        });
        this.communityService.currentlyPlayingWaveformComponent = undefined;
      } else {
        this.waveformService.calculateProgress(this.waveform.getCurrentTime(), this.waveform.getDuration()) >= 100 ? this.playAtTime(0) : this.onPlayButtonClick();
        this.audioPlayed.next({
          file: this.selectedWaveformFile.url,
          audio: this.waveform,
        });
        this.communityService.currentlyPlayingWaveformComponent = this;
      }
    }
  };

  ngOnDestroy() {
    this.toggleAudioSubscription.unsubscribe();
    this.playAtTimeSubscription.unsubscribe();
    this.pauseSubscription.unsubscribe();
  }
}
