import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, mergeMap, timestamp } from "rxjs/operators";
import { environment } from "../environments/environment";
import { UserService } from "./user.service";
import { HelperService } from "./helper.service";
import { BehaviorSubject, forkJoin, of, Subject, throwError } from "rxjs";
import { StorageKeys, StorageService } from "./storage.service";
import { Course, File, Folder, Lesson, Video } from "../models";
import { ActivatedRoute } from "@angular/router";
import { EventBusService } from "./event-bus.service";
import { ProductService } from "./product.service";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  public videosById: any = {};
  public videosByPlatformId: any = {};
  public videosByProductId: any = {};
  public productsAssociatedWithVideos = [];
  public tagsAssociatedWithVideos = [];
  public sidebarTags = [];
  public tagsByTag: any = {};
  public courseMap: any = {};
  public lessonMap: any = {};
  public folderMap: any = {};
  public myVideoNotesMap: any = {};
  public myVideoNotesByVideoId: any = {};

  public groupedFormatsMap: any = {};
  public groupedFilesMap: any = {};

  public rawEducationData: any;
  public rawAdminEducationData: any;
  public loadingPlayId: any;

  public educationDataStream$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  get educationDataStream() {
    return this.educationDataStream$.getValue();
  }

  set educationDataStream(data) {
    this.educationDataStream$.next(data);
  }

  public educationData: any;

  public videos: Array<any>;
  public lessons: Array<Lesson>;
  public courses: Array<Course>;
  public folders: Array<Folder>;

  public cachedVimeofolders: Array<any>;

  public vimeoFolders$: Subject<any> = new Subject();
  public vimeoVideosByFolder$: Subject<any> = new Subject();
  public selectedVideos$: Subject<any> = new Subject();

  private _vimeoFolders: Array<any>;
  get vimeoFolders() {
    return this._vimeoFolders || this.storageService.getItem(StorageKeys.VIMEOFOLDERS);
  }

  set vimeoFolders(folders) {
    this._vimeoFolders = folders;
    this.storageService.setItem(StorageKeys.VIMEOFOLDERS, folders);
  }

  public uploadSession = {
    filesMap: {} as {
      [key: string]: {
        file?: any;
        progress?: number;
        paeEntity?: any;
      };
    },
    get totalFilesUploading(): number {
      return Object.keys(this.filesMap).length;
    },
    get totalProgress(): number {
      let allTotalProgress = 0;
      for (let file in this.filesMap) {
        if (this.filesMap[file].progress) {
          allTotalProgress += this.filesMap[file].progress;
        }
      }
      let percentage = 0;
      if (allTotalProgress && Object.keys(this.filesMap).length) {
        percentage = allTotalProgress / Object.keys(this.filesMap).length; // Average
      }
      if (Math.round(percentage) == 100) {
        this.filesMap = {};
      }
      return Math.round(percentage);
    },
  };

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private _helperService: HelperService,
    public storageService: StorageService,
    public activatedRoute: ActivatedRoute,
    public eventBusService: EventBusService,
    public productService: ProductService
  ) {
    this._helperService.userID = this.userService.user ? this.userService.user.id : undefined;
  }
  public folderDescriptions: any = {
    "sampling-external-instruments":
      '<div class="text-center"><img src="./assets/images/huyskens-series.png" style="max-width: 100%;" /><br /><br />In this Studio One Pro+ Exclusive, Studio One Product Specialist Marcus Huyskens takes us through a detailed workflow overview of how to sample an external instrument using Studio One 5 - and turn it into a playable Presence XT Instrument, that can be uploaded & shared with the community. Although the concepts in this video are based upon Sampling External Instruments, the same techniques, shortcuts, and Macros can be used in ANY scenario with respect to creating, curating, and editing Samples - Be it external, or software based.<br /><br /><a class="btn btn-primary" href="https://mypresonus.s3.amazonaws.com/exchange/sampling-external-instruments-in-studio-one.zip" target="_blank">Download Materials</a> <br /><br /></div>',
    "comping-in-studio-one":
      '<div class="text-center"><img src="./assets/images/marcus-huyskens-comping.png" style="max-width: 100%;" /><br /><br />In this Studio One Pro+ Exclusive Series, Studio One Product Specialist Marcus Huyskens demonstrates some of the different Recording & Comping Workflows that can be utilized in Studio One 5. In addition, watch in realtime as Marcus Comps together a Vocal Performance and begins to piece together what will eventually become a polished final vocal, before it hits the next stage in production.<br /><br /><a class="btn btn-primary" href="https://mypresonus.s3.amazonaws.com/exchange/comping-in-studio-one.zip" target="_blank">Download Materials</a> <br /><br /></div>',
    "studio-one-5.1-score-features":
      '<div class="text-center"><h1 class="brandfont-bold">STUDIO ONE 5.1 SCORE FEATURES</h1>Exclusive Video, Studio One Product Specialist Marcus Huyskens demonstrates how the new Score Features in 5.1 (such as Staff Presets, and Score printing) can streamline & overhaul the entire production process of creating & arranging Horn Lines on a killer funk track provided by John Bastianelli, and Rick Naqvi of Phat Hat.</div>',
    obedia:
      '<div class="text-center m-b-20"><h3 class="brandfont-bold">Getting Started With Studio One by OBEDIA</h3>Exclusively available to Studio One Pro+ members for free, our friends at OBEDIA provide a detailed overview of everything you need to know to get started in Studio One. Note that while these videos demonstrate these common tasks in Studio One Pro 7 v4, everything is applicable to v5.</div>',
    "mixing-for-beginners":
      '<div class="text-center"><img src="./assets/images/sphere-mixing-for-beginners-cover.jpg" style="max-width: 100%;" /><br /><br />In this Studio One Pro+ Exclusive, Home Recording Made Easy\'s David Vignola takes you through the basics of creating a great mix: from session set-up to final mix and everything in between. Whether you\'re brand new to mixing or just looking to pick up a few tricks, this series is loaded with useful information.<br /><br />Want more mixing courses like this? Studio One Pro+ members get 50% off any Home Recording Made Easy training series. All classes are taught in Studio One, making it easy to follow along. Go to <a href="https://www.homerecordingmadeeasy.com/all-training-courses">https://www.homerecordingmadeeasy.com/all-training-courses</a><br /><br /><a class="btn btn-primary" href="https://mypresonus.s3.amazonaws.com/exchange/mixing_for_beginners.zip" target="_blank">Download Materials</a> <br /><br /></div>',
  };

  public partners: Array<any> = [
    {
      name: "Marcus Huyskens Music",
      image: "partner-marcus.jpg",
      link: "https://www.youtube.com/channel/UCrUjOjfA1tu30P3mTNXBoIQ",
    },
    {
      name: "Joe Gilder • Home Studio Corner",
      image: "partner-gilder.jpg",
      link: "https://www.youtube.com/user/HomeStudioCorner",
    },
    {
      name: "Jonny Lipsham Studios",
      image: "partner-lipsham.jpg",
      link: "https://www.youtube.com/channel/UCToY-yTtVEifCiABswzatqA",
    },
    {
      name: "Home studio trainer",
      image: "partner-homestudiotrainer.jpg",
      link: "https://www.youtube.com/channel/UCLetydMjgI5fuHtclyujnrQ",
    },
    {
      name: "Home Recording Made Easy",
      image: "partner-homerecordingmadeeasy.jpg",
      link: "https://www.homerecordingmadeeasy.com/",
    },
    {
      name: "GusLozadaTV",
      image: "partner-guslozada.png",
      link: "https://www.youtube.com/user/GusLozadaTV",
    },
    {
      name: "groove 3",
      image: "partner-groove3.png",
      link: "https://www.groove3.com/browse/by-category/daw/studio-one",
    },
    {
      name: "Obedia",
      image: "partner-obedia.png",
      link: "https://obedia.com/presonus-studio-one/",
    },
    {
      name: "Studio One Tutorials",
      image: "partner-studioonetutorials.jpg",
      link: "http://www.studioonetutorials.com/",
    },
  ];

  public mimTypesByExtension: any = {
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    pdf: "application/pdf",
    mp4: "video/mp4",
    webm: "video/webm",
    m4v: "video/x-m4v",
    qt: "video/quicktime",
    mov: "video/quicktime",
    mp3: "audio/mpeg",
    wave: "audio/x-wav",
    wav: "audio/x-wav",
  };

  public levels: any = {
    0: "EASY",
    1: "INTERMEDIATE",
    2: "DIFFICULT",
    3: "EXPERT",
  };

  public userLearnData: any = {
    videos: {},
    saved_videos: {},
    watched_videos: {},
    lessons: {},
    saved_lessons: {},
    courses: {},
    saved_courses: {},
  };

  getVideos(admin?) {
    // const savedData = this.connections;
    // if (savedData && !forceRefresh) {
    //   this.updateConnections(savedData)
    //   return observableOf(savedData);
    // } else {
    return this.http.get(environment.apiUrl + "content/" + (admin ? "index/true" : ""), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return this.processVideoData(result.videos);
      })
    );
    // }
  }

  getLessons(admin?) {
    return this.http.get(environment.apiUrl + "content/lessons" + (admin ? "index/true" : ""), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return this.processLessonData(result.lessons);
      })
    );
  }

  getCourses(admin?) {
    return this.http.get(environment.apiUrl + "content/courses" + (admin ? "index/true" : ""), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return this.processCourseData(result.courses);
      })
    );
  }

  getLearnItem(id) {
    return this.http.get(environment.apiUrl + "content/learn_item/" + id, this._helperService.getHttpOptions());
  }

  saveLearnGroupOrder(ids) {
    return this.http.put(environment.paeApiUrl + "content/learn-group-order/", JSON.stringify(ids), this._helperService.getHttpOptions());
  }

  handleEducationData(data, admin?) {
    this[admin ? "rawAdminEducationData" : "rawEducationData"] = data;
    let results = this._processEducationData(data);
    if (this.activatedRoute.snapshot.queryParamMap.get("viewingVideo")) {
      let video = this.videosById[this.activatedRoute.snapshot.queryParamMap.get("viewingVideo")];
      if (video) {
        this.eventBusService.emit(this.eventBusService.types.playVideo, {
          id: video.platform_id,
          title: video.title,
          type: video.platform,
          thumb: video.image,
          presonus_id: video.id,
        });
      } else if (this.userService.isEmployee) {
        this.getLearnItem(this.activatedRoute.snapshot.queryParamMap.get("viewingVideo")).subscribe((result: any) => {
          if (result && result.item) {
            this.eventBusService.emit(this.eventBusService.types.playVideo, {
              id: result.item.platform_id,
              title: result.item.title,
              type: result.item.platform,
              thumb: result.item.image,
              presonus_id: result.item.id,
              presonusVideo: result.item,
            });
          }
        });
      }
    }
    this.educationDataStream = results;
    return results;
  }

  getEducationData(admin?, forceRefresh?) {
    const cachedData = admin ? this.rawAdminEducationData : this.rawEducationData;
    let savedData = false;
    if (cachedData && !forceRefresh) {
      let data = this._processEducationData(cachedData);
      this.educationDataStream = data;
      return of(data);
    } else if (savedData && !forceRefresh) {
      let data = this._processEducationData(savedData);
      this.educationDataStream = data;
      return of(data);
    } else {
      return this.http.get(environment.paeApiUrl + "content/learn-data/" + (admin ? "?admin=true" : ""), this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          return this.handleEducationData(result, admin);
        })
      );
    }
  }

  processVideoNoteData(notes) {
    this.myVideoNotesMap = {};
    this.myVideoNotesByVideoId = {};
    if (notes && notes.length) {
      notes.forEach((note) => {
        this.myVideoNotesMap[note.id] = note;
        if (!this.myVideoNotesByVideoId[note.video_id]) this.myVideoNotesByVideoId[note.video_id] = [];
        this.myVideoNotesByVideoId[note.video_id].push(note);
      });
    }
  }

  private _processEducationData(result) {
    this.processVideoNoteData(result.video_notes);
    this.educationData = {
      videos: this.processVideoData(result.videos, result.user_stats),
      lessons: this.processLessonData(result.lessons),
      courses: this.processCourseData(result.courses),
      folders: this.processFolderData(result.folders),
      user_stats: result.user_stats,
    };
    return this.educationData;
  }

  syncVideos() {
    return this.http.post(environment.apiUrl + "content/sync_videos", JSON.stringify({ process_vimeo: true, process_youtube: true }), this._helperService.getHttpOptions());
  }

  deleteLearnGroup(id) {
    return this.http.post(environment.apiUrl + "content/delete_learn_group/", JSON.stringify({ id }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  deleteVideo(id) {
    return this.http.post(environment.apiUrl + "content/delete_video/", JSON.stringify({ id }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  addOrUpdateLearnGroup(data) {
    return this.http.post(environment.apiUrl + "content/add_or_update_learn_group/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  searchTags(query) {
    return this.http.get(environment.apiUrl + "content/tags/" + query, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result.tags;
      })
    );
  }

  createTag(data) {
    return this.http.post(environment.apiUrl + "content/create_tag/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  removeTag(data) {
    return this.http.post(environment.apiUrl + "content/remove_tag/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  associateTag(data) {
    return this.http.post(environment.apiUrl + "content/associate_tag/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getVideoNotes() {
    return this.http.get(environment.apiUrl + "content/video_notes/", this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.processVideoNoteData(result.video_notes);
        return result.video_notes;
      })
    );
  }

  createVideoNote(data) {
    return this.http.post(environment.apiUrl + "content/create_video_note/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  updateVideoNote(data) {
    return this.http.post(environment.apiUrl + "content/update_video_note/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  deleteVideoNote(data) {
    return this.http.post(environment.apiUrl + "content/delete_video_note/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  saveUserVideoData(data) {
    return this.http.put(environment.paeApiUrl + "content/user-video-stats/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.rawEducationData = false;
        this.rawAdminEducationData = false;
        this._processUserStats(result.user_stats);
        return result;
      })
    );
  }

  saveUserLearnGroupData(data) {
    return this.http.post(environment.apiUrl + "content/save_user_learn_group_data/", JSON.stringify(data), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.rawEducationData = false;
        this.rawAdminEducationData = false;
        this._processUserStats(result.user_stats);
        return result;
      })
    );
  }

  getPresignedUploadURL(fileData) {
    let url = environment.paeApiUrl + "content/create-upload-url";
    return this.http.post(url, JSON.stringify(fileData), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  deleteFolderWithContents(id) {
    return this.http.delete(environment.paeApiUrl + `content/folders/${id}/remove-contents`, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getUserBackups() {
    return this.http.get(environment.paeApiUrl + `content/file?type=backup`, this._helperService.getHttpOptions());
  }

  public processVideoData(videos, user_stats?) {
    this.productsAssociatedWithVideos = [];
    this.tagsAssociatedWithVideos = [];
    this.sidebarTags = [];
    let added = {};
    videos.forEach((item) => {
      item.duration = parseInt(item.duration);
      if (this.userLearnData.videos[item.id] && this.userLearnData.videos[item.id]) {
        item.watched = this.userLearnData.videos[item.id].marked_done ? item.duration : this.userLearnData.videos[item.id].watched;
        item.progress = item.watched == item.duration ? 100 : parseInt(((item.watched * 100) / item.duration).toFixed(0));
      }
      this.videosByPlatformId[item.platform_id] = item;
      this.videosById[item.id] = item;
      if (item.tags && item.tags.length) {
        item.tags.forEach((tag) => {
          this.tagsByTag[tag.title] = tag;
        });
      }
      if (item.tags && item.tags.length) {
        item.tags.forEach((tag) => {
          tag.string_id = tag.title.toLowerCase().replace(/ /g, "_");
          if (!added[tag.id]) {
            this.tagsAssociatedWithVideos.push(tag);
            if (tag.show_in_sidebar) this.sidebarTags.push(tag);
            added[tag.id] = true;
          }
        });
      }
      if (item.products && item.products.length) {
        item.products.forEach((product) => {
          if (!added[product.id]) {
            this.productsAssociatedWithVideos.push(product);
            added[product.id] = true;
          }
        });
      }
    });
    this.productsAssociatedWithVideos.sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    });
    this.sidebarTags.sort((a, b) => (a.title > b.title ? 1 : -1));
    if (user_stats) this._processUserStats(user_stats);
    this.videos = videos;
    return videos;
  }

  public processCourseData(courses) {
    courses.forEach((cur) => {
      cur.string_id = cur.title.toLowerCase().replace(/ /g, "_");
      let runtime = 0;
      let secondsWatched = 0;
      let videoCount = 0;
      cur.lessons = [];
      if (cur.lesson_ids && cur.lesson_ids.length) {
        cur.lesson_ids.forEach((id) => {
          let les = this.lessonMap[id];
          if (les) {
            runtime += les.runtime;
            videoCount += les.videos.length;
            if (les.watched) secondsWatched += les.watched;

            cur.lessons.push(les);
          }
        });

        cur.runtime = runtime;
        cur.totalVideos = videoCount;
        cur.watched = secondsWatched;
        cur.progress = secondsWatched == runtime ? 100 : parseInt(((secondsWatched * 100) / runtime).toFixed(0));
        this.courseMap[cur.id] = cur;
      }
    });
    this.courses = courses;
    return this.courses;
  }

  public processLessonData(lessons) {
    lessons.forEach((les) => {
      les.string_id = les.title.toLowerCase().replace(/ /g, "_");
      let secondsWatched = 0;
      les.videos = [];
      let runtime = 0;
      if (les.video_ids && les.video_ids.length) {
        les.video_ids.forEach((id) => {
          let vid = this.videosById[id];
          if (vid) {
            les.videos.push(vid);
            runtime += vid.duration;
            if (this.userLearnData.videos[vid.id]) {
              secondsWatched += this.userLearnData.videos[vid.id].marked_done ? vid.duration : this.userLearnData.videos[vid.id].watched;
            }
          }
        });
        les.totalVideos = les.videos.length;
        les.runtime = runtime;
        les.watched = secondsWatched;
        les.progress = secondsWatched == runtime ? 100 : parseInt(((secondsWatched * 100) / runtime).toFixed(0));
        this.lessonMap[les.id] = les;
      }
    });
    this.lessons = lessons;
    return this.lessons;
  }

  public processFolderData(folders) {
    let activeFolders = [];
    folders.forEach((fol) => {
      if (fol.status != "deleted") {
        fol.string_id = fol.title.toLowerCase().replace(/ /g, "_");
        fol.videos = [];
        if (fol.video_ids && fol.video_ids.length) {
          fol.video_ids.forEach((id) => {
            let vid = this.videosById[id];
            if (vid) {
              fol.videos.push(vid);
            }
          });
          fol.totalVideos = fol.videos.length;
        }
        this.folderMap[fol.id] = fol;
        if (fol.title == "Exclusive Livestreams") fol.icon = "./assets/icons/plus-icon-blue.png";
        if (fol.title == "Mix Critiques") fol.icon = "./assets/icons/plus-icon-blue.png";
        if (fol.title == "Song Production Critiques") fol.icon = "./assets/icons/plus-icon-blue.png";
        if (fol.title.indexOf("Studio One Pro 7") > -1) fol.icon = "./assets/images/s17-images/seven-icon-blue.png";
        activeFolders.push(fol);
      }
    });
    activeFolders.unshift({
      created: "2022-06-21 14:11:55",
      created_by: "0",
      description: "Video content exclusively available for " + environment.studioOnePlusBrandName + " Members.",
      id: "-1",
      image: null,
      level: null,
      parent_id: null,
      resources_link: null,
      snippet: "",
      status: "approved",
      subscription_only: false,
      icon: "./assets/icons/plus-icon-blue.png",
      title: environment.studioOnePlusBrandName + " Exclusives",
      type: "folder",
      string_id: "exclusive_videos",
      videos: this.videos.filter((item: Video) => item.subscription_only),
    });
    // if (this.productService.s16Released) {
    //   let s16Videos = this.videos.filter((item: Video) => item.title.indexOf("Studio One 6") > -1);
    //   if (s16Videos && s16Videos.length) {
    //     activeFolders.unshift({
    //       created: "2022-06-21 14:11:55",
    //       created_by: "0",
    //       description: "Learn all about the new Studio One 6 features here!",
    //       id: "-2",
    //       image: null,
    //       level: null,
    //       parent_id: null,
    //       resources_link: null,
    //       snippet: "",
    //       status: "approved",
    //       subscription_only: false,
    //       title: "Studio One 6 Features",
    //       icon: "./assets/images/s16-6.png",
    //       type: "folder",
    //       string_id: "studio_one_6_features",
    //       videos: s16Videos,
    //     });
    //   }
    // }
    this.folders = activeFolders;
    return this.folders;
  }

  private _processVimeoVideos(data, sortBy, uri = "all", save = true) {
    let videos = data.filter((item) => {
      return item.status == "available" && item.name.substring(0, 6) != "HIDDEN";
    });
    if (videos && videos.length) {
      videos.forEach((item) => {
        item.display_date = item.type == "live" ? item.last_user_action_event_date : item.created_time;
      });
      videos.sort((a, b) => {
        if (sortBy == "name") {
          return a.name <= b.name ? -1 : 1;
        } else {
          return new Date(a.display_date) <= new Date(b.display_date) ? 1 : -1;
        }
      });
    }
    let savedVideos = this.storageService.getItem(StorageKeys.VIMEOVIDEOSBYFOLDER);
    if (!savedVideos) savedVideos = {};
    savedVideos[uri] = videos;
    if (save) this.storageService.setItem(StorageKeys.VIMEOVIDEOSBYFOLDER, savedVideos);
    this.vimeoVideosByFolder$.next(savedVideos);
    this.selectedVideos$.next(videos);
    return videos;
  }

  private _processUserStats(data) {
    if (data.videos && data.videos.length) {
      let newSavedVideoMap = {};
      let newWatchedVideoMap = {};
      data.videos.forEach((item) => {
        let video = this.videosById[item.video_id];
        if (video) {
          item.watched = item.marked_done ? video.duration : item.watched;
          item.progress = item.watched == video.duration ? 100 : parseInt(((item.watched * 100) / video.duration).toFixed(0));
          this.userLearnData.videos[item.video_id] = item;
        }
        if (item.saved) {
          newSavedVideoMap[item.video_id] = true;
          this.userLearnData.saved_videos[item.video_id] = item;
        } else if (item.progress < 90) {
          newWatchedVideoMap[item.video_id] = true;
          this.userLearnData.watched_videos[item.video_id] = item;
        }
      });
      for (var i in this.userLearnData.saved_videos) if (!newSavedVideoMap[i]) delete this.userLearnData.saved_videos[i];
      for (var i in this.userLearnData.watched_videos) if (!newWatchedVideoMap[i]) delete this.userLearnData.watched_videos[i];
    }
    if (data.lessons && data.lessons.length) {
      let newSavedLessonMap = {};
      data.lessons.forEach((lesson) => {
        if (!this.userLearnData.lessons[lesson.learn_group_id] || JSON.stringify(this.userLearnData.lessons[lesson.learn_group_id]) != JSON.stringify(lesson)) {
          this.userLearnData.lessons[lesson.learn_group_id] = lesson;
        }
        if (lesson.enrolled) {
          newSavedLessonMap[lesson.learn_group_id] = true;
          if (!this.userLearnData.saved_lessons[lesson.learn_group_id] || JSON.stringify(this.userLearnData.saved_lessons[lesson.learn_group_id]) != JSON.stringify(lesson)) {
            this.userLearnData.saved_lessons[lesson.learn_group_id] = lesson;
          }
        }
      });
      for (var i in this.userLearnData.saved_lessons) if (!newSavedLessonMap[i]) delete this.userLearnData.saved_lessons[i];
    }
    if (data.courses && data.courses.length) {
      let newSavedCourseMap = {};
      data.courses.forEach((course) => {
        if (!this.userLearnData.courses[course.learn_group_id] || JSON.stringify(this.userLearnData.courses[course.learn_group_id]) != JSON.stringify(course)) {
          this.userLearnData.courses[course.learn_group_id] = course;
        }
        if (course.enrolled) {
          newSavedCourseMap[course.learn_group_id] = true;
          if (!this.userLearnData.saved_courses[course.learn_group_id] || JSON.stringify(this.userLearnData.saved_courses[course.learn_group_id]) != JSON.stringify(course)) {
            this.userLearnData.saved_courses[course.learn_group_id] = course;
          }
        }
      });
      for (var i in this.userLearnData.saved_courses) if (!newSavedCourseMap[i]) delete this.userLearnData.saved_courses[i];
    }
  }

  saveVideo(video) {
    return this.http.post(environment.apiUrl + "content/update_video", JSON.stringify(video), this._helperService.getHttpOptions()).pipe();
  }

  getVimeoFolders(force?) {
    const savedFolders = this.storageService.getItem(StorageKeys.VIMEOFOLDERS);
    if (!force && savedFolders) {
      this.vimeoFolders$.next(savedFolders);
      return of(savedFolders);
    } else {
      let headers = {
        Authorization: "bearer " + environment.vimeoAuthToken,
      };
      return this.http.get("https://api.vimeo.com/me/projects", { headers: new HttpHeaders(headers) }).pipe(
        map((data: any) => {
          this.cachedVimeofolders = data.data;
          this.vimeoFolders = this.cachedVimeofolders;
          this.vimeoFolders$.next(this.cachedVimeofolders);
          return this.cachedVimeofolders;
        })
      );
    }
  }

  getVideo(videoID) {
    let headers = {
      Authorization: "bearer " + environment.vimeoAuthToken,
    };
    return this.http.get("https://api.vimeo.com/videos/" + videoID, { headers: new HttpHeaders(headers) }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getVimeoVideosByFolder(uri, sortBy = "name", force?, limit = 100) {
    let savedVideos = this.storageService.getItem(StorageKeys.VIMEOVIDEOSBYFOLDER);
    if (limit == 100 && !force && savedVideos && savedVideos[uri] && savedVideos[uri].length) {
      savedVideos[uri].forEach((item) => {
        item.display_date = item.type == "live" ? item.last_user_action_event_date : item.created_time;
      });
      savedVideos[uri].sort((a, b) => {
        if (sortBy == "name") {
          return a.name <= b.name ? -1 : 1;
        } else {
          return new Date(a.display_date) <= new Date(b.display_date) ? 1 : -1;
        }
      });

      this.vimeoVideosByFolder$.next(savedVideos);
      this.selectedVideos$.next(savedVideos[uri]);
      return of(savedVideos[uri]);
    } else {
      let headers = {
        Authorization: "bearer " + environment.vimeoAuthToken,
      };
      let url = "https://api.vimeo.com/me/videos?sort=date&per_page=" + limit;
      if (uri != "all") url = "https://api.vimeo.com/" + uri + "/videos?sort=date&per_page=" + limit;
      return this.http.get(url, { headers: new HttpHeaders(headers) }).pipe(
        mergeMap((data: any) => {
          if (data && limit == 100 && data.paging && data.paging.next) {
            return this.http.get((url += "&page=2"), { headers: new HttpHeaders(headers) }).pipe(
              mergeMap((moreData: any) => {
                return of(this._processVimeoVideos([...data.data, ...moreData.data], sortBy, "all", limit == 100));
              })
            );
          } else {
            let videos = this._processVimeoVideos([...data.data], sortBy, uri, limit == 100);
            return of(videos);
          }
        })
      );
    }
  }

  getObediaVimeoVideos(force?) {
    let savedVideos = this.storageService.getItem(StorageKeys.VIMEOVIDEOSBYFOLDER);
    if (!force && savedVideos && savedVideos["obedia"] && savedVideos["obedia"].length) {
      savedVideos["obedia"].forEach((item) => {
        item.sortNumber = parseInt(item.name.split(". ")[0]);
        item.display_date = item.type == "live" ? item.last_user_action_event_date : item.created_time;
      });
      savedVideos["obedia"].sort((a, b) => {
        return a.sortNumber <= b.sortNumber ? -1 : 1;
      });

      this.vimeoVideosByFolder$.next(savedVideos);
      this.selectedVideos$.next(savedVideos["obedia"]);
      return of(savedVideos["obedia"]);
    } else {
      let headers = {
        Authorization: "bearer " + environment.obediaVimeoAuthToken,
      };
      return this.http.get("https://api.vimeo.com/users/38029758/projects/4946258/videos?per_page=100", { headers: new HttpHeaders(headers) }).pipe(
        map((data: any) => {
          if (data && data.data && data.data.length) {
            let videos = data.data.filter((item) => {
              item.sortNumber = parseInt(item.name.split(". ")[0]);
              return item.status == "available" && item.name.substring(0, 6) != "HIDDEN";
            });
            videos.sort((a, b) => {
              return a.sortNumber <= b.sortNumber ? -1 : 1;
            });
            let savedVideos = this.storageService.getItem(StorageKeys.VIMEOVIDEOSBYFOLDER);
            if (!savedVideos) savedVideos = {};
            savedVideos["obedia"] = videos;
            this.storageService.setItem(StorageKeys.VIMEOVIDEOSBYFOLDER, savedVideos);
            this.vimeoVideosByFolder$.next(savedVideos);
            this.selectedVideos$.next(videos);
            return videos;
          } else {
            return [];
          }
        })
      );
    }
  }

  sendEmail(args, method) {
    return this.http.post(environment.apiUrl + "users/" + method, JSON.stringify(args), this._helperService.getHttpOptions()).pipe();
  }
}
