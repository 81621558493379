import { Component, OnInit } from "@angular/core";
import { BaseComponent, UserService, WorkspaceService, WindowService, EventBusService, HelperService, ContentService, File } from "@mypxplat/xplat/core";
import { filter, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { format } from "date-fns";
import { BytesToSizePipe } from "libs/xplat/features/src/lib/ui/pipes/bytestosize.pipe";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-account-file-management",
  templateUrl: "./file-management.component.html",
})
export class AccountFileManagementComponent extends BaseComponent implements OnInit {
  public storageData: any;
  public filesMap: any = {};
  public allFiles: Array<any> = [];
  public backupsCollapsed: boolean = true;
  public files: Array<any> = [];
  public postAttachments: Array<any> = [];
  public attachmentsCollapsed: boolean = true;
  public filesCollapsed: boolean = true;
  public filesByWorkspace: any = {};
  public workspaces: Array<string>;
  public filterQuery: string;
  public fileSortSettings: any = {
    sortBy: "size",
    sortDirection: "desc",
    groupByWorkspace: false,
    selectMode: false,
  };
  public backupSortSettings: any = {
    sortBy: "size",
    sortDirection: "desc",
  };
  public selection: any;
  public allSelected: boolean;
  public anySelected: boolean;
  public currentPage: number = 1;
  public paging: boolean = false;
  public shouldShowBackups: boolean = false;
  public backups: Array<File> = [];

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    private _eventbusService: EventBusService,
    private _win: WindowService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public translate: TranslateService,
    public helperService: HelperService,
    public contentService: ContentService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.userService.storageDetails$
      .pipe(
        filter((items) => !!items),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        console.log("storageData gottle");
        this.storageData = result;
      });
    this.userService.getStorageDetails(false, true).subscribe();
    this.contentService.getUserBackups().subscribe((result: any) => {
      this.backups = result;
    });
  }

  getBackupMetadata(file: File) {
    const bytesToSize = new BytesToSizePipe();
    let data = [];
    data.push({ title: "Created", value: format(file.date_created, "MMM do, yyyy") });
    data.push({ title: "Size", value: bytesToSize.transform(file.filesize) });
    data.push({ title: "Application", value: file.application });
    return data;
  }

  goWorkspace(name) {
    const file = this.files.filter((file) => file.workspace_name == name)[0];
    this.router.navigate(["/workspaces/detail", file.workspace_id]);
  }

  downloadFiles() {
    let files = [];
    for (var i in this.selection) {
      this.selection[i].selectedForBatchDownload = false;
      files.push(this.selection[i]);
    }
    if (!files.length) {
      alert("You did not select any files to download.");
    } else {
      let timeout = 0;
      files.forEach((item) => {
        timeout += 500;
        item.delay = timeout;
      });
      files.forEach((file) => {
        this._win.setTimeout(() => {
          this.downloadFile(file);
        }, file.delay);
      });
    }
  }

  public removingBackups: any = {};
  removeBackup(backup: File) {
    if (confirm("Are you sure you want to remove this backup? This cannot be undone.")) {
      this.removingBackups[backup.id] = true;
      this.workspaceService.deleteFile(backup.id).subscribe({
        next: (result) => {
          this.backups = this.backups.filter((item) => item.id != backup.id);
        },
        error: (error) => {
          this.appService.alertError(error);
        },
      });
    }
  }

  downloadFile(file) {
    let link = document.createElement("a");
    link.href = file.url;
    link.download = file.url;
    link.click();
  }
}
