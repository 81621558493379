<div class="modal-header">
  <h4 class="modal-title">Edit Comment</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body edit-post" ngbAutofocus>
  <div class="form-group" *ngIf="!isCommunityPost && !isVideoNote && !isHtmlComment">
    <textarea type="text" name="comment" style="height: 400px" id="comment" [(ngModel)]="comment.message" class="form-control"></textarea>
  </div>
  <div class="form-group" *ngIf="isVideoNote">
    <span
      role="textbox"
      aria-label="message"
      class="editable-message-span input-style"
      (keyup)="keyUpEvent($event)"
      (paste)="contentPasted($event)"
      (focusout)="focusOut($event)"
      contenteditable
      #messageSpan
      id="message-span"
    ></span>
  </div>
  <div class="form-group edit-comment-form" *ngIf="comment.subject">
    <myp-message-input
      #editSubjectInput
      [type]="'span'"
      [backgroundColor]="appService.activeTheme == 'dark' ? '#2e2e2e' : '#e9e9e9'"
      inputID="edit-subject-input"
      (valueChanged)="postSubjectChanged($event)"
      [placeholder]="'com.start_new_post' | translate"
      [shouldDetectContent]="false"
      [includeSendButton]="false"
      [mentionSource]="false"
      [mentionDataSource]="false"
    ></myp-message-input>
  </div>
  <div class="form-group edit-comment-form" *ngIf="isCommunityPost || isHtmlComment">
    <myp-message-input
      #editPostInput
      [type]="'span'"
      inputID="edit-post-input"
      [backgroundColor]="appService.activeTheme == 'dark' ? '#2e2e2e' : '#e9e9e9'"
      (valueChanged)="postContentChanged($event)"
      [placeholder]="'com.start_new_post' | translate"
      [shouldDetectContent]="isCommunityPost"
      [includeSendButton]="false"
      [useQuill]="isBetaComment"
      [mentionSource]="isCommunityPost ? 'community_profiles' : true"
      [mentionDataSource]="isCommunityPost ? false : collaborators"
      (contentDetected)="contentDetected($event)"
      (mentionSelected)="mentionSelected($event)"
    ></myp-message-input>
  </div>
  <ng-container *ngIf="!isVideoNote">
    <div class="file-drop-zone" (onFileDrop)="droppedFile($event)" ng2FileDrop [uploader]="uploader" (fileOver)="fileOverBase($event)" [class.hovering]="hasBaseDropZoneOver">
      <div class="d-flex flex-wrap" *ngIf="files && files.length && isCommunityPost">
        <div class="attachment" *ngFor="let attachment of files">
          <img [src]="attachment.url" *ngIf="workspaceService.imageFiles[attachment.extension]" class="image-border" style="height: 100px" />
          <audio id="audio_player" *ngIf="workspaceService.audioFiles[attachment.extension]" controls="true">
            <source [src]="attachment.url" type="audio/wav" />
          </audio>
          <span class="gothic t-18" *ngIf="!workspaceService.audioFiles[attachment.extension] && !workspaceService.imageFiles[attachment.extension]">
            <fa-icon icon="file" class="m-r-10"></fa-icon>
            <span>{{ attachment.filename }}</span>
          </span>
          <div class="remove" (click)="removeAttachment(attachment)"><fa-icon icon="times-circle" class="c-red"></fa-icon></div>
        </div>
      </div>

      <ng-container *ngIf="isCommunityPost">
        <div *ngIf="comment.articles && comment.articles.length">
          <div class="comment-article-attachment" *ngFor="let item of comment.articles">
            <fa-icon icon="newspaper" class="icon"></fa-icon>
            <div>
              <span class="bold">{{ item.article.name }}</span>
              <br />
              <span class="t-12 text-muted">{{ item.article.created_at_pretty }}</span>
            </div>
            <fa-icon icon="times-circle" class="close-btn" (click)="removeArticle(item, $event)"></fa-icon>
          </div>
        </div>

        <div class="post-attachments" *ngIf="comment.embeds && comment.embeds.length && !queued">
          <ng-container *ngFor="let embed of comment.embeds">
            <div class="message-embed message-embed--{{ embed.type }} message-embed--{{ embed.subtype }}">
              <fa-icon icon="times-circle" class="close-btn" (click)="removeEmbed(embed, $event)"></fa-icon>
              <iframe width="560" height="315" [src]="embed.src" frameborder="0" allowfullscreen></iframe>
            </div>
          </ng-container>
        </div>

        <div class="m-y-20" *ngIf="comment.learn_content && comment.learn_content.length">
          <ng-container *ngFor="let item of comment.learn_content">
            <div class="grid-container wider pad-10" *ngIf="item.content.type == 'video'">
              <myp-video-grid-item [showBorder]="true" [video]="item.content" [showRemoveFromPost]="true" (removeFromPostClicked)="removeLearnContent(item, $event)"></myp-video-grid-item>
            </div>
            <ng-container *ngIf="item.content.type != 'video'">
              <myp-learn-list-item
                [shouldShowButtons]="false"
                [showRemoveFromPost]="true"
                [item]="item.content"
                [preventNav]="true"
                (removeFromPostClicked)="removeLearnContent(item, $event)"
              ></myp-learn-list-item>
            </ng-container>
          </ng-container>
        </div>
        <div class="d-flex align-items-center">
          <span class="d-block m-r-10 t-12 bold">Attach:</span>
          <fa-icon class="circle-icon-btn m-r-5" (click)="attachFile()" [ngbTooltip]="'File'" icon="file"></fa-icon>
          <fa-icon class="circle-icon-btn m-r-5" (click)="showAttach('videos')" [ngbTooltip]="'Video'" icon="video"></fa-icon>
          <fa-icon class="circle-icon-btn m-r-5" (click)="showAttach('articles')" [ngbTooltip]="'Article'" icon="newspaper"></fa-icon>
        </div>
      </ng-container>

      <div class="w-100" *ngIf="uploadingFiles && uploadingFiles.length">
        <ng-container *ngFor="let file of uploadingFiles">
          <span class="text-muted t-14">{{ "general.uploading" | translate }}: {{ file.filename }} {{ file.progress }}%</span>
          <div class="post-progress-bar" [ngStyle]="{ width: (file.progress ? file.progress : 0) + '%' }"></div>
        </ng-container>
      </div>
    </div>
    <hr />
  </ng-container>

  <div class="form-group m-t-30" *ngIf="communityService.availableSkills && isCommunityPost && !comment.parentID">
    <div class="d-flex flex-wrap align-items-center position-relative m-t-5">
      <span class="t-12 text-muted">Channels:</span>
      <ng-container *ngFor="let channel of comment.channels">
        <span class="showcase-selected-skill">
          # {{ isBetaComment ? channel : ("com.skills." + channel | translate) }}
          <fa-icon icon="times-circle" class="m-l-5" (click)="removeChannel(channel)"></fa-icon>
        </span>
      </ng-container>
      <span class="add-channel-button" *ngIf="!comment.channels || comment.channels.length < 3">
        <fa-icon icon="plus-circle" class="c-green t-14 pointer m-l-10" (click)="shouldShowChannelSelector = !shouldShowChannelSelector"></fa-icon>
      </span>

      <div class="channel-selector" *ngIf="shouldShowChannelSelector">
        <div class="overflow-auto h-100" *ngIf="!isBetaComment">
          <div class="bold pointer t-14 p-1" *ngFor="let channel of communityService.taggableChannels" (click)="addChannel(channel)">
            <fa-icon icon="hashtag" class="c-lblue"></fa-icon>
            {{ channel.title }}
          </div>
        </div>
        <div class="overflow-auto h-100" *ngIf="isBetaComment">
          <div class="bold pointer t-14 p-1" *ngFor="let channel of betaService.communityTags" (click)="addChannel(channel)">
            <fa-icon icon="hashtag" class="c-lblue"></fa-icon>
            {{ channel.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save()">{{ "general.save" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
