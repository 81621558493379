import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, ProductService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-skeleton-loader",
  templateUrl: "skeleton-loader.component.html",
})
export class SkeletonLoaderComponent extends BaseComponent implements OnInit {
  @Input() type: string;
  @Input() width: string;
  @Input() height: string;
  @Input() borderRadius: string;
  @Input() number: number = 1;
  @Input() extraContainerClass: string;
  @Input() useRandomWidth: boolean = false;

  public instances: Array<any>;

  randomWidths: any = {};

  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {
    let arr = [];
    for (let i = 0; i < this.number; i++) {
      arr.push(i);
    }
    this.instances = arr;
    if (this.useRandomWidth) {
      this.instances.forEach((line, index) => {
        this.randomWidths[index] = Math.floor(Math.random() * 60) + 40 + "%";
      });
    }
  }
}
