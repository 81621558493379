import { Injectable, EventEmitter, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { UserService, environment } from "@mypxplat/xplat/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FirebaseService } from "./firebase.service";

export interface IForegroundMessage {
  data: any;
  from: any;
  priority: any;
  notification: {
    title: string;
    body: string;
  };
}

@Injectable()
export class MessagingService {
  // currentMessage = new BehaviorSubject(null);
  public foregroundMessageReceived$: EventEmitter<any> = new EventEmitter();

  constructor(
    private angularFireAuth: AngularFireAuth,
    private _userService: UserService,
    private _zone: NgZone,
    public db: AngularFirestore,
    private angularFireMessaging: AngularFireMessaging,
    public fbService: FirebaseService
  ) {}

  /**
   * request permission for notification from firebase cloud messaging
   *
   */
  requestPermission() {
    return new Promise((resolve, reject) => {
      this.angularFireMessaging.requestToken.subscribe({
        next: (result) => {
          (token) => {
            if (this._userService.user && this._userService.user.id) {
              const tokensCollection = this.db.collection("push_tokens");
              let myTokenDocRef = tokensCollection.doc(this._userService.user.id).ref;
              this.fbService.handleFirestorePromise(() =>
                myTokenDocRef.set({
                  token: token,
                  user_id: this._userService.user.id,
                })
              );
              if (token != this._userService.user.push_token) {
                this._userService.saveUser({ push_token: token }).subscribe((result) => {
                  resolve(true);
                  // console.log('Saved Push Token', result);
                });
              } else {
                // console.log('Token doesnt need to be saved because it already matches.');
                resolve(true);
              }
            } else {
              reject(new Error("User is not logged in."));
            }
          };
        },
        error: (er) => {
          // console.error('Unable to get permission to notify.', err);
        },
      });
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      // console.log("New Message Received", payload);
      if (payload.data && payload.data.message) {
        payload.data.message = JSON.parse(payload.data.message);
      }
      this._zone.run(() => {
        this.foregroundMessageReceived$.next(payload);
      });
    });
  }
}
