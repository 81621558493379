<ng-container *ngIf="type == 'product-tile'">
  <div
    *ngFor="let item of instances; let i = index"
    [ngStyle]="{ height: height || '280px', 'border-radius': '10px' }"
    [class]="'shimmer product-tile' + (extraContainerClass ? ' ' + extraContainerClass : '')"
  ></div>
</ng-container>

<ng-container *ngIf="type == 'line'">
  <div
    *ngFor="let item of instances; let i = index"
    [class]="'shimmer' + (extraContainerClass ? ' ' + extraContainerClass : '')"
    [ngStyle]="{ height: height || '10px', 'border-radius': borderRadius || '5px', width: width || randomWidths[i] || '80%' }"
  ></div>
</ng-container>

<ng-container *ngIf="type == 'learn-video'">
  <div *ngFor="let item of instances; let i = index" [class]="extraContainerClass ? extraContainerClass : ''">
    <div class="card video-grid-item hover dontgrow video">
      <div class="video-grid-item-container">
        <div class="card-header image-container image-border shimmer"></div>
        <div class="card-body">
          <div class="video-title-container shimmer" style="height: 20px; border-radius: 5px"></div>
          <div class="video-title-container shimmer" style="height: 20px; width: 80%; margin-top: 20px; border-radius: 5px"></div>
          <div class="video-title-container shimmer" style="height: 20px; width: 40%; margin-top: 20px; border-radius: 5px"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == 'workspace-list-item'">
  <div *ngFor="let item of instances; let i = index" [class]="'d-flex p-10' + (extraContainerClass ? ' ' + extraContainerClass : '')">
    <div style="width: 40px; height: 40px; border-radius: 5px" class="shimmer"></div>
    <div style="flex: 1; margin-left: 10px">
      <div style="height: 10px; width: 60%; margin-top: 5px; border-radius: 5px" class="shimmer"></div>
      <div style="height: 10px; width: 80%; margin-top: 5px; border-radius: 5px" class="shimmer"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == 'list-item' || type == 'list-item-large'">
  <ng-container *ngFor="let item of instances; let i = index">
    <ng-container *ngTemplateOutlet="listItem"></ng-container>
  </ng-container>
</ng-container>

<div
  [class]="'shimmer' + (extraContainerClass ? ' ' + extraContainerClass : '')"
  *ngIf="type == 'carousel'"
  [ngStyle]="{ width: width || '100%', height: height || '215px', 'border-radius': borderRadius || '30px', 'max-width': maxWidth || 'auto' }"
></div>

<div *ngIf="type == 'workspace'">
  <div style="width: 200px; margin: 10px; height: 30px; border-radius: 5px" class="shimmer"></div>
  <div class="d-flex">
    <div style="width: 40%">
      <div style="margin: 10px; height: 300px; border-radius: 10px" class="shimmer"></div>
      <div class="m-10"><ng-container *ngTemplateOutlet="listItem"></ng-container></div>
      <div class="m-10"><ng-container *ngTemplateOutlet="listItem"></ng-container></div>
      <div class="m-10"><ng-container *ngTemplateOutlet="listItem"></ng-container></div>
      <div class="m-10"><ng-container *ngTemplateOutlet="listItem"></ng-container></div>
    </div>

    <div style="width: 60%; height: 900px">
      <div style="margin: 10px; height: 100%; border-radius: 10px" class="shimmer"></div>
    </div>
  </div>
</div>

<div *ngIf="type == 'product-detail'">
  <div class="d-flex">
    <div style="width: 180px; margin-right: 20px">
      <div style="height: 200px; width: 200px; border-radius: 10px" class="shimmer"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 90%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 70%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 60%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 50%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 60%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 70%"></div>
      <div [class]="'shimmer'" style="margin: 20px 0px; height: 30px; border-radius: 30px; width: 50%"></div>
    </div>

    <div style="width: calc(100% - 200px); margin-left: 20px; height: 400px">
      <div [class]="'shimmer'" style="margin-bottom: 10px; height: 10px; border-radius: 5px; width: 90%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 80%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 90%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 60%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px 30px 0px; height: 10px; border-radius: 5px; width: 70%"></div>

      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 90%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 80%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px; height: 10px; border-radius: 5px; width: 90%"></div>
      <div [class]="'shimmer'" style="margin: 10px 0px 30px 0px; height: 10px; border-radius: 5px; width: 60%"></div>

      <div class="d-flex">
        <div [ngStyle]="{ height: '280px', width: '33%', marginRight: '10px', 'border-radius': '10px' }" [class]="'shimmer product-tile'"></div>
        <div [ngStyle]="{ height: '280px', width: '33%', marginRight: '10px', 'border-radius': '10px' }" [class]="'shimmer product-tile'"></div>
        <div [ngStyle]="{ height: '280px', width: '33%', 'border-radius': '10px' }" [class]="'shimmer product-tile'"></div>
      </div>

      <div [class]="'shimmer'" style="margin: 30px 0px; height: 30px; border-radius: 30px; width: 100px"></div>
    </div>
  </div>
</div>

<ng-template #listItem>
  <div [class]="'d-flex align-items-center' + (extraContainerClass ? ' ' + extraContainerClass : '')">
    <div [ngStyle]="{ width: type == 'list-item-large' ? '200px' : '40px', height: type == 'list-item-large' ? '120px' : '40px', 'border-radius': '5px' }" class="shimmer"></div>
    <div style="flex: 1; margin-left: 10px">
      <div style="height: 20px; width: 20%; margin-top: 5px; border-radius: 5px" *ngIf="type == 'list-item-large'" class="shimmer"></div>
      <div style="height: 15px; width: 70%; margin-top: 5px; border-radius: 5px" class="shimmer"></div>
      <div class="d-flex">
        <div style="height: 10px; width: 10%; margin-top: 5px; margin-right: 5px; border-radius: 5px" class="shimmer"></div>
        <div style="height: 10px; width: 10%; margin-top: 5px; margin-right: 5px; border-radius: 5px" class="shimmer"></div>
        <div style="height: 10px; width: 10%; margin-top: 5px; margin-right: 5px; border-radius: 5px" class="shimmer"></div>
        <div style="height: 10px; width: 10%; margin-top: 5px; border-radius: 5px" class="shimmer"></div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div style="width: 30px; height: 30px; border-radius: 15px" class="shimmer"></div>
      <div style="width: 30px; height: 30px; border-radius: 15px; margin-left: 20px" class="shimmer"></div>
      <fa-icon icon="chevron-right" style="color: #333; margin-left: 20px; margin-right: 10px"></fa-icon>
    </div>
  </div>
</ng-template>
