<ng-container *ngIf="userService.storageDetails">
  <div class="storage-details m-b-10">
    <span
      [translate]="'sphere.account.used_storage'"
      [translateParams]="{ percentage: (userService.storageDetails.used | bytesToSize) + ' (' + meterLength.toFixed(2) + '%)', total: (userService.storageDetails.plan_total | bytesToSize) }"
      [ngStyle]="{ color: userService.storageDetails.used > userService.storageDetails.plan_total ? 'red' : '' }"
    ></span>
    <div class="m-x-10">|</div>
    <span>{{ userService.storageDetails.file_count }} {{ "sphere.account.total_files" | translate }}</span>
  </div>
  <div>
    <div class="storage-meter">
      <ng-container *ngIf="types && types.length">
        <div
          class="total-used"
          placement="top"
          [ngbTooltip]="item.filetype + ': ' + (item.bytes | bytesToSize) + ' / ' + item.width.toFixed(2) + '%'"
          *ngFor="let item of types"
          [ngStyle]="{ width: item.width + '%', 'background-color': item.color }"
        ></div>
      </ng-container>
    </div>
  </div>
</ng-container>
