<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" #menu [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">{{ "header.nav.exchange" | translate }}</h1>
        <div style="font-size: 1rem">
          <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'general.upload' | translate" (click)="uploadWare()">
            <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
          </button>
          <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'What Is Exchange?'" (click)="exchangeInfo()">
            <fa-icon icon="video" class="action circle-icon-btn"></fa-icon>
          </button>
        </div>
      </div>

      <ul class="menu-options">
        <li class="menu-option" [class.active]="displayedSection == 'featured'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('featured')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('featured')"
            (click)="appService.mobileMenuShown = false; switchSection('featured')"
          >
            <!-- {{ "exchange.featured" | translate }} -->
            Featured Artist Content
          </a>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'browse'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('browse')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('browse')"
            (click)="appService.mobileMenuShown = false; switchSection('browse')"
          >
            <!-- {{ "exchange.browse" | translate }} -->
            Browse User Content
          </a>

          <div class="menu-suboptions" *ngIf="showBrowseCategories">
            <input
              type="text"
              *ngIf="browseExchange"
              [(ngModel)]="browseExchange.query"
              (keyup)="browseExchange.keyUp($event)"
              class="menu-search text-center"
              [placeholder]="'exchange.search_dots' | translate"
            />
            <ng-container *ngIf="!browseCategories?.length">
              <myp-skeleton-loader number="18" extraContainerClass="m-y-20" type="line" [useRandomWidth]="true" height="10px"></myp-skeleton-loader>
            </ng-container>

            <div class="sub-option d-block" *ngFor="let cat of browseCategories">
              <a
                tabindex="0"
                href="javascript:void(0);"
                (keyup.enter)="browseExchange.switchCategory(cat)"
                (keyup.space)="browseExchange.switchCategory(cat)"
                [class.active]="cat.active"
                (click)="appService.mobileMenuShown = false; browseExchange.switchCategory(cat)"
              >
                <div>
                  <img [src]="'./assets/icons/exchange/' + cat.title + '-category.png'" class="icon" />
                  {{ cat.display_name }}
                </div>
                <span class="list-item-badge">{{ cat.total }}</span>
              </a>
            </div>
          </div>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'myitems'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('myitems')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('myitems')"
            (click)="appService.mobileMenuShown = false; switchSection('myitems')"
          >
            {{ "exchange.my_items" | translate }}
          </a>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'myfavorites'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('myfavorites')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('myfavorites')"
            (click)="appService.mobileMenuShown = false; switchSection('myfavorites')"
          >
            {{ "exchange.favorites" | translate }}
          </a>
        </li>
      </ul>
    </aside>
  </div>
  <section role="contentinfo" class="content animate-in-from-right" #contentContainer (scroll)="contentContainerScrolled($event)" [class.mobile-shown]="!appService.mobileMenuShown">
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Exchange Categories
    </div>
    <div class="row h-100" *ngIf="!user.active_subscription && displayedSection == 'featured'">
      <div class="h-100 w-full d-flex justify-content-center align-items-center text-center">
        <div style="max-width: 600px; margin: 10px">
          <div>
            <h2 class="brandfont-black">{{ "newdash.curated_exchange" | translate }}</h2>
          </div>
          <p class="m-t-20">
            {{ "newdash.exchange_slug" | translate }}
          </p>
          <button class="btn btn-primary" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</button>
        </div>
      </div>
    </div>
    <div class="content-constrained" *ngIf="displayedSection == 'featured'">
      <div *ngIf="user.active_subscription">
        <myp-featured-creators [featuredCreators]="featuredCreators" (creatorClicked)="goToCreator($event)"></myp-featured-creators>
      </div>
    </div>

    <div style="min-height: 600px" class="content-constrained" *ngIf="displayedSection == 'browse'">
      <exc-browse-exchange
        [showCategories]="false"
        #browseExchange
        (searchEnterPressed)="appService.mobileMenuShown = false"
        (categoriesInitted)="browseCategories = $event"
        (exchangeItemTapped)="viewExchangeDetail($event)"
      ></exc-browse-exchange>
    </div>

    <div class="content-constrained" *ngIf="displayedSection == 'myitems'">
      <myp-creator (exchangeAssetClicked)="viewExchangeDetail($event)" [showEditProfile]="true" [creator]="user" [showNav]="false"></myp-creator>
    </div>

    <div class="favorites-page content-constrained" *ngIf="displayedSection == 'myfavorites'">
      <myp-inline-loader [loading]="loading" height="400px" spinnerName="exchangeFavorites"></myp-inline-loader>
      <div *ngIf="!loading && displayedWares.data.length">
        <myp-exchange-list
          (itemTapped)="viewExchangeDetail($event)"
          [displayedCategory]="{ title: 'favorites', display_name: 'MY FAVORITES' }"
          (updateFavorites)="loadFavorites($event)"
          [limit]="limit"
          [page]="page"
          [wares]="displayedWares"
        ></myp-exchange-list>
      </div>
      <div class="text-center text-lg col" style="opacity: 0.5; max-width: 300px; margin: 50px auto" *ngIf="!loading && !displayedWares.data.length">
        {{ "sphere.no_favorites" | translate }}
      </div>
    </div>
  </section>
</main>
