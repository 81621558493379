import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-create-folder",
  templateUrl: "create-folder.component.html",
})
export class CreateFolderComponent extends BaseComponent implements OnInit {
  public name: string;
  @Input() folder: any;
  @Input() parentFolder: any;
  @Input() workspaceID: number;
  @Output() folderCreated: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.folder) this.name = this.folder.filename + "";
  }

  submit() {
    this.loading = true;
    if (this.folder) {
      this.workspaceService.updateFolder(this.folder.id, this.name).subscribe((result) => {
        this.workspaceService.getWorkspaceFiles(this.workspaceID).subscribe((files) => {
          this.loading = false;
          this.folderCreated.next(files);
          this.activeModal.close();
        });
      });
    } else {
      if (this.name.indexOf("/") > -1) {
        this.loading = false;
        alert("Folder names with slashes are not allowed.");
      } else if (this.name.indexOf(".") == 0) {
        this.loading = false;
        alert("A folder cannot begin with a period.");
      } else {
        this.workspaceService.createEmptyFolder(this.name, this.workspaceID, this.parentFolder?.id).subscribe((result) => {
          this.workspaceService.getWorkspaceFiles(this.workspaceID).subscribe((files) => {
            this.loading = false;
            this.folderCreated.next(files);
            this.activeModal.close();
          });
        });
      }
    }
  }
}
