import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, Subject, catchError, concatMap, firstValueFrom, from, of, switchMap, take, throwError } from "rxjs";
import { UserService } from "@mypxplat/xplat/core";

@Injectable()
export class FirebaseService {
  public fbUser: any;
  private _logginFBUserIn: boolean = false;
  public firbeaseUserLoggedIn$: Subject<boolean> = new Subject();

  constructor(
    public auth: AngularFireAuth,
    public userService: UserService
  ) {
    this.auth.onAuthStateChanged((credential) => {
      this.fbUser = credential;
    });
  }

  async handleFirestorePromise<T>(request: () => Promise<T>): Promise<T> {
    try {
      return await request();
    } catch (error) {
      const normalizedError = error instanceof Error ? error : new Error(String(error)); // Ensure error is an instance of Error
      if (normalizedError["code"] === "permission-denied") {
        const user = await firstValueFrom(this.auth.user.pipe(take(1))); // Replace toPromise with firstValueFrom
        if (!user) {
          await this.loginFirebaseUser();
          return await request(); // Retry the request after login
        }
      }
      throw normalizedError; // Rethrow the normalized error
    }
  }

  handleFirestoreObservable<T>(request: () => Observable<T>): Observable<T> {
    return request().pipe(
      catchError((error: any) => {
        if (error.code === "permission-denied") {
          return from(firstValueFrom(this.auth.user.pipe(take(1)))).pipe(
            switchMap(async (user) => {
              if (!user) {
                await this.loginFirebaseUser();
                return request();
              }
            }),
            catchError(() => throwError(() => new Error("Permission denied")))
          );
        }
        return throwError(() => new Error(error || "Unknown error")); // Wrap all errors properly
      }),
      switchMap((result) => (result instanceof Observable ? result : of(result)))
    );
  }

  private _currentPromise: Promise<any> | null = null;
  public loginFirebaseUser() {
    if (this._currentPromise) {
      return this._currentPromise;
    }
    this._currentPromise = new Promise((resolve, reject) => {
      if (!this._logginFBUserIn) {
        this._logginFBUserIn = true;
        if (this.fbUser && this.fbUser.uid == this.userService.user.id) {
          this._logginFBUserIn = false;
          this.firbeaseUserLoggedIn$.next(true);
          resolve(true);
        } else {
          this.auth.user.pipe(take(1)).subscribe({
            next: (credential) => {
              this.fbUser = credential;
              if (!this.fbUser || this.fbUser.uid != this.userService.user.id) {
                if (!this.userService.user || !this.userService.user.firebase_token) {
                  this.userService.getUserDetails(false).subscribe((userDetails) => {
                    this.auth
                      .signInWithCustomToken(userDetails.firebase_token)
                      .then((result) => {
                        this.firbeaseUserLoggedIn$.next(true);
                        this._logginFBUserIn = false;
                        resolve(true);
                      })
                      .catch((error) => {
                        this._logginFBUserIn = false;
                        this._currentPromise = null;
                        reject(new Error(error?.message || "An error occurred while logging in."));
                      });
                  });
                } else {
                  this.auth
                    .signInWithCustomToken(this.userService.user.firebase_token)
                    .then((result) => {
                      this.firbeaseUserLoggedIn$.next(true);
                      this._logginFBUserIn = false;
                      resolve(true);
                    })
                    .catch((error) => {
                      this._logginFBUserIn = false;
                      reject(new Error(error?.message || "An error occurred while logging in."));
                    });
                }
              } else {
                this.firbeaseUserLoggedIn$.next(true);
                this._logginFBUserIn = false;
                resolve(true);
              }
            },
            error: (err) => {
              reject(new Error(err?.message || "An error occurred while logging in."));
            },
          });
        }
      } else {
        reject(new Error("User is already logging in."));
      }
    });

    this._currentPromise.then(
      () => {
        this._currentPromise = null;
      },
      () => {
        this._currentPromise = null;
      }
    );

    return this._currentPromise;
  }
}
