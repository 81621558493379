<div class="autocomplete-dropdown">
  <div class="form-control">
    <div
      *ngIf="searching"
      class="editable-input"
      [tabindex]="tabindex"
      (click)="initAutocomplete()"
      (focus)="inputFocusChange('focus', $event)"
      (blur)="inputFocusChange('blur', $event)"
      (keydown.enter)="$event.preventDefault()"
      #input
      (keyup)="filter($event)"
      contenteditable="true"
      role="combobox"
      aria-haspopup="listbox"
      [id]="'combobox-' + idName"
      [attr.aria-labelledby]="'selected-option-' + idName"
      [attr.aria-expanded]="searching"
      [attr.aria-controls]="'autocomplete-options-' + idName"
      [attr.aria-activedescendant]="preselectedItem?.value ? preselectedItem.value : (preselectedItem ?? '')"
    >
      <span [id]="'selected-text-' + idName">{{ selection?.value ? selection.value : selection }}</span>
    </div>
    <ng-container *ngIf="!searching">
      <div
        class="selected"
        role="combobox"
        aria-haspopup="listbox"
        [attr.aria-labelledby]="'selected-option-' + idName"
        [attr.aria-expanded]="searching"
        [attr.aria-controls]="'autocomplete-options-' + idName"
        *ngIf="selection"
        (focus)="initAutocomplete()"
        [tabindex]="tabindex"
      >
        {{ selection?.value ? selection.value : selection }}
      </div>
      <div class="hint" *ngIf="!selection" (focus)="initAutocomplete()" [tabindex]="tabindex">{{ hint }}</div>
    </ng-container>
    <fa-icon icon="chevron-down" (click)="initAutocomplete()"></fa-icon>
  </div>
  <!-- <div class="autocomplete-mask" [class.d-none]="!searching"></div> -->
  <ul [id]="'autocomplete-options-' + idName" class="autocomplete-container" [attr.aria-labelledby]="'selected-text-' + idName" role="listbox" [class.d-none]="!searching">
    <li *ngIf="!filteredItems || !filteredItems.length" role="option" class="muted">No options match your query.</li>
    <li *ngFor="let item of filteredItems; let i = index" [id]="item.value ? item.value : item" role="option" [aria-selected]="preselectedItem == item" (click)="select(item, $event)">
      <div class="select-item" [id]="'item' + i" [class.preselected]="preselectedItem == item" (mouseenter)="preselectedItem = item">
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ item.value ? item.value : item }}</span>
          <span class="metadata" *ngIf="item.metadata && item.metadata.type">{{ item.metadata.type.toUpperCase() }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>
