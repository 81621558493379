import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of as observableOf, throwError, BehaviorSubject, Subject, of } from "rxjs";
import { map, take } from "rxjs/operators";
import * as moment from "moment";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  public uploadingFile$: BehaviorSubject<any> = new BehaviorSubject(false);
  public workspaces$: BehaviorSubject<any> = new BehaviorSubject(false);
  public collabs$: BehaviorSubject<any> = new BehaviorSubject(false);
  public refreshWorkspace$: BehaviorSubject<any> = new BehaviorSubject(false);

  public fileUploadProgressUpdate$: BehaviorSubject<any> = new BehaviorSubject(false);
  public fileUploadSessionCompleted$: BehaviorSubject<any> = new BehaviorSubject(false);

  public workspaces: Array<any>;
  public workspaceMap: any = {};
  public collabs: Array<any>;
  public collabsMap: any = {};

  public allMap: any = {};

  public fileMap: any = {};
  public folderMap: any = {};
  public foldersByID: any = {};
  public syncedFolderMap: any = {};
  public lastViewedFolder: string;
  public workspaceFilesMap: any = {};

  public uploadingFilesMap: any = {};
  public uploadingFilesSessionMap: any = {};

  private _cachedCollaborationInvites: Array<any>;
  set cachedCollaborationInvites(invites) {
    this._cachedCollaborationInvites = invites;
  }

  get cachedCollaborationInvites() {
    return this._cachedCollaborationInvites;
  }

  public activeCollaborationInvites: Array<any> = [];

  private _workspacecollabs: any;

  public availableFileIcons: any = {
    aac: true,
    aaf: true,
    aif: true,
    aiff: true,
    application: true,
    audio: true,
    audioloop: true,
    aupreset: true,
    avi: true,
    capture: true,
    flac: true,
    fxb: true,
    fxp: true,
    install: true,
    ioconfig: true,
    keyswitch: true,
    kristal: true,
    license: true,
    m4a: true,
    m4v: true,
    macro: true,
    macropage: true,
    mid: true,
    midi: true,
    miditrack: true,
    mov: true,
    mp3: true,
    mp4: true,
    multiinstrument: true,
    multipreset: true,
    multitrack: true,
    musicloop: true,
    ogg: true,
    opentl: true,
    pattern: true,
    pitchlist: true,
    preset: true,
    project: true,
    quantize: true,
    rex: true,
    sfpack: true,
    sfpreset: true,
    shortcuts: true,
    show: true,
    song: true,
    soundpack: true,
    soundpreset: true,
    soundset: true,
    txt: true,
    wav: true,
    zip: true,
  };

  batchOperationSelection: any = {};

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private _win: WindowService,
    private _log: LogService,
    private _helperService: HelperService,
    private _userService: UserService
  ) {
    this._helperService.userID = this._userService.user ? this._userService.user.id : undefined;
  }

  get workspacecollabs() {
    if (!this._workspacecollabs) this._workspacecollabs = this.storage.getItem(StorageKeys.WORKSPACESCOLLABS);
    return this._workspacecollabs;
  }
  set workspacecollabs(data) {
    if (data) {
      this._workspacecollabs = data;
      this.storage.setItem(StorageKeys.WORKSPACESCOLLABS, data);
    } else {
      this._workspacecollabs = false;
      this.storage.removeItem(StorageKeys.WORKSPACESCOLLABS);
    }
  }

  public audioFiles: any = {
    wav: true,
    WAV: true,
    mp3: true,
    MP3: true,
    aiff: true,
    AIFF: true,
    aif: true,
    AIF: true,
    m4a: true,
    M4A: true,
    flac: true,
    FLAC: true,
    aac: true,
    AAC: true,
    ogg: true,
    OGG: true,
    caf: true,
    CAF: true,
  };

  public iosAudioFiles: any = {
    wav: true,
    WAV: true,
    mp3: true,
    MP3: true,
    aiff: true,
    AIFF: true,
    aif: true,
    AIF: true,
    m4a: true,
    M4A: true,
    flac: true,
    FLAC: true,
    aac: true,
    AAC: true,
    ogg: true,
    OGG: true,
    caf: true,
    CAF: true,
  };

  public androidAudioFiles: any = {
    wav: true,
    WAV: true,
    mp3: true,
    MP3: true,
    aiff: true,
    AIFF: true,
    aif: true,
    AIF: true,
    m4a: true,
    M4A: true,
    flac: true,
    FLAC: true,
    aac: true,
    AAC: true,
    ogg: true,
    OGG: true,
  };

  public imageFiles: any = {
    jpg: true,
    JPG: true,
    gif: true,
    GIF: true,
    png: true,
    PNG: true,
    jpeg: true,
    JPEG: true,
    webp: true,
    WEBP: true,
    heic: true,
    HEIC: true,
  };

  public videoFiles: any = {
    m4v: true,
    M4V: true,
    mp4: true,
    MP4: true,
    mov: true,
    MOV: true,
  };

  buildFileUrl(user_id, workspace_id, file) {
    let url;
    if (file.storage_location == "aws") {
      url = encodeURI(environment.awsContentUrl + user_id + "/workspace-uploads/" + workspace_id + "/" + (file.storage_id ? file.storage_id : file.filename));
    } else {
      url = encodeURI(environment.wasabiContentUrl + user_id + "/workspace-uploads/" + workspace_id + "/" + (file.storage_id ? file.storage_id : file.filename));
    }
    return url;
  }

  addEditWorkspace(args) {
    let url = environment.apiUrl + "workspace/";
    if (environment.features.use_paeapi) url = environment.paeApiUrl + "workspace/";
    return this.http.post(url, JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        if (args.id) this.deleteSavedWorkspace(args.id);
        this.getWorkspaces(true).subscribe();
        return result;
      })
    );
  }

  updateWorkspace(args) {
    let url = environment.apiUrl + "workspace/update";
    if (environment.features.use_paeapi) url = environment.paeApiUrl + "workspace/";
    return this.http.post(url + "workspace/update", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        this.deleteSavedWorkspace(args.id);
        this.getWorkspaces(true).subscribe();
        return result;
      })
    );
  }

  private deleteSavedWorkspace(id) {
    const workspaceDetailsMap = this.storage.getItem(StorageKeys.WORKSPACEDETAILSMAP) || {};
    if (workspaceDetailsMap[id]) delete workspaceDetailsMap[id];
    this.storage.setItem(StorageKeys.WORKSPACEDETAILSMAP, workspaceDetailsMap);
  }

  getWorkspaces(forceRefresh?) {
    const savedData = this.workspacecollabs;
    if (savedData && !forceRefresh) {
      const processedData = this._handleWorkspaceCollabData(savedData);
      return observableOf(processedData);
    } else {
      if (forceRefresh) {
        this.storage.removeItem(StorageKeys.WORKSPACEDETAILSMAP);
      }

      let url = environment.apiUrl + "workspace/list/";
      if (environment.features.use_paeapi) url = environment.paeApiUrl + "workspace/list/";
      return this.http.get(url, this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          this.workspacecollabs = result;
          this._handleWorkspaceCollabData(result);
          return result;
        })
      );
    }
  }

  getWorkspace(id, fresh?) {
    const workspaceDetailsMap = this.storage.getItem(StorageKeys.WORKSPACEDETAILSMAP) || {};
    if (!fresh && workspaceDetailsMap && workspaceDetailsMap[id]) {
      this.workspaceFilesMap[id] = workspaceDetailsMap[id].shared_files;
      return observableOf(workspaceDetailsMap[id]);
    } else {
      let url = environment.apiUrl + "workspace/detail/" + id;
      if (environment.features.use_paeapi) url = environment.paeApiUrl + "workspace/detail?id=" + id;
      return this.http.get(url, this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          workspaceDetailsMap[result.id] = result;
          this.workspaceFilesMap[result.id] = result.shared_files;
          this.storage.setItem(StorageKeys.WORKSPACEDETAILSMAP, workspaceDetailsMap);
          return result;
        })
      );
    }
  }

  getFileDetails(id?, hash?, isPublic?) {
    let url = environment.paeApiUrl + "content/file/" + id;
    if (hash) url = environment.paeApiUrl + "content/file/?hash=" + hash;
    if (hash && isPublic) url = environment.paeApiUrl + "content/public-file/" + hash;
    return this.http.get(url, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getCollaborationInvites(fresh?) {
    if (!fresh && this.cachedCollaborationInvites) {
      this.activeCollaborationInvites = this.cachedCollaborationInvites;
      return of(this.cachedCollaborationInvites);
    } else {
      return this.http.get(environment.paeApiUrl + "workspace/invites/", this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          if (result && result.status == "success" && result.invites && result.invites.length) {
            this.cachedCollaborationInvites = result.invites;
            this.activeCollaborationInvites = this.cachedCollaborationInvites;
            return result.invites;
          } else {
            this.cachedCollaborationInvites = undefined;
            this.activeCollaborationInvites = [];
            return false;
          }
        })
      );
    }
  }

  updateInvitation(args) {
    return this.http.post(environment.apiUrl + "workspace/update_invite", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  sendFileMessage(args, method = "send_direct_message") {
    return this.http.post(environment.apiUrl + "workspace/" + method, JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  findCollaborator(email, workspace_id) {
    return this.http.get(environment.paeApiUrl + "workspace/search-user/" + encodeURIComponent(email) + "/" + workspace_id, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  resendWorkspaceInvite(email, workspace_id) {
    return this.http.get(environment.apiUrl + "workspace/resend_invite/" + encodeURIComponent(email) + "/" + workspace_id, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  updateFile(data, ids?) {
    let url = "workspace/update_file/";
    if (ids) url = "workspace/update_files/";
    let postData = data;
    if (ids) {
      postData = {
        data: data,
        ids: ids,
      };
    }
    return this.http.post(environment.apiUrl + url, JSON.stringify(postData), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  createEmptyFolder(name, workspaceID, parentID?) {
    let args: any = {
      name: name,
      workspace_id: workspaceID,
    };
    if (parentID) args.parent_folder_id = parentID;
    return this.http.post(environment.apiUrl + "workspace/empty_folder/", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  updateFolder(id, title) {
    return this.http.post(environment.apiUrl + "workspace/update_folder/", JSON.stringify({ id: id, title: title }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  deleteFolder(id) {
    return this.http.get(environment.apiUrl + "workspace/delete_folder/" + id, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getWorkspaceFiles(workspace_id, fresh = true) {
    if (!fresh && this.workspaceFilesMap[workspace_id]) {
      return observableOf(this.workspaceFilesMap[workspace_id]);
    } else {
      return this.http.get(environment.paeApiUrl + "content/file?workspaceId=" + workspace_id, this._helperService.getHttpOptions()).pipe(
        // return this.http.get(environment.apiUrl + "workspace/workspace_files/" + workspace_id, this._helperService.getHttpOptions()).pipe(
        map((result) => {
          const workspaceDetailsMap = this.storage.getItem(StorageKeys.WORKSPACEDETAILSMAP) || {};
          if (workspaceDetailsMap[workspace_id]) {
            workspaceDetailsMap[workspace_id].shared_files = result;
            this.storage.setItem(StorageKeys.WORKSPACEDETAILSMAP, workspaceDetailsMap);
          }
          this.workspaceFilesMap[workspace_id] = result;
          return result;
        })
      );
    }
  }

  getWorkspaceFolders(workspace_id) {
    return this.http.get(environment.paeApiUrl + "content/folders?workspaceId=" + workspace_id, this._helperService.getHttpOptions());
  }

  getFilesByFolderId(folderId, workspaceId) {
    return this.http.get(environment.paeApiUrl + `content/file?folderId=${folderId ? folderId : "Home"}&workspaceId=${workspaceId}`, this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  checkFileUploadStatus(file_id) {
    return this.http.get(environment.apiUrl + "workspace/upload_status/" + file_id, this._helperService.getHttpOptions()).pipe();
  }

  updateUploadProgress(id, progress) {
    return this.http.put(environment.paeApiUrl + `content/file/${id}`, JSON.stringify({ progress }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  notifyWorkspaceFileUploaded(file_id) {
    return this.http.get(environment.apiUrl + "workspace/notify_workspace_file_uploaded/" + file_id, this._helperService.getHttpOptions()).pipe();
  }

  updateWorkspacePreferences(args) {
    return this.http.post(environment.apiUrl + "workspace/update_preferences", JSON.stringify(args), this._helperService.getHttpOptions()).pipe();
  }

  getWorkspacePreferences() {
    return this.http.get(environment.apiUrl + "workspace/preferences", this._helperService.getHttpOptions()).pipe();
  }

  deleteFile(fileId) {
    return this.http.delete(environment.paeApiUrl + `content/file/${fileId}`, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this._userService.getStorageDetails().subscribe();
        return result;
      })
    );
  }

  batchDeleteFilesLegacy(data) {
    return this.http.post(environment.cloudApiUrl + "deleteAssetArray", JSON.stringify({ files: data, test: data.length }), this._helperService.getHttpOptions());
  }

  batchDeleteFiles(files, workspaceId) {
    return this.http.post(environment.paeApiUrl + "content/batch-delete", JSON.stringify({ files: files, workspaceId: workspaceId }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  createPublicLink(args) {
    return this.http.post(environment.apiUrl + "workspace/public_link", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  updatePublicLink(args) {
    return this.http.post(environment.apiUrl + "workspace/update_public_link", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  deletePublicLink(id) {
    return this.http.get(environment.apiUrl + "workspace/delete_public_link/" + id, this._helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  deleteWorkspace(id) {
    return this.http.post(environment.cloudApiUrl + "deleteWorkspace", JSON.stringify({ id, user_id: this._userService.user.id }), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this._userService.getStorageDetails().subscribe();
        return result;
      })
    );
  }

  leaveWorkspace(id) {
    return this.http.get(environment.apiUrl + "workspace/leave/" + id, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  private _handleWorkspaceCollabData(data) {
    if (data.myworkspaces && data.myworkspaces.length) {
      let workspaces = data.myworkspaces;
      // do workspace processing here.
      workspaces.forEach((item) => {
        this.workspaceMap[item.id] = item;
        this.allMap[item.id] = item;
      });
      this.workspaces = workspaces;
      this.workspaces$.next(workspaces);
    } else {
      this.workspaces$.next([]);
    }

    if (data.mycollaborations && data.mycollaborations.length) {
      let collabs = data.mycollaborations;
      // do collabs processing here.
      collabs.forEach((item) => {
        this.collabsMap[item.id] = item;
        this.allMap[item.id] = item;
      });
      this.collabs = collabs;
      this.collabs$.next(collabs);
    } else {
      this.collabs = [];
      this.collabs$.next([]);
    }
    return {
      workspaces: data.myworkspaces,
      collabs: data.mycollaborations,
    };
  }

  clearCache() {
    this.workspaces$.next(false);
    this.collabs$.next(false);
    this._workspacecollabs = false;
    this.workspaces = undefined;
    this.workspaceMap = {};
    this.collabs = undefined;
    this.collabsMap = {};
    this.allMap = {};
    this.storage.removeItem(StorageKeys.WORKSPACESCOLLABS);
  }
}
