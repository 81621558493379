<fieldset
  class="view-chooser"
  [attr.aria-labelledby]="ariaLabelledBy ? ariaLabelledBy : null"
  [class.white]="white"
  [class.force-white]="forceWhite"
  [class.no-wrap]="nowrap"
  [attr.role]="isUsedAsTab ? 'tablist' : 'radiogroup'"
>
  <label
    *ngFor="let option of options; let i = index"
    [for]="option.name"
    class="option"
    [class.selected]="option.selected"
    (keyup.enter)="selectOption(option, $event)"
    (keydown.space)="selectOption(option, $event)"
    (keyup.ArrowUp)="selectPrevOption(i)"
    (keyup.ArrowLeft)="selectPrevOption(i)"
    (keyup.ArrowDown)="selectNextOption(i)"
    (keyup.ArrowRight)="selectNextOption(i)"
    (click)="clickSelectOption(option, $event)"
    [tabindex]="isUsedAsTab && option.selected ? 0 : -1"
    [role]="isUsedAsTab ? 'tab' : null"
    [id]="'tab-' + option.id"
  >
    <input type="radio" class="sr-only" [name]="uniqueID" [tabindex]="!isUsedAsTab && option.selected ? 0 : -1" [id]="option.name" [attr.aria-checked]="option.selected" [checked]="option.selected" />
    {{ option.translationKey ? (option.translationKey | translate) : option.name }}
  </label>
</fieldset>
