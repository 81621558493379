import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, ExchangeService, HelperService, ProductService, AdminService, WindowService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import Quill from "quill";
@Component({
  selector: "myp-confirm-beta-announcement-email",
  templateUrl: "confirm-beta-announcement-email.component.html",
})
export class ConfirmBetaAnnouncementEmail extends BaseComponent implements OnInit {
  @Input() to: Array<string>;
  @Input() subject: any;
  @Input() messageHtml: any;
  @Input() from: any;
  @Input() directLink: string;
  @Input() program: any;
  public loading: boolean;
  public preview: boolean = false;
  public quill: Quill;
  public userIds: any = [];
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService,
    public productService: ProductService,
    public adminService: AdminService,
    public win: WindowService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.win.setTimeout(() => {
      this.initQuill();
    }, 300);
    this.adminService.getBetaMembers(this.program.id).subscribe((result: any) => {
      this.userIds = result.map((user) => user.user_id);
    });
  }

  getMessageHtml() {
    let html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${this.subject}</title>
          <style>
              .email-container {
                  max-width: 600px;
                  margin: 20px auto;
                  padding: 20px;
                  border-radius: 8px;
              }
              .content { padding: 20px 0; line-height: 1.6; }
              .author-section {
                  display: flex;
                  align-items: center;
                  padding: 20px 0;
                  margin-bottom: 20px;
              }
              .author-photo {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 15px;
              }
              .author-photo img {
                  width: 100%;
                  height: auto;
                  display: block;
              }
              .author-name { font-weight: bold; font-size: 16px; }
              .button {
                  background-color: #007bff;
                  color: #ffffff;
                  padding: 10px 12px;
                  border-radius: 30px;
                  text-decoration: none;
                  font-size: 16px;
              }
              .button:hover { background-color: #0056b3; color: white }
          </style>
      </head>
      <body>
          <div class="email-container">
              <div><strong>${this.subject}</strong></div>
              <div class="content"><p>${this.quill.root.innerHTML}</p></div>
              <div class="author-section">
                  <div class="author-photo"><img src="${this.from.photo}" alt="Author Photo"></div>
                  <div class="author-name">${this.from.name}</div>
              </div>
              <div class="button-container"><a href="${this.directLink}" class="button">View More Details</a></div>
          </div>
      </body>
      </html>
    `;
    return html;
  }

  initQuill() {
    const toolbarOptions = [["bold", "italic", "underline", "strike"], ["blockquote", "code-block"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]];
    this.quill = new Quill("#beta-email_quill-editor", {
      modules: { toolbar: toolbarOptions },
      placeholder: "What would you like to say?",
      theme: "snow",
    });

    const delta = this.quill.clipboard.convert({ html: this.messageHtml });
    this.quill.setContents(delta);

    // move the toolbar into a container below the editor.
    const toolbar = (<any>this.quill.getModule("toolbar")).container;
    const customToolbarContainer = document.getElementById("beta-email_quill-toolbar");
    customToolbarContainer.appendChild(toolbar);
  }

  togglePreview(event) {
    this.preview = event.id == "preview" ? true : false;
    if (!this.preview) {
      this.win.setTimeout(() => {
        this.initQuill();
      }, 100);
    } else {
      this.messageHtml = this.quill.root.innerHTML;
    }
  }

  submit() {
    if (this.subject && (this.to?.length || this.userIds?.length) && this.messageHtml) {
      if (confirm("Are you sure you want to send this email? It will be sent to " + (this.to?.length || this.userIds?.length) + " recipients.")) {
        let html = this.getMessageHtml();
        let args: any = {
          subject: this.subject,
          messageHtml: html,
          from: "no-replies@presonus.com",
          bcc: true,
          author: {
            name: this.userService.user.firstName + " " + this.userService.user.lastName,
            id: this.userService.user.id,
          },
        };
        if (this.to) args.to = this.to;
        if (this.userIds) args.userIds = this.userIds;
        // args.userIds = [804975, 880848]; // for testing
        if (this.userService.user.photoURL) args.author.photoURL = this.userService.user.photoURL;
        this.adminService.sendEmail(args).subscribe((result) => {
          this.activeModal.close();
        });
      }
    } else {
      alert("Please fill out all the required fields.");
    }
  }
}
