<div class="row">
  <div class="col-sm-12 plan-container">
    <!-- <h4 class="section-header">STORAGE</h4> -->
    <div *ngIf="!userService.userSubscription && !loading" class="d-flex flex-wrap">
      <div class="text-left m-l-10" style="width: 100%">
        <h4>{{ "sphere.account.plan" | translate }}</h4>
        {{ "sphere.account.current_free_account" | translate }}
        <br />
        <br />
        <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
        <br />
        <br />
        <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" style="margin: 10px; max-width: 250px" />
        <br />
        <span class="brandfont-black" style="font-size: 30px">
          {{ "sphere.subscription_details.only_price" | translate: { monthly: userService.spherePrices.monthly, annual: userService.spherePrices.annual } }}
        </span>
        <br />
        <br />
        <button class="btn btn-primary" data-cy="upgrade-subscription-button" (click)="upgrade()">{{ "sphere.subscription_details.upgrade_to_sphere" | translate }}</button>
      </div>
    </div>
    <div class="p-20 text-center" *ngIf="userService.userSubscription">
      <h4>{{ "sphere.account.membership_title" | translate }}</h4>
      <h6>{{ "account.storage_included" | translate: { gigabytes: userService.userSubscription.base.storage_gigabytes_included } }}</h6>
      <h6
        *ngIf="
          userService.userSubscription.base.bluesnap_subscription &&
          userService.userSubscription.base.bluesnap_subscription.coupon &&
          userService.userSubscription.base.bluesnap_subscription.coupon == 'SPHERE4MFREE'
        "
        class="c-green"
      >
        {{ "account.coupon_applied_4_months" | translate }}
      </h6>
      <h6
        *ngIf="
          userService.userSubscription.base.bluesnap_subscription &&
          userService.userSubscription.base.bluesnap_subscription.coupon &&
          userService.userSubscription.base.bluesnap_subscription.coupon == 'SPHERE1YFREE'
        "
        class="c-green"
      >
        {{ "account.coupon_applied_1_year" | translate }}
      </h6>
      <h6
        *ngIf="
          userService.userSubscription.base.bluesnap_subscription &&
          userService.userSubscription.base.bluesnap_subscription.coupon &&
          userService.userSubscription.base.bluesnap_subscription.coupon == 'SPHEREBF2020'
        "
        class="c-green"
      >
        {{ "account.coupon_applied_10_percent" | translate }}
      </h6>
      <div class="brandfont-bold" *ngIf="userService.userSubscription.base.cancel_reason != 'terminate bluesnap' && !userService.userSubscription.base.bluesnap_subscription">
        {{ "account.free_subscription_ends" | translate: { date: userService.userSubscription.base.end_date | date } }}
        <br />
        <br />
        <a class="small-btn blue" (click)="upgrade()">Setup Auto-Pay</a>
      </div>
      <h5 *ngIf="userService.userSubscription.base.bluesnap_subscription && userService.userSubscription.base.bluesnap_subscription['catalog-recurring-charge']">
        {{
          userService.userSubscription.base.bluesnap_subscription["catalog-recurring-charge"].amount +
            " " +
            userService.userSubscription.base.bluesnap_subscription["catalog-recurring-charge"].currency
        }}
        {{ userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "MONTHLY" ? ("sphere.account.per_month" | translate) : ("sphere.account.per_year" | translate) }}
      </h5>
    </div>
    <div class="m-x-10">
      <div class="m-t-50" *ngIf="userService.storageDetails && userService.userSubscription">
        <myp-storage-meter></myp-storage-meter>
      </div>

      <myp-inline-loader [loading]="loading" height="400px" spinnerName="loadingPlan"></myp-inline-loader>
      <div class="m-t-100 text-center" *ngIf="noSubscriptionFound">
        <h4>{{ "sphere.account.sub_info_not_found" | translate }}</h4>
      </div>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.product_data.activations && subscriptionProduct.product_data.activations.length">
        <h4 class="section-header m-t-50">{{ "sphere.account.sphere_activations" | translate }}</h4>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.product_data.activations">
          <span>{{ act.comment }}</span>
          <div>
            <span class="d-inline-block m-r-10">{{ act.aquired | date }}</span>
            <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act)">
              <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
              <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.product_data.mixerActivations && subscriptionProduct.product_data.mixerActivations.length">
        <h4 class="section-header m-t-50">{{ "sphere.account.mixer_activations" | translate }}</h4>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.product_data.mixerActivations">
          <span>{{ act.comment }}</span>
          <span>{{ act.aquired | date }}</span>
          <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act, true)">
            <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
            <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="userService.userSubscription && userService.userSubscription.base.xchange_subscription">
        <h4 class="section-header m-t-50">{{ "sphere.account.subscription_details" | translate }}</h4>
        <div class="m-y-20">
          <div class="text-center">
            <h5 class="text-center">{{ "sphere.account.membership_title" | translate }}</h5>
            <span class="brandfont-bold">{{ "sphere.account.sub_managed_third_party" | translate }}</span>
          </div>
          <div class="m-t-30 m-b-50 plan-detail-section">
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.managed_by" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.userSubscription.base.xchange_subscription.reseller_name }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.start_date" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.userSubscription.base.xchange_subscription.start_date.split("+")[0] | date }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.next_charge_date" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.userSubscription.base.xchange_subscription.next_bill_date.split("+")[0] | date }}</div>
            </div>
          </div>
        </div>

        <div class="text-center">
          {{ "sphere.account.to_make_changes" | translate }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          userService.userSubscription &&
          userService.userSubscription.base &&
          userService.userSubscription.base.cancel_reason == 'terminate bluesnap' &&
          !userService.userSubscription.base.schedule_subscription_id
        "
      >
        <div class="text-center m-t-50">
          <fa-icon icon="lock" style="font-size: 50px"></fa-icon>
          <h3 class="brandfont-bold">{{ "account.reenter_payment_info" | translate }}</h3>
          <ng-container
            *ngIf="userService.userSubscription.base.bluesnap_subscription['charge-frequency'] && userService.userSubscription.base.bluesnap_subscription['charge-frequency'] == 'ANNUALLY'"
          >
            {{
              "account.upgrade_payment_processing_announcement"
                | translate
                  : {
                      studioOnePlusBrandName: env.studioOnePlusBrandName,
                      subscriptionEndDate: userService.userSubscription.base.end_date | date,
                    }
            }}
          </ng-container>
          <ng-container
            *ngIf="!userService.userSubscription.base.bluesnap_subscription['charge-frequency'] || userService.userSubscription.base.bluesnap_subscription['charge-frequency'] == 'MONTHLY'"
          >
            {{
              "account.upgrade_payment_processing_announcement_monthly"
                | translate
                  : {
                      studioOnePlusBrandName: env.studioOnePlusBrandName,
                    }
            }}
          </ng-container>
          <br />
          <br />
          <button class="btn btn-primary" (click)="showUpdateCard()">
            {{ "account.enter_payment_information" | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="userService.userSubscription && userService.userSubscription.base.bluesnap_subscription && userService.userSubscription.base.bluesnap_subscription.status">
        <h4 class="section-header m-t-50">{{ "sphere.account.subscription_details" | translate }}</h4>
        <div class="m-t-20">
          <div class="text-center">
            <h5 class="text-center gothic">{{ "sphere.account.membership_title" | translate }}</h5>
            <span *ngIf="!userService.userSubscription.base.schedule_subscription_id" class="{{ 'pill ' + statusMap[userService.userSubscription.base.bluesnap_subscription.status].color }}">
              {{ "sphere.account.status" | translate }}: {{ statusMap[userService.userSubscription.base.bluesnap_subscription.status].title }}
            </span>
            <span *ngIf="userService.userSubscription.base.schedule_subscription_id" class="pill green">{{ "sphere.account.status" | translate }}: Active</span>
            <div *ngIf="statusMap[userService.userSubscription.base.bluesnap_subscription.status].title == 'Canceled' && !env.features.bluesnap_to_stripe_migration" class="warning-container m-t-10">
              <span [translate]="'sphere.account.sub_ends_on_base'" [translateParams]="{ date: (userService.userSubscription.base.end_date | date) }"></span>
              <br />
              <button class="small-btn blue" (click)="reactivate()">{{ "sphere.account.reactivate_membership" | translate }}</button>
            </div>
            <div *ngIf="userService.userSubscription.base.status === 'missed payment'" class="warning-container m-t-10">
              {{ "onboarding.missed_payment_warning" | translate }}
              <br />
              <button class="small-btn" (click)="showUpdateCard()">{{ "sphere.account.add_card" | translate }}</button>
              <div class="m-t-20" *ngIf="userService.userSubscription.base.bluesnap_subscription['last-charge-result']">
                {{ "onboarding.info_from_provider" | translate }}
                <br />
                {{ "onboarding.result_code" | translate }}: {{ userService.userSubscription.base.bluesnap_subscription["last-charge-result"]["result-code"] }}
                <br />
                {{ "onboarding.processor_error_msg" | translate }}: {{ userService.userSubscription.base.bluesnap_subscription["last-charge-result"]["processor-error-message"] }}
              </div>
              <!-- <span [translate]="'sphere.account.sub_ends_on_base'" [translateParams]="{ date: (userService.userSubscription.base.end_date | date) }"></span><br />
              <button class="small-btn green" (click)="reactivate()">{{ "sphere.account.reactivate_membership" | translate }}</button> -->
            </div>
            <div
              *ngIf="
                env.features.bluesnap_to_stripe_migration &&
                isPaypalPayment &&
                !userService.userSubscription.base.schedule_subscription_id &&
                userService.userSubscription.base.status !== 'missed payment'
              "
              class="warning-container m-t-10"
            >
              {{ "stripe.migration_notice" | translate }}
              <br />
              <button class="small-btn" (click)="showUpdateCard()">{{ "sphere.account.add_card" | translate }}</button>
            </div>
            <div
              *ngIf="env.features.bluesnap_to_stripe_migration && userService.userSubscription.base.schedule_subscription_id && !userService.userSubscription.base.schedule_subscription_canceled_at"
              class="onboard-helptext m-t-10"
            >
              {{
                "stripe.scheduled_notice"
                  | translate: { next_charge_date: userService.userSubscription.base.bluesnap_subscription["next-charge-date"] || userService.subDetails.subscription.end_date | date: "MMM Do, YYYY" }
              }}
            </div>
          </div>

          <div
            class="plan-detail-section m-y-30"
            *ngIf="userService.userSubscription.base.bluesnap_subscription && userService.userSubscription.base.bluesnap_subscription['catalog-recurring-charge']"
          >
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.sub_frequency" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.userSubscription.base.bluesnap_subscription["charge-frequency"] }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.recurring_amt" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
                {{
                  userService.userSubscription.base.bluesnap_subscription["catalog-recurring-charge"].amount +
                    " - " +
                    userService.userSubscription.base.bluesnap_subscription["catalog-recurring-charge"].currency
                }}
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              *ngIf="userService.userSubscription.base.bluesnap_subscription['credit-card'] && userService.userSubscription.base.cancel_reason != 'terminate bluesnap'"
            >
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.card_on_file" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
                {{
                  ("sphere.account.ending_in" | translate) +
                    " " +
                    userService.userSubscription.base.bluesnap_subscription["credit-card"]["card-last-four-digits"] +
                    " (" +
                    userService.userSubscription.base.bluesnap_subscription["credit-card"]["card-type"] +
                    ")"
                }}&nbsp;&nbsp;&nbsp;
                <button
                  class="small-btn"
                  *ngIf="
                    !userService.userSubscription.base.cardLoading &&
                    (!env.features.bluesnap_to_stripe_migration || (env.features.bluesnap_to_stripe_migration && !userService.userSubscription.base.schedule_subscription_id))
                  "
                  (click)="changeCard()"
                >
                  Change
                </button>
                <fa-icon *ngIf="userService.userSubscription.base.cardLoading" icon="spinner" [animation]="'spin'"></fa-icon>
              </div>
            </div>
            <div class="d-flex align-items-center" *ngIf="userService.userSubscription.base.bluesnap_subscription['paypal-subscription']">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">
                {{ "account.payment_source" | translate }}
              </div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
                {{ "account.paypal" | translate }}
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              *ngIf="statusMap[userService.userSubscription.base.bluesnap_subscription.status].title != 'Canceled' || userService.userSubscription.base.schedule_subscription_id"
            >
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.next_charge_date" | translate }}</div>
              |

              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ nextChargeDate | date: "MMM Do, YYYY" }}</div>
            </div>
            <div class="d-flex align-items-center" *ngIf="statusMap[userService.userSubscription.base.bluesnap_subscription.status].title != 'Canceled'">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.auto_renew" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
                <fa-icon
                  [class.c-red]="userService.userSubscription.base.bluesnap_subscription['auto-renew'] != 'true'"
                  [class.c-green]="userService.userSubscription.base.bluesnap_subscription['auto-renew'] == 'true'"
                  [icon]="userService.userSubscription.base.bluesnap_subscription['auto-renew'] == 'true' ? 'check-circle' : 'times-circle'"
                ></fa-icon>
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              *ngIf="!userService.userSubscription.base.schedule_subscription_id && statusMap[userService.userSubscription.base.bluesnap_subscription.status].title == 'Canceled'"
            >
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">
                {{ "account.plan_end_date" | translate }}
              </div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.userSubscription.base.end_date | date }}</div>
            </div>
          </div>

          <div *ngIf="statusMap[userService.userSubscription.base.bluesnap_subscription.status].title != 'Canceled' && !env.features.bluesnap_to_stripe_migration" class="text-center">
            <button class="small-btn" (click)="switchPlan()">
              {{ (userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "ANNUALLY" ? "account.switch_to_monthly_plan" : "account.switch_to_annual_plan") | translate }}
            </button>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="
          userService.userSubscription &&
          userService.shopperData &&
          userService.shopperData.saved_cards &&
          userService.userSubscription.base.cancel_reason != 'terminate bluesnap' &&
          userService.shopperData.saved_cards.length &&
          (!env.features.bluesnap_to_stripe_migration || (env.features.bluesnap_to_stripe_migration && !userService.userSubscription.base.schedule_subscription_id))
        "
      >
        <h4 class="section-header m-t-50">{{ "sphere.account.cards_on_file" | translate }}</h4>
        <div
          class="list-item hover dontgrow"
          [ngStyle]="{ opacity: editingExpDetails && editingExpDetails['credit-card']['card-last-four-digits'] != card['credit-card']['card-last-four-digits'] ? '.2' : '1' }"
          *ngFor="let card of userService.shopperData.saved_cards"
        >
          <div class="list-item-container" [class.flex-wrap]="editingExpDetails">
            <div class="main-data">
              {{ "sphere.account.ends_in" | translate }} {{ card["credit-card"]["card-last-four-digits"] }}
              <br />
              <span class="sub-data">
                <span>
                  {{ card["credit-card"]["card-type"] }} - {{ card["credit-card"]["issuing-bank"] }}
                  <br />
                </span>
              </span>
            </div>
            <div class="right-data-buttons">
              <fa-icon
                icon="edit"
                [ngbTooltip]="'Update Expiration Date'"
                placement="top"
                *ngIf="!editingExpDetails && !card.loading"
                class="action circle-icon-btn"
                (click)="editingExpDetails = card"
              ></fa-icon>
              <fa-icon
                icon="times"
                [ngbTooltip]="'sphere.account.remove_card' | translate"
                placement="top"
                *ngIf="
                  !editingExpDetails &&
                  !card.loading &&
                  (!userService.userSubscription.base ||
                    !userService.userSubscription.base.bluesnap_subscription ||
                    (userService.userSubscription.base.bluesnap_subscription['credit-card'] &&
                      userService.userSubscription.base.bluesnap_subscription['credit-card']['card-last-four-digits'] != card['credit-card']['card-last-four-digits']))
                "
                class="action circle-icon-btn m-l-10"
                (click)="updateCreditCard(card, false, true)"
              ></fa-icon>
              <fa-icon icon="spinner" [animation]="'spin'" [ngbTooltip]="'general.loading_dots' | translate" placement="top" class="action circle-icon-btn" *ngIf="card.loading"></fa-icon>
            </div>
            <div class="d-flex w-full flex-wrap m-t-10" *ngIf="editingExpDetails && editingExpDetails['credit-card']['card-last-four-digits'] == card['credit-card']['card-last-four-digits']">
              <div class="form-group m-r-20">
                <label class="control-label" for="date">{{ "account.expiration_date" | translate }}</label>
                <input type="text" class="form-control" name="date" maxlength="7" style="width: 100px" placeholder="MM/YYYY" [(ngModel)]="expDetails.stringDate" />
              </div>
              <div class="form-group m-r-20">
                <label class="control-label" for="ccv">{{ "account.ccv" | translate }}</label>
                <input type="text" class="form-control" name="ccv" maxlength="4" style="width: 100px" placeholder="Enter ccv..." [(ngModel)]="expDetails.ccv" />
              </div>
              <div class="form-group m-r-20">
                <small class="form-text">&nbsp;</small>
                <span class="btn btn-primary" (click)="saveNewExpDetails(card)">{{ "account.save" | translate }}</span>
              </div>
              <div class="form-group">
                <small class="form-text">&nbsp;</small>
                <span class="btn btn-danger" (click)="editingExpDetails = false">{{ "account.cancel" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <button class="small-btn" (click)="showUpdateCard()" *ngIf="!editingExpDetails">{{ "sphere.account.add_card" | translate }}</button>
      </div>
      <ng-container *ngIf="charges && charges.length">
        <h4 class="section-header m-t-50">{{ "sphere.account.payment_history" | translate }}</h4>
        <div class="plan-detail-section m-t-30 m-b-20">
          <div class="flex">
            <div class="p-10 d-flex justify-content-between" style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)" *ngFor="let pay of charges">
              <span>{{ (pay.date | date: "MMM Do, YYYY") + " " + pay.description }}</span>
              <span class="text-nowrap">
                {{ pay.amount ? pay.amount : " - " }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="m-y-20 text-center">
        <button
          class="small-btn red"
          *ngIf="
            userService.userSubscription &&
            userService.userSubscription.base.bluesnap_subscription &&
            (!userService.userSubscription.base.bluesnap_subscription.status || userService.userSubscription.base.bluesnap_subscription.status != 'failed') &&
            statusMap[userService.userSubscription.base.bluesnap_subscription.status]?.title != 'Canceled'
          "
          (click)="cancelSubscription()"
        >
          {{ "sphere.account.cancel_membership" | translate }}
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>
  <ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
</div>
