import { Component, Input, Output, EventEmitter, OnInit, Directive } from "@angular/core";
import { ContentService, EventsService, ProductService, UserService } from "@mypxplat/xplat/core";

// libs
import { BaseComponent } from "@mypxplat/xplat/core";
import { filter, take, takeUntil } from "rxjs/operators";

@Directive()
export abstract class SphereHomeBaseComponent extends BaseComponent implements OnInit {
  public recentProducts: Array<any>;
  public productsLoading: boolean = true;
  public videos: Array<any>;
  public videosLoading: boolean = true;
  public events: Array<any> = [];
  public eventsLoading: boolean = true;
  public newsLoading: boolean = true;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public contentService: ContentService,
    public eventsService: EventsService
  ) {
    super(userService);
  }

  ngOnInit(platform?) {
    this.productService.products$
      .pipe(
        filter((products) => !!products),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        if (result) {
          this.productsLoading = false;
          this.recentProducts = [];
          let addedRecentProducts = {};

          // killed on 1/14/25 in favor of redeeming from product detail page.
          // this.productService.getRedeemableProducts().subscribe((result) => {});
          this.productService.productsByAcquired.forEach((prod) => {
            if (!addedRecentProducts[prod.stringId] && this.recentProducts.length < 3) {
              this.recentProducts.push(prod);
              addedRecentProducts[prod.stringId] = true;
            }
          });
        }
      });
    this.productService.getHomeRss(4, "sphere." + (platform ? platform : "web")).subscribe((result) => {
      this.newsLoading = false;
    });
    this.contentService.educationDataStream$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.videos = [];
        this.videosLoading = false;
        this.videos = result.videos.sort((a, b) => (a.created > b.created ? -1 : 1)).filter((item, index) => index <= 3);
      }
    });
    this.eventsService.events$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.eventsLoading = false;
      this.events = result;
    });
    if (this.eventsService.events) this.eventsService.getEvents(10).subscribe(); // update cached events
    this.eventsService.getEvents(10, true).subscribe();
  }
}
