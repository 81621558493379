<div class="file-folders h-full">
  <div class="text-center file-breadcrumbs">
    <div>
      <fa-icon [icon]="folderBreadCrumbSelection.length > 1 ? 'arrow-left' : 'home'" class="circle-icon-btn back-btn" (click)="folderBack()"></fa-icon>
      {{ folderBreadCrumbSelection.length > 1 ? folderBreadCrumbSelection[folderBreadCrumbSelection.length - 1].filename : "Home" }}
      &nbsp;&nbsp;
      <fa-icon icon="spinner" animation="spin" *ngIf="isGettingFiles"></fa-icon>
    </div>
    <div class="d-flex align-items-center">
      <div class="t-12 m-r-10">
        <span *ngFor="let folder of folderBreadCrumbSelection" (click)="selectFolder(folder, true)">
          /
          <span class="folder">{{ folder?.filename || "Home" }}</span>
        </span>
      </div>
      <fa-icon icon="plus" *ngIf="!batchOperationMode" class="action circle-icon-btn m-r-10" placement="left" [ngbTooltip]="'com.create_folder' | translate" (click)="showCreateFolder()"></fa-icon>
      <fa-icon
        [icon]="batchOperationMode ? 'times' : 'check-square'"
        class="action circle-icon-btn m-r-10"
        [class.red]="batchOperationMode"
        placement="left"
        [ngbTooltip]="(batchOperationMode ? 'sphere.cancel' : 'com.select_files') | translate"
        (click)="batchOperationClicked.next(true)"
        *ngIf="filesByFolderId[selectedFolder?.id || 'Home']?.length"
      ></fa-icon>
    </div>
  </div>

  <div class="h-full" class="file-folders-container">
    <!-- FOLDERS -->
    <div class="list-item hover dontgrow folder" *ngFor="let folder of displayedFolders" (click)="selectFolder(folder)">
      <div class="list-item-container">
        <fa-icon icon="sync" class="m-r-10" placement="right" ngbTooltip="This folder is synced in Studio One" *ngIf="folder.synced"></fa-icon>
        <img src="./assets/icons/file-icons/folder.png" class="item-image file-icon" />
        <div class="main-data">
          {{ decodeName(folder.filename) }}
          <br />
        </div>
        <div class="right-data-buttons no-hover">
          <fa-icon icon="ellipsis-h" *ngIf="!batchOperationMode && !folder.synced" class="action circle-icon-btn m-r-10" (click)="folderOptions(folder, $event)"></fa-icon>
          <fa-icon icon="chevron-right" *ngIf="!batchOperationMode" class="action m-r-10"></fa-icon>
        </div>
      </div>
    </div>
    <!-- /FOLDERS -->
    <!-- FILES -->
    <ng-container *ngIf="loadingFiles">
      <myp-skeleton-loader type="list-item" number="8" extraContainerClass="m-y-20 m-x-10"></myp-skeleton-loader>
    </ng-container>
    <div class="text-center h-full d-flex align-items-center" *ngIf="!loadingFiles && !displayedFolders?.length && !filesByFolderId[selectedFolder?.id || 'Home']?.length">
      <div class="w-full text-center">
        {{ "com.folder_is_empty" | translate }}
        <br />
        <br />
        <button class="small-btn" (click)="addFileClicked.next(true)">{{ "profile.edit.upload_file" | translate }}</button>
      </div>
    </div>
    <ng-container *ngIf="!loadingFiles">
      <myp-list-item
        *ngFor="let file of filesByFolderId[selectedFolder?.id || 'Home']"
        [photo]="!workspaceService.imageFiles[file.extension] ? './assets/icons/file-icons/' + (workspaceService.availableFileIcons[file.extension] ? file.extension + '.png' : 'file.png') : file.url"
        [showImage]="
          !workspaceService.imageFiles[file.extension] || (workspaceService.imageFiles[file.extension] && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress == 100)
        "
        [title]="file.type == 'stems' ? 'Stem Upload from Studio One' : file.format_grouping_id ? removeExtension(file.filename) : file.filename"
        [imageRound]="false"
        [subDataHtml]="itemSubData"
        (itemClicked)="fileItemClick(file)"
        [includeChevron]="false"
      >
        <div class="button-content">
          <fa-icon
            icon="play"
            *ngIf="
              contentService.loadingPlayId != file.id &&
              !file.grouping_id &&
              !batchOperationMode &&
              workspaceService.audioFiles[file.extension] &&
              workspaceService.fileMap[file.id] &&
              workspaceService.fileMap[file.id].progress == 100
            "
            class="action circle-icon-btn m-r-10"
            (click)="playAudioClicked.next({ file: file, event: $event })"
          ></fa-icon>
          <fa-icon icon="spinner" animation="spin" *ngIf="contentService.loadingPlayId == file.id" class="action circle-icon-btn m-r-10"></fa-icon>
          <fa-icon
            icon="download"
            *ngIf="!batchOperationMode && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress == 100 && file.type != 'stems'"
            class="action circle-icon-btn m-r-10"
            (click)="downloadFileClicked.next({ file: file, event: $event })"
          ></fa-icon>
          <fa-icon icon="ellipsis-h" *ngIf="!batchOperationMode && !file.synced" class="action circle-icon-btn m-r-10" (click)="msgOptions(file, $event)"></fa-icon>
          <span class="m-r-10 unread-count" *ngIf="unReadCounts[file.id] && !batchOperationMode">{{ unReadCounts[file.id] }}</span>
          <fa-icon icon="chevron-right" *ngIf="!batchOperationMode" class="action m-r-10"></fa-icon>
          <fa-icon
            [icon]="file.selectedForBatchOperation ? 'check' : 'minus'"
            *ngIf="batchOperationMode"
            class="action circle-icon-btn m-r-10"
            [class.purple]="file.selectedForBatchOperation"
          ></fa-icon>
          <ng-template #itemSubData>
            <ng-container *ngIf="file.type == 'stems' && file.grouping_id">
              <span>
                {{ contentService.groupedFilesMap[file.grouping_id][0].filename }}
                <br />
              </span>
              <span *ngIf="contentService.groupedFilesMap[file.grouping_id][1]">
                {{ contentService.groupedFilesMap[file.grouping_id][1].filename }}
                <br />
              </span>
              <span *ngIf="contentService.groupedFilesMap[file.grouping_id][2]">
                {{ contentService.groupedFilesMap[file.grouping_id][1].filename }}
                <br />
              </span>
              <span *ngIf="contentService.groupedFilesMap[file.grouping_id].length > 3">
                ...plus {{ contentService.groupedFilesMap[file.grouping_id].length - 3 }} more ({{ contentService.groupedFilesMap[file.grouping_id].length }} total)
                <br />
              </span>
              <span *ngIf="contentService.groupedFilesMap[file.grouping_id].length < 3">
                {{ contentService.groupedFilesMap[file.grouping_id].length }} total file{{ contentService.groupedFilesMap[file.grouping_id].length > 1 ? "s" : "" }}
                <br />
              </span>
            </ng-container>
            <ng-container *ngIf="file.format_grouping_id">
              <div class="d-flex">
                <span class="m-r-5 d-block">Contains</span>
                <span class="file-format-pill" *ngFor="let format of contentService.groupedFormatsMap[file.format_grouping_id]">{{ format.extension }}</span>
              </div>
            </ng-container>
            <span *ngIf="file.description">
              {{ file.description }}
              <br />
            </span>
            <span *ngIf="!file.grouping_id && !file.format_grouping_id && file.filesize && workspaceService.fileMap[file.id].progress == 100">
              {{ ("download.file_size" | translate) + ": " + (file.filesize | bytesToSize) }}
              <br />
            </span>
            <span
              *ngIf="
                !file.grouping_id &&
                !file.format_grouping_id &&
                ((workspaceService.uploadingFilesSessionMap[file.id] && workspaceService.uploadingFilesSessionMap[file.id].progress != 100) ||
                  (workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress != 100 && !workspaceService.fileMap[file.id].stalled))
              "
              class="c-orange"
            >
              {{ "sphere.workspaces.upload_incomplete" | translate }}
              {{ workspaceService.uploadingFilesSessionMap[file.id] ? workspaceService.uploadingFilesSessionMap[file.id].progress : workspaceService.fileMap[file.id].progress }}%
              <br />
            </span>
            <span
              *ngIf="
                !file.grouping_id && !file.format_grouping_id && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].stalled && !workspaceService.uploadingFilesSessionMap[file.id]
              "
            >
              <fa-icon icon="exclamation-circle" class="m-r-5"></fa-icon>
              {{ "sphere.workspaces.delete_reupload" | translate }}
              <br />
            </span>
            <span *ngIf="file.date_created">
              {{ "sphere.workspaces.added_on_date" | translate }} {{ file.date_created | date }}
              {{ file.user_id == user.id ? "by me" : collaboratorMap[file.user_id] ? "by " + collaboratorMap[file.user_id].name : "" }}
              <br />
            </span>
            <span *ngIf="!file.date_created && (file.user_id == user.id || collaboratorMap[file.user_id])">
              Added by {{ file.user_id == user.id ? "me" : collaboratorMap[file.user_id].name }}
              <br />
            </span>
          </ng-template>
        </div>
      </myp-list-item>
    </ng-container>
    <!-- /FILES -->
  </div>
</div>
