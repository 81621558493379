import { Input, OnInit, Directive, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "@mypxplat/xplat/core";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";

@Directive()
export abstract class UpgradeSoftwareBaseComponent extends BaseComponent {
  @Input() upgradingProduct: any;
  @Input() isAdmin: boolean = false;
  @Input() userId: string;
  public loading: boolean = false;
  public productKey: string;
  @Output() upgradeComplete: EventEmitter<any> = new EventEmitter();

  constructor(
    storageService: StorageService,
    userService: UserService,
    private _productService: ProductService,
    public translate: TranslateService
  ) {
    super(userService);
  }
}
