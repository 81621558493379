import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-move-file",
  templateUrl: "move-file.component.html",
})
export class MoveFileComponent extends BaseComponent implements OnInit {
  @Input() folders: any;
  @Input() files: any;
  @Input() workspaceID: any;
  @Input() folderToMove: any;
  @Output() moved: EventEmitter<any> = new EventEmitter();
  public selectedFolder: any;
  public moving: boolean = false;
  public filesExistInDestination: Array<any> = [];

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
  }

  ngOnInit() {
    console.log(this.folderToMove);
  }

  select(folder) {
    if (this.folderToMove && (this.isDescendant(this.folderToMove, folder) || this.folderToMove.id == folder.id)) {
      alert("You cannot move a folder into itself or one of its children.");
    } else {
      this.selectedFolder = folder;
    }
  }

  /**
   * Returns true if `target` folder is the same as `candidateParent`
   * OR if `target` is a nested child of `candidateParent`.
   */
  private isDescendant(candidateParent: any, target: any): boolean {
    // If the candidateParent has no children, it can't contain anything else
    if (!candidateParent?.children) {
      return false;
    }

    for (const child of candidateParent.children) {
      // Direct match
      if (child.id === target.id) {
        return true;
      }
      // Recursive check in deeper levels
      if (this.isDescendant(child, target)) {
        return true;
      }
    }

    return false;
  }

  save() {
    this.moving = true;
    if (this.folderToMove) {
      let ids = [this.folderToMove.id];
      this.workspaceService.updateFile({ folder_id: this.selectedFolder?.id || "Home" }, ids).subscribe((result) => {
        this.moving = false;
        this.moved.next(result);
        this.activeModal.close();
      });
    } else {
      // check if any files exist in the destination with the same name.
      this.filesExistInDestination = [];
      this.workspaceService.getFilesByFolderId(this.selectedFolder.id, this.workspaceID).subscribe((result: any[]) => {
        let movingFileMap = {};
        this.files.forEach((file) => (movingFileMap[file.filename] = file));
        this.filesExistInDestination = result.filter((file) => movingFileMap[file.filename]);
        console.log(this.filesExistInDestination);
        if (!this.filesExistInDestination.length) {
          let ids = [];
          this.files.forEach((file) => {
            ids.push(file.id);
          });
          this.workspaceService.updateFile({ folder_id: this.selectedFolder?.id || "Home" }, ids).subscribe((result) => {
            this.moving = false;
            this.moved.next(result);
            this.activeModal.close();
          });
        } else {
          this.moving = false;
        }
      });
    }
  }
}
