import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UserService, ProductService, EventBusService, ProductListItem, ProductSnapshot } from "@mypxplat/xplat/core";
import { Product } from "@mypxplat/xplat/core";
import { ProductTileBaseComponent } from "@mypxplat/xplat/features";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";
import { StudiooneGracePeriodComponent } from "../modals";
import { RuleSet } from "@nativescript/core/ui/styling/css-selector";
import { Router } from "@angular/router";

@Component({
  selector: "myp-product-tile",
  templateUrl: "product-tile.component.html",
})
export class ProductTileComponent extends ProductTileBaseComponent implements OnInit {
  @ViewChild("register") registerBtn: ElementRef;
  @Input() product: any;
  @Input() image: string;
  @Input() buttons: Array<any>;
  @Input() title: string;
  @Input() description: string;
  @Input() productLink: string;
  @Input() registerProductTile: boolean;
  @Input() redeemS17Tile: boolean;
  @Input() showDate: boolean = true;
  @Output() tileClicked: EventEmitter<any> = new EventEmitter();
  @Output() registerProductClicked: EventEmitter<any> = new EventEmitter();
  @Output() redeems17Clicked: EventEmitter<any> = new EventEmitter();
  public ownedProductID: any;
  public samplePrefix = "https://pae-web.presonusmusic.com/uploads/products/";
  public redeeming: boolean = false;
  constructor(
    public userService: UserService,
    public productService: ProductService,
    public appService: AppService,
    public translate: TranslateService,
    public eventBusService: EventBusService,
    public avoService: AvoService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.product) {
      if (!this.productLink && this.product.url) {
        this.productLink = this.product.url;
      } else if (!this.productLink && this.product.permalink_products) {
        this.productLink = "https://shop.presonus.com/" + this.product.permalink_products;
      }

      this.title = this.product.title;
      this.description = this.product.shortDescription;
      this.image = this.product.imageUrl;
    }

    this.productService.newProductRegistered.subscribe((flag) => {
      if (this.registerBtn && flag) {
        this.registerBtn.nativeElement.focus();
        this.productService.newProductRegistered$.next(false);
      }
    });
  }

  redeemInfo() {
    alert("You are elligible to redeem this software for free! Click Redeem Product and it will be automatically registered to your account.");
  }

  redeemProduct() {
    this.redeeming = true;
    this.productService.redeemProduct(this.product.id, null, this.product.groupId).subscribe((result) => {
      this.productService.getProducts(true).subscribe((result) => {
        this.redeeming = false;
        this.tileClicked.next(true);
      });
    });
  }

  tileClick() {
    if (this.product?.redeemable) {
      alert("You are elligible to redeem this software for free! Click Redeem Product and it will be automatically registered to your account.");
    } else {
      this.tileClicked.next(true);
    }
  }

  showRedeemS17() {
    const modalRef = this.appService.showModal(StudiooneGracePeriodComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.asset = this.productService.s16ProductToUpgradeTo7;
  }

  playSample(url) {
    this.eventBusService.emit(this.eventBusService.types.playAudio, {
      url: this.samplePrefix + url,
      filename: this.product.title,
    });
  }

  downloadInstaller(product) {
    this.productService.getProductSnapshot(product.id).subscribe((snapshot: ProductSnapshot) => {
      if (this.appService.os == "mac") {
        this._getTokenizedInstaller(snapshot.downloads.installers.mac[0]);
      } else {
        if (snapshot.downloads.installers.win64) {
          this._getTokenizedInstaller(snapshot.downloads.installers.win64[0]);
        } else {
          this._getTokenizedInstaller(snapshot.downloads.installers.win[0]);
        }
      }
    });
  }

  private _getTokenizedInstaller(dl) {
    if (dl.tokenized) {
      this.productService
        .getTokenizedUrl(
          dl.folder,
          dl.filename,
          this.userService.appId == "mypresonus" ? "mypresonus" : "studio_one_plus",
          dl.title,
          dl.id.toString(),
          dl.filename,
          parseInt(dl.filesize),
          dl.version || dl.version_number
        )
        .subscribe({
          next: (result: any) => {
            window.open(result.url);
          },
          error: (error) => {
            this.appService.alertError(error);
          },
        });
    } else {
      window.open(dl.url);
    }
  }
}
