<div class="row m-x-10">
  <div class="col-12">
    <h4 class="section-header m-b-20">{{ 'account.storage' | translate }}</h4>
  </div>

  <div class="col-12">
    <myp-storage-meter></myp-storage-meter>
    <ng-container *ngIf="!storageData">
      <myp-skeleton-loader type="line" height="15px" width="25%"></myp-skeleton-loader>
      <myp-skeleton-loader type="line" height="35px" width="100%" extraContainerClass="m-t-15" borderRadius="30px"></myp-skeleton-loader>
    </ng-container>
  </div>
  <div class="col-12 m-t-50"><h4 class="section-header m-b-10">{{ 'account.workspace_files' | translate }}</h4></div>
  <div class="col-12" *ngIf="!storageData">
    <myp-skeleton-loader type="line" height="15px" width="80%" extraContainerClass="m-b-30"></myp-skeleton-loader>
    <myp-skeleton-loader type="line" height="15px" width="80%"></myp-skeleton-loader>
  </div>
  <ng-container *ngIf="storageData">
    <div class="col-4 col-lg-3">{{ 'account.number_of_files' | translate }}</div>
    <div class="col-8 col-lg-9">
      {{ storageData.count_by_section.files }}
    </div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
    <div class="col-4 col-lg-3">{{ 'account.total_filesize' | translate }}</div>
    <div class="col-8 col-lg-9">
      {{ storageData.total_size_by_section.files | bytesToSize }}
    </div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
  </ng-container>

  <div class="col-12 m-t-50"><h4 class="section-header m-b-10">{{ 'account.backups' | translate }}</h4></div>
  <div class="col-12" *ngIf="!storageData">
    <myp-skeleton-loader type="line" height="15px" width="80%" extraContainerClass="m-b-30"></myp-skeleton-loader>
    <myp-skeleton-loader type="line" height="15px" width="80%"></myp-skeleton-loader>
  </div>
  <ng-container *ngIf="storageData">
    <div class="col-4 col-lg-3">{{ 'account.number_of_files' | translate }}</div>
    <div class="col-8 col-lg-9">
      {{ storageData.count_by_section.backups }}
    </div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
    <div class="col-4 col-lg-3">{{ 'account.total_filesize' | translate }}</div>
    <div class="col-8 col-lg-9">
      {{ storageData.total_size_by_section.backups | bytesToSize }}
    </div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3">
      <button class="btn btn-secondary btn-sm" (click)="shouldShowBackups = !shouldShowBackups">
        {{ shouldShowBackups ? ('account.hide_backups' | translate) : ('account.show_backups' | translate) }}
      </button>
    </div>
  </ng-container>

  <div class="col-12 m-t-50" *ngIf="shouldShowBackups">
    <h4 class="section-header m-b-10">{{ 'account.manage_backups' | translate }}</h4>
    <myp-list-item *ngFor="let backup of backups" [title]="backup.filename" [metadata]="getBackupMetadata(backup)" [showImage]="false" [includeChevron]="false">
      <div class="button-content">
        <fa-icon
          icon="times"
          *ngIf="!removingBackups[backup.id]"
          class="action circle-icon-btn"
          ngbTooltip="{{ 'account.remove_backup' | translate }}"
          tabindex="0"
          (keyup.enter)="removeBackup(backup)"
          (click)="removeBackup(backup)"
        ></fa-icon>
        <fa-icon *ngIf="removingBackups[backup.id]" icon="spinner" animation="spin" class="action circle-icon-btn"></fa-icon>
      </div>
    </myp-list-item>
  </div>
</div>
