<div class="header-drop-menu" data-cy="header-drop-menu">
  <div class="pull-right">
    <button type="button" class="btn btn-transparent p-0" (click)="close(false, true)" aria-label="close">
      <fa-icon icon="times" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Close"></fa-icon>
      <span class="sr-only">close</span>
    </button>
  </div>

  <div class="hd-top m-t-20">
    <div class="d-flex align-items-center m-b-20">
      <img role="img" aria-label="user profile image" [src]="userService.profilePicture" class="header-menu-profile-pic" alt="user profile image" />
      <div class="w-full">
        <div class="d-flex justify-content-between align-items-center">
          <h1 id="modal-title" class="name mb-0" *ngIf="userService.user.firstName && userService.user.lastName">{{ userService.user.firstName + " " + userService.user.lastName }}</h1>
        </div>
        <a (click)="close(false, true)" class="email" data-cy="user-email" style="overflow: hidden; text-overflow: ellipsis">{{ userService.user.email }}</a>
        <a class="small-btn my-0" *ngIf="!admin" (click)="close(false, true)" [routerLink]="['../account/general']">{{ "profile.edit_profile" | translate }}</a>
      </div>
    </div>
    <ng-container *ngIf="admin">
      <ul class="nav flex-column">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../products']">Products</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../studiooneplus']">Studio One Pro+</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../customer']">Customer</a>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="!admin">
      <ul class="nav flex-column">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../home']">{{ "header.nav.home" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" data-cy="account-nav-link" class="account-link" [routerLink]="['../account']">{{ "sphere.account.account" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../orders']">{{ "header.nav.orders" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../account/plan']">{{ "sphere.account.membership_billing" | translate }}</a>
          <hr class="extra mb-0" />
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../products']">{{ "header.nav.products" | translate }}</a>
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../learn']">{{ "header.nav.learn" | translate }}</a>
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../support']">{{ "header.nav.support" | translate }}</a>
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../workspaces']">{{ "sphere.workspaces.workspaces" | translate }}</a>
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../exchange']">{{ "header.nav.exchange" | translate }}</a>
        </li>
        <li class="nav-item extra" [routerLinkActive]="['active']" *ngIf="!user.banned">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../community']">Community</a>
        </li>
      </ul>
      <hr />
      <ul class="nav flex-column">
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="user.active_subscription">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../messages']">{{ "com.messages" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../events']">{{ "header.nav.events" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="user.is_dealer">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../dealer']">{{ "header.nav.dealer" | translate }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="account-link" (click)="viewAllAnnouncements()" href="javascript:void();">{{ "newdash.view_announcements" | translate }}</a>
        </li>
      </ul>
      <hr />
      <ul class="nav flex-column">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../metro']">Metro</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="betaService.shouldShowBetaSection">
          <a class="account-link" (click)="close(false, true)" [routerLink]="['../beta']">Beta Programs</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isBetaManager">
          <a (click)="close(false, true)" class="account-link" [routerLink]="['../managebetas']">Manage Beta Features</a>
        </li>
        <!-- <li class="nav-item" [routerLinkActive]="['active']" *ngIf="betaService.enrolledBetas && betaService.enrolledBetas.length">
          <a (click)="openSubmitFeedback(); close(false, true)" class="account-link" href="javascript:void();">Submit Beta Feedback</a>
        </li> -->
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="user.is_sphere_admin">
          <a (click)="close(false, true)" class="account-link" [href]="env.adminUrl" target="_blank">{{ "header.nav.admin" | translate }}</a>
        </li>
      </ul>
      <hr />
    </ng-container>
    <button type="button" class="small-btn" (click)="appService.showLanguageChooser()" aria-label="language">Language ({{ helperService.selectedLanguage.code | uppercase }})</button>
    <button
      type="button"
      class="small-btn"
      *ngIf="user.is_editor || user.is_support || user.is_admin"
      (click)="openUrl('https://app.contentful.com/spaces/b7gl3dxk4jyc/views/entries')"
      aria-label="rss"
    >
      {{ "profile.rss_editor" | translate }}
    </button>
    <button type="button" class="small-btn" *ngIf="(user.is_support || user.is_admin) && !admin" (click)="openUrl('http://uux.presonus.com')" aria-label="UUX">UUX</button>
    <button type="button" class="small-btn" *ngIf="user.is_media" (click)="openUrl('http://media.presonus.com/')" aria-label="media">{{ "profile.media_vault" | translate }}</button>

    <!-- <span class="account-link dropdown-item" (click)="showThemeChooser(); close(false, true)">{{ "newdash.choose_theme" | translate }}</span> -->
    <hr />
    <fieldset class="theme-selection">
      <legend class="brandfont-bold m-b-10">Theme</legend>
      <myp-segmented-bar [white]="true" (selected)="selectTheme($event.id)" [options]="themes" uniqueID="theme-selector" [workAsRadioButton]="true"></myp-segmented-bar>
    </fieldset>

    <hr />
    <ul class="nav flex-column">
      <li class="nav-item" [routerLinkActive]="['active']">
        <button (click)="logout(); close(false, true)" data-cy="logout-button" class="small-btn" aria-label="logout">{{ "header.nav.logout" | translate }}</button>
      </li>
    </ul>
  </div>
</div>
