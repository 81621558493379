import { Component, EventEmitter, Input, OnInit, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { UploadWareBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploader, FileItem } from "ng2-file-upload";
import { StorageService, StorageKeys, UserService, environment, ExchangeService, WindowService, HelperService } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-upload-ware",
  templateUrl: "upload-ware.component.html",
})
export class UploadWareComponent extends UploadWareBaseComponent implements OnInit {
  @Input() editing: any;
  @Input() creator_id: any;
  public original: any;
  public hasBaseDropZoneOver = false;
  public hasBaseDropZoneOverPreview = false;
  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
    url: environment.cloudApiUrl + "uploadFile",
    method: "POST",
    queueLimit: 1,
  });

  public previewUploader: FileUploader;
  public uploadingComplete: boolean = false;
  public previewUploadingComplete: boolean = false;
  public uploadingPreviewFile: any;
  public previewFileResponse: any;
  public uploadingFile: any;
  public fileResponse: any;
  public newAsset: any;
  public saving: boolean = false;
  public saved: boolean = false;
  public audioPreviews: Array<any> = [];
  public newAssetIDs: any = {};
  public extraData: any = {
    pitchlist: "Device",
    ioconfig: "Device",
    macropage: "CategoryAttribute",
    pattern: "CategoryAttribute",
    multisample: "CategoryAttribute",
    keyswitch: "Device",
  };
  public allowedExtensions: Array<string>;
  public fileName: string = "No file chosen";

  constructor(
    public storageService: StorageService,
    public userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public router: Router,
    private _win: WindowService,
    public helperService: HelperService,
    public appService: AppService
  ) {
    super(userService);
    this.allowedExtensions = [];
    for (let i in this.exchangeService.allowedExtensions) this.allowedExtensions.push(i);
    this.uploader.onAfterAddingFile = (file) => {
      let size = file.file.size;
      let ext = file.file.name.split(".").pop();
      this.fileName = file.file.name;
      if (ext == "song" && this.user.is_sphere_admin) {
        // continue.
        this.uploadingFile = file;
        file.withCredentials = false;
      } else if (!this.exchangeService.allowedExtensions[ext]) {
        alert("The file type you are trying to upload is not supported.");
        this.uploader.removeFromQueue(file);
      } else if (size > this.exchangeService.fileSizeLimits[ext] * 1024 * 1024) {
        this.uploader.removeFromQueue(file);
        alert("The file you selected exceeds the maximum file size for this type. The largest file you can upload is " + this.exchangeService.fileSizeLimits[ext] + " MB");
      } else {
        this.uploadingFile = file;
        file.withCredentials = false;
      }
    };
    this.uploader.onProgressAll = (progress: any) => {
      if (progress == 100) this.uploadingComplete = true;
    };
    this.uploader.response.subscribe((response) => {
      this.fileResponse = JSON.parse(response);
      this.newAsset = this.fileResponse.ware;
      this.fileResponse.ware.newlyUploaded = true;
      this.exchangeService.wareDetail$.next(this.fileResponse.ware);
    });
  }

  ngOnInit() {
    if (this.editing) this.original = { ...this.editing };
    this.setupPreviewUploader();
    this._buildPreviews();
  }

  getMimeType(ext) {
    const mimetypes: any = {
      soundset: "application/x-presonus-soundset",
      pitchlist: "application/octet-stream",
      multipreset: "application/octet-stream",
      preset: "application/octet-stream",
      install: "application/x-presonus-extension",
      quantize: "application/octet-stream",
      studioonemacro: "application/octet-stream",
      colorscheme: "application/x-presonus-colorscheme+xml",
      trackpreset: "application/x-presonus-trackpreset",
      colorpalette: "application/x.ccl-colorpalette+json",
    };
    return mimetypes[ext];
  }

  getCategory(ext) {
    const categories: any = {
      soundset: "soundset",
      pitchlist: "pitchlist",
      multipreset: "fxchain",
      preset: "preset",
      install: "install",
      quantize: "quantize",
      studioonemacro: "studioonemacro",
      colorscheme: "colorscheme",
      trackpreset: "trackpreset",
      colorpalette: "colorpalette",
    };
    return categories[ext];
  }

  setupPreviewUploader() {
    this.previewUploader = new FileUploader({
      url: environment.apiUrl + "exchange/upload_ware_preview",
      isHTML5: true,
      method: "POST",
      autoUpload: true,
      queueLimit: 1,
      maxFileSize: this.exchangeService.fileSizeLimits.preview * 1024 * 1024,
      allowedMimeType: ["audio/mp3", "audio/mpeg", "audio/wav"],
      headers: [
        { name: "X-API-KEY", value: environment.presonusApiKey },
        { name: "X-USER-TOKEN", value: this.helperService.token },
      ],
    });
    this.previewUploader.onAfterAddingFile = (file) => {
      let options = {
        additionalParameter: {
          method: "add_ware_preview",
          ware_id: this.editing ? this.editing.id : this.fileResponse.ware.id,
        },
      };
      this.previewUploader.setOptions({
        ...this.previewUploader.options,
        ...options,
      });
      this.uploadingPreviewFile = file;
      file.withCredentials = false;
    };
    this.previewUploader.onProgressAll = (progress: any) => {
      if (progress == 100) this.previewUploadingComplete = true;
    };

    this.previewUploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name == "fileSize") {
        alert("The file you selected exceeds the maximum file size for this type. The largest file you can upload is " + this.exchangeService.fileSizeLimits.preview + " MB");
      } else {
        alert("Adding the file failed. Please make sure you are uploading a WAV or an MP3 file.");
      }
    };
    this.previewUploader.response.subscribe((response) => {
      this.previewFileResponse = JSON.parse(response);
      if (this.editing) {
        this.editing = this.previewFileResponse.ware;
      } else {
        this.fileResponse.ware = this.previewFileResponse.ware;
      }
      this._buildPreviews();
      this.exchangeService.wareDetail$.next(this.previewFileResponse.ware);
      this.previewUploader.clearQueue();
      this._win.setTimeout(() => {
        this.setupPreviewUploader();
        this.previewFileResponse = undefined;
        this.previewUploadingComplete = false;
        this.uploadingPreviewFile = undefined;
      }, 100);
    });
  }

  private _buildPreviews() {
    this.audioPreviews = [];
    let viewingWare = this.editing ? this.editing : this.fileResponse && this.fileResponse.ware ? this.fileResponse.ware : null;

    if (viewingWare && viewingWare.downloads && viewingWare.downloads.length) {
      viewingWare.downloads.forEach((dl) => {
        if (dl.contentRole == "PREVIEW") {
          this.audioPreviews.push(dl);
        }
      });
    }
  }

  fileOverBase(e, type) {
    if (type == "preview") {
      this.hasBaseDropZoneOverPreview = e;
    } else {
      this.hasBaseDropZoneOver = e;
    }
  }

  removeWarePreview(package_id) {
    this.exchangeService.removeWarePreview(this.editing.id, package_id).subscribe((result: any) => {
      if (this.editing) this.editing = result.ware;
      this._buildPreviews();
      this.exchangeService.wareDetail$.next(result.ware);
    });
  }

  performUpload() {
    let headers = [
      { name: "X-API-KEY", value: environment.presonusApiKey },
      { name: "X-USER-TOKEN", value: this.helperService.token },
      { name: "X-USER-ENCRYPTED-ID", value: this.userService.user.encrypted_id },
    ];

    let options: any = {
      headers: headers,
      additionalParameter: {},
    };
    if (this.editing) options.additionalParameter.editingWare = JSON.stringify(this.editing);
    if (this.creator_id) options.additionalParameter.creator_id = this.creator_id;
    this.uploader.setOptions(options);
    this.uploader.uploadAll();
  }

  saveNewAssetDetails() {
    if (this.newAsset.title && this.newAsset.description) {
      this.saving = true;
      let ware: any = {
        id: this.newAsset.id,
        title: this.newAsset.title,
        description: this.newAsset.description,
        device: this.newAsset.device,
        approved: this.newAsset.approved,
      };
      if (this.newAsset.categoryattribute) ware.categoryattribute = this.newAsset.categoryattribute;

      this.exchangeService.updateWare(ware).subscribe((result) => {
        this.saving = false;
        this.router.navigate(["../exchange/detail/", this.newAsset.id]);
        this.activeModal.close();
      });
    } else {
      alert("Please enter a title and a description.");
    }
  }

  saveAssetDetails() {
    this.saving = true;
    let ware: any = {
      id: this.editing.id,
      title: this.editing.title,
      description: this.editing.description,
      device: this.editing.device,
    };
    if (this.extraData[this.editing.extension] == "CategoryAttribute") {
      ware.categoryattribute = this.editing.category;
    }
    this.exchangeService.updateWare(ware).subscribe(
      (result) => {
        this.saving = false;
        this.activeModal.close();
      },
      (error) => {
        this.saving = false;
        this.editing.title = this.original.title;
        this.editing.description = this.original.description;
        this.appService.alertError(error);
      }
    );
  }

  viewNewAsset() {
    this.router.navigate(["../exchange/detail/", this.newAsset.id]);
    this.activeModal.close();
  }
}
