import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { HelperService } from "./helper.service";
import { StorageKeys, StorageService } from "./storage.service";
import { UserService } from "./user.service";
import { catchError, map, take } from "rxjs/operators";
import { Observable, of as observableOf, of, ReplaySubject, Subject, throwError } from "rxjs";
import * as moment from "moment";
import { WindowService } from "./window.service";
import { ProductService } from "./product.service";
import { CurrencyPipe } from "@angular/common";
import { ProductLicenseItem, SimpleProduct } from "../models";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  public checkoutComplete$: Subject<any> = new Subject<any>();
  public productTitle: string;
  public total: any = 0;

  public cardBrandIcons: any = {
    amex: "./assets/icons/cc-icons/amex.png",
    visa: "./assets/icons/cc-icons/visa.png",
    discover: "./assets/icons/cc-icons/discover.png",
    unionpay: "./assets/icons/cc-icons/unionpay.png",
    mastercard: "./assets/icons/cc-icons/mastercard.png",
    generic: "./assets/icons/cc-icons/credit_card.png",
  };

  public isReadyForCheckout$: Subject<any> = new Subject<any>();
  public purchaseComplete$: Subject<any> = new Subject<any>();
  public stripe: any;
  public stripeCustomerId: any;
  public s1PlusPrices: {
    monthly?: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
      productId: any;
      stripeProductId?: string;
      title: string;
      image?: string;
    };
    annual?: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
      productId: any;
      stripeProductId?: string;
      title: string;
      image?: string;
    };
    hybrid_upgrade?: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
      stripeProductId?: string;
      title: string;
      image?: string;
    };
  };
  calculatingTax: boolean = false;
  couponApplied: {
    code?: string;
    coupon_id?: string;
    discount_percent?: number;
    amount_off?: number;
    title?: string;
  };
  public coupons: any;
  public coupon: any;
  public couponData: any;
  public showCouponField: boolean;
  public discountSuccessMessage: string;
  public couponParam: any;
  public applyingCoupon: boolean;
  activeFinalPaymentAmount: number;
  public billingInfoChange$ = new Subject<any>();
  public billingInfo: any = {
    firstName: this.userService.user?.firstName || "",
    lastName: this.userService.user?.lastName || "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: this.userService.user && this.userService.user.detectedCountry ? this.userService.user.detectedCountry : "US",
  };
  public taxedBillingInfo: any;

  public ccInfo: any = {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  };
  public cardCountry: any;
  public stripeCustomerAddress: any;
  public loadingCheckout: boolean = false;
  public loadingPrices: boolean;
  public loadingPaymentMethods: boolean;
  public loadingMsg: string;
  public percentDone: number;
  public taxCalculated: number;
  public totalCharge: number;

  public activeCurrency: any;
  public currencyConverted: boolean;
  public selectedS1PlusPlan: string = "monthly";
  public activeOffers: any;
  public selectedActiveOffer: any;
  public offersRetrieved: boolean = false;
  private fieldsInitiallyCompleted = false; // Introduced flag

  public supportedCurrencies: Array<any>;
  public priceList: Array<any>;
  public discountedMonthlyDisplayPrice: any;
  public discountedAnnualDisplayPrice: any;
  public discountedAnnualMonthlyDisplayPrice: any;
  public applicableActiveOffer: any;
  public monthlyDisplayPrice: any;
  public annualDisplayPrice: any;
  public annualMonthlyDisplayPrice: any;
  public hybridUpgradePrice: any;
  public selectedPrice: any;
  public isTaxIncluded: boolean = false;
  public currencyIsConverted: boolean = false;

  public subscriptionId: any;
  public clientSecret: any;

  public paymentMethods: Array<any>;
  public newPaymentMethod: any;
  public selectedExistingPaymentMethod: any;
  public addNewCard: boolean;

  public source: string;

  public checkoutError: any;
  public orderCompletedTrackingArgs: any;

  public selectedProduct: any;
  public selectedProductUpgrade: any;
  public genericProduct: any;
  public upgradingProduct: any;
  public upgradableProducts: any;
  public upgradableNotionProducts: any;
  public upgradableStudioOneProducts: any;

  public transferableProduct: any;

  public scheduleDetails: any;

  public productTransferDetails: {
    transferingLicense: ProductLicenseItem;
    transferingProduct: SimpleProduct;
  };

  constructor(
    private http: HttpClient,
    private _helperService: HelperService,
    private userService: UserService,
    private storage: StorageService,
    public win: WindowService,
    public productService: ProductService,
    private currencyPipe: CurrencyPipe
  ) {
    this.userService.user$.subscribe((user) => {
      this.stripeCustomerId = user.stripe_id;
    });
  }

  initCheckout(args: { stripe: any; title: string; couponParam?: string; source?: string; productUpgrade?: any; creatingPaymentMethod?: boolean; genericProduct?: any; scheduleDetails?: any }) {
    this.upgradableProducts = args.title.toLowerCase().indexOf("notion") > -1 ? this.upgradableNotionProducts : this.upgradableStudioOneProducts;
    this.loadingCheckout = true;
    this.productTitle = args.title;
    if (args.couponParam) this.couponParam = args.couponParam;
    if (args.source) this.source = args.source;
    if (args.productUpgrade) this.selectedProductUpgrade = args.productUpgrade;
    if (args.genericProduct) this.genericProduct = args.genericProduct;
    if (args.scheduleDetails) this.scheduleDetails = args.scheduleDetails;
    if (!this.selectedS1PlusPlan) this.selectedS1PlusPlan = "monthly";
    return new Promise((resolve, reject) => {
      this.stripe = args.stripe;
      this.setAddress().then(() => {
        if (this.userService.user.stripe_id && !args.creatingPaymentMethod && environment.features.pay_with_saved_card) {
          this.loadCustomerPaymentMethods().then((result) => {
            this.loadingCheckout = false;
            resolve(true);
          });
        } else {
          this.loadingCheckout = false;
          resolve(true);
        }
      });
    });
  }

  getUpgradableProducts(force?, type = "Studio One") {
    let url = environment.apiUrl + `stripe/retrieve_upgradable_products/${type}`;
    if (environment.features.studio_one_seven) url = environment.paeApiUrl + `user/get-upgradable-products/${type}`;

    if (type == "Notion") {
      if (this.upgradableNotionProducts && !force) {
        return of(this.upgradableNotionProducts);
      } else {
        return this.http.get(url, this._helperService.getHttpOptions()).pipe(
          map((result: any) => {
            if (result && result.success && result.data) {
              this.upgradableNotionProducts = result;
              return result;
            }
          })
        );
      }
    } else {
      if (this.upgradableStudioOneProducts && !force) {
        return of(this.upgradableStudioOneProducts);
      } else {
        return this.http.get(url, this._helperService.getHttpOptions()).pipe(
          map((result: any) => {
            if (result && result.success && result.data) {
              this.upgradableStudioOneProducts = result;
              return result;
            }
          })
        );
      }
    }
  }

  loadCustomerPaymentMethods() {
    return new Promise((resolve, reject) => {
      if (this.userService.user.stripe_id) {
        this.loadingPaymentMethods = true;
        this.userService.getPaymentMethods().subscribe({
          next: (result: any) => {
            if (this.userService.paymentMethods) {
              this.stripeCustomerAddress = result.address;
              this.loadingPaymentMethods = false;
              if (this.userService.paymentMethods.length) {
                resolve(true);
                this.win.setTimeout(() => {
                  this.selectPaymentMethod(this.userService.paymentMethods[0]);
                }, 500);
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
              this.loadingPaymentMethods = false;
            }
          },
          error: (error) => {
            resolve(true);
            this.loadingPaymentMethods = false;
          },
        });
      }
    });
  }

  selectPaymentMethod(paymentMethod) {
    if (!this.selectedExistingPaymentMethod || this.selectedExistingPaymentMethod != paymentMethod.id) {
      this.selectedExistingPaymentMethod = paymentMethod.id;
      this.totalCharge = undefined;
      this.loadCountryOfCard(paymentMethod);
      this.addNewCard = false;
    }
  }

  addNewPaymentMethod() {
    if (!this.addNewCard) {
      this.selectedExistingPaymentMethod = undefined;
      this.billingInfo = {
        ...this.billingInfo,
        address: "",
        city: "",
        state: "",
        zip: "",
        country: this.userService.user && this.userService.user.detectedCountry ? this.userService.user.detectedCountry : "US",
      };
      this.loadPriceByCountry(this.billingInfo.country);
      this.addNewCard = true;
    } else {
      this.addNewCard = false;
    }
  }

  onBillingInfoChange(property: string, newValue: any) {
    const previousState = this.billingInfo.state;
    const previousCountry = this.billingInfo.country;

    this.billingInfo[property] = newValue;

    let args: any = { billingInfo: this.billingInfo, fieldChanged: property, newValue: newValue };

    if (this.allFieldsPresent()) {
      if (!this.fieldsInitiallyCompleted) {
        this.fieldsInitiallyCompleted = true; // Set the flag true once all fields are filled out the first time
        args.shouldRecalculateTax = true;
      } else if ((property === "state" && previousState !== newValue) || (property === "country" && previousCountry !== newValue) || (property === "zip" && previousCountry !== newValue)) {
        args.shouldRecalculateTax = true;
      }
    }

    if (property === "country" && newValue != previousCountry) {
      this.billingInfo.state = "";
    }
    if (args.fieldChanged == "country") {
      this.loadPriceByCountry(this.billingInfo.country).then(() => {
        if (args.shouldRecalculateTax) this.calculateFinalAmount();
      });
    } else {
      if (args.shouldRecalculateTax) this.calculateFinalAmount();
    }
    this.billingInfoChange$.next(args);
  }

  public hasSavedAddress: boolean = false;
  setAddress() {
    return new Promise((resolve, reject) => {
      this.billingInfo.firstName = this.userService.user.firstName;
      this.billingInfo.lastName = this.userService.user.lastName;
      this.userService.getAddresses().subscribe({
        next: (addresses: any) => {
          if (addresses && addresses.length) {
            let bestAddress = addresses.find((a) => a.zipcode);
            if (bestAddress) {
              this.hasSavedAddress = true;
              this.billingInfo.address = bestAddress.street;
              this.billingInfo.city = bestAddress.city;
              this.billingInfo.state = bestAddress.state;
              this.billingInfo.zip = bestAddress.zipcode;
              this.billingInfo.country = bestAddress.countryCode;
            }
          }
          if (!this.billingInfo.zip && this.userService.user?.stripe_customer?.address) {
            this.billingInfo.zip = this.userService.user.stripe_customer.address.postal_code;
            this.billingInfo.state = this.userService.user.stripe_customer.address.state;
            this.billingInfo.city = this.userService.user.stripe_customer.address.city;
            this.billingInfo.country = this.userService.user.stripe_customer.address.country;
            this.billingInfo.address = this.userService.user.stripe_customer.address.line1;
            this.userService
              .saveAddress({
                street: this.billingInfo.address,
                city: this.billingInfo.city,
                state: this.billingInfo.state,
                zipcode: this.billingInfo.zip,
                countryCode: this.billingInfo.country,
              })
              .subscribe();
          }
          resolve(true);
        },
        error: (err) => {
          resolve(true);
        },
      });
    });
  }

  getCouponCode(code) {
    return this.http.get(environment.apiUrl + "stripe/coupon_code/" + code, this._helperService.getHttpOptions());
  }

  allFieldsPresent() {
    const requiredFields = ["firstName", "lastName", "address", "city", "state", "zip", "country"];
    return requiredFields.every((field) => !!this.billingInfo[field]);
  }

  loadPrices(prices, data, applyCouponParam = true) {
    this.priceList = prices;
    this.loadDefaultPrices(true);
    if (!this.hasSavedAddress || !this.billingInfo.country) this.billingInfo.country = data.geo["country-code"] || "US";
    this.supportedCurrencies = data.supported_currencies || [];
    this.activeCurrency = this.replaceCurrency(data.geo["currency-code"] ? data.geo["currency-code"] : "USD");
    return new Promise((resolve, reject) => {
      this.loadPriceByCountry().then((result) => {
        if (applyCouponParam) this.applyCouponParam();
        resolve(true);
      });
    });
  }

  handleOffersAndCoupons() {
    if (environment.features.active_offers && !this.coupons) {
      if (this.offersRetrieved) {
        this.displayDiscountedOfferPrice();
      } else {
        this.getActiveOffers();
      }
    } else {
      this.applyHardwareCoupon();
    }
    if (this.couponParam && this.isValidCheckout) {
      this.coupon = this.couponParam;
    }
  }

  loadGenericProductPrices(stripeProductId) {
    return this.userService.getGenericProductPrices(stripeProductId).pipe(
      map((result) => {
        return result;
      })
    );
  }

  loadS1PlusPrices(term = "monthly") {
    this.loadingPrices = true;
    return new Promise((resolve, reject) => {
      this.userService.getSpherePricesStripe().subscribe({
        next: (result: any) => {
          if (result.success && result.data && result.data.prices) {
            let subscriptionPrices = result.data.prices.filter((p) => p.product === environment.stripe.subscription_product.monthly || p.product === environment.stripe.subscription_product.annual);
            let annualScheduleUpradePrices = result.data.prices.filter((p) => p.product === environment.stripe.subscription_product.hybrid_upgrade);
            this.loadPrices([...subscriptionPrices, ...annualScheduleUpradePrices], result.data, false).then(() => {
              if (this.s1PlusPrices?.annual?.price) {
                this.activeCurrency = this.s1PlusPrices.annual.currency;
                this.monthlyDisplayPrice = this.s1PlusPrices.monthly.price;
                this.annualDisplayPrice = this.s1PlusPrices.annual.price;
                this.hybridUpgradePrice = this.s1PlusPrices.hybrid_upgrade.price;
                this.annualMonthlyDisplayPrice = (this.s1PlusPrices.annual.price - this.s1PlusPrices.annual.tax) / 12;
              }
              this.selectPlan(term, true);
              resolve(result.data);
            });
          }
        },
      });
    });
  }

  loadPriceByCountry(country?) {
    return new Promise((resolve, reject) => {
      this.loadingPrices = true;
      this.taxCalculated = undefined;
      if (country) this.billingInfo.country = country;
      this.userService.getCurrencyCodeByCountryCode(this.billingInfo.country).subscribe((result: any) => {
        if (result.data && result.data.locations && result.data.locations.length && result.data.locations[0]["country-code"].toLowerCase() === this.billingInfo.country.toLowerCase()) {
          this.activeCurrency = this.replaceCurrency(result.data.locations[0]["currency-code"]);
          //Filter from existing stripe prices
          let filteredPrices = this.priceList.filter((p) => p.currency.toLowerCase() === this.activeCurrency.toLowerCase());
          if (filteredPrices && filteredPrices.length) {
            this.currencyIsConverted = false;
            if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
              // this sets the studio one+ prices objects (this.s1PlusPrices) with the monthly and annual prices
              filteredPrices.map((price) => {
                if (price.recurring?.interval === "year" || price.recurring?.interval === "month") {
                  this.setS1PlusPrices(
                    price.recurring.interval === "month" ? "monthly" : "annual",
                    price.id,
                    this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100,
                    price.currency
                  );
                } else {
                  this.setS1PlusPrices(null, price.id, this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100, price.currency);
                }
              });
            } else if (this.selectedProductUpgrade) {
              /**
               * this sets the selected product to the price for the user's country
               * INFO: If this is the product to upgrade
               */
              const price = filteredPrices.find((p) => p.product === this.selectedProductUpgrade.stripeProductId);
              this.setSelectedProduct(this.selectedProductUpgrade, price.product, this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100, price.currency);
            } else if (this.genericProduct) {
              this.setSelectedProduct(
                this.genericProduct,
                filteredPrices[0].product,
                this.isNoDecimalCurrency(filteredPrices[0].currency) ? filteredPrices[0].unit_amount : filteredPrices[0].unit_amount / 100,
                filteredPrices[0].currency
              );
            }
            this.handleOffersAndCoupons();
            this.setTotalAmountPayable();
            resolve(true);
          } else if (this.activeCurrency && this.isCurrencySupported(this.activeCurrency.toLowerCase())) {
            // Converting the price as price for current currency is not available
            this.convertCurrency().then(() => {
              resolve(true);
            });
          } else {
            this.loadDefaultPrices();
            resolve(true);
          }
        } else {
          this.activeCurrency = "USD";
          this.loadingPrices = false;
          this.loadDefaultPrices();
          resolve(true);
        }
      });
    });
  }

  convertCurrency() {
    return new Promise((resolve, reject) => {
      let basePrices = this.priceList.filter((p) => p.currency === "usd");
      let currencyData = {
        currency_code: this.activeCurrency,
        convert: [],
      };
      if (basePrices && basePrices.length) {
        basePrices.map((price) => {
          currencyData.convert.push({
            from: "usd",
            value: (price.unit_amount / 100).toFixed(2),
            [price.recurring && price.recurring.interval ? "interval" : "product"]: price.recurring && price.recurring.interval ? price.recurring.interval : price.product,
          });
        });

        this.userService.currencyConvert(currencyData).subscribe((result: any) => {
          if (result.success && result.data.every((c) => c.valid === true)) {
            this.currencyIsConverted = true;
            if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
              result.data.map((price) => {
                if (price.interval === "year" || price.interval === "month") {
                  this.setS1PlusPrices(
                    price.interval === "month" ? "monthly" : "annual",
                    "converted_" + price.interval,
                    this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                    price["to-type"]
                  );
                } else {
                  this.setS1PlusPrices(
                    null,
                    "converted_" + price.id,
                    this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                    price["to-type"]
                  );
                }
              });
            } else if (this.selectedProductUpgrade) {
              const filteredProductPrice = result.data.find((p) => p.product === this.selectedProductUpgrade.stripeProductId);
              this.setSelectedProduct(
                this.selectedProductUpgrade,
                "converted_" + filteredProductPrice.product,
                this.isNoDecimalCurrency(filteredProductPrice["to-type"]) ? parseInt(filteredProductPrice["result-float"]) : Number(filteredProductPrice["result-float"].toFixed(2)),
                filteredProductPrice["to-type"]
              );
            } else if (this.genericProduct) {
              const filteredProductPrice = result.data.find((p) => p.product === this.genericProduct.stripeProductId);
              this.setSelectedProduct(
                this.genericProduct,
                "converted_" + filteredProductPrice.product,
                this.isNoDecimalCurrency(filteredProductPrice["to-type"]) ? parseInt(filteredProductPrice["result-float"]) : Number(filteredProductPrice["result-float"].toFixed(2)),
                filteredProductPrice["to-type"]
              );
            }
            this.handleOffersAndCoupons();
            this.loadingPrices = false;
            this.clientSecret = "";
            this.subscriptionId = "";
          } else {
            this.loadingPrices = false;
            this.loadDefaultPrices();
          }
          this.setTotalAmountPayable();
          resolve(true);
        });
      } else {
        this.loadingPrices = false;
        this.setTotalAmountPayable();
        resolve(true);
      }
    });
  }

  loadDefaultPrices(init = false) {
    let basePrices = this.priceList.filter((p) => p.currency === "usd");
    this.currencyIsConverted = false;
    if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
      basePrices.map((price) => {
        if (price.recurring?.interval === "year" || price.recurring?.interval === "month") {
          this.setS1PlusPrices(price.recurring.interval === "month" ? "monthly" : "annual", price.id, price.unit_amount / 100, price.currency);
        } else {
          this.setS1PlusPrices(null, price.id, price.unit_amount / 100, price.currency);
        }
      });
    } else if (this.selectedProductUpgrade) {
      let filteredProductPrice = basePrices.find((p) => p.product === this.selectedProductUpgrade.stripeProductId);
      this.setSelectedProduct(
        this.selectedProductUpgrade,
        filteredProductPrice.product,
        this.isNoDecimalCurrency(filteredProductPrice.currency) ? filteredProductPrice.unit_amount : filteredProductPrice.unit_amount / 100,
        filteredProductPrice.currency
      );
    } else if (this.genericProduct) {
      this.setSelectedProduct(
        this.genericProduct,
        basePrices[0].product,
        this.isNoDecimalCurrency(basePrices[0].currency) ? basePrices[0].unit_amount : basePrices[0].unit_amount / 100,
        basePrices[0].currency
      );
    }

    if (!init) {
      this.handleOffersAndCoupons();
      this.setTotalAmountPayable();
    }
  }

  loadCountryOfCard(paymentMethod?, card?, channel?) {
    this.loadingPrices = true;
    if (paymentMethod) {
      this.cardCountry = paymentMethod.card.country;
      //If card country doesn't match with user location
      if (this.billingInfo.country && this.billingInfo.country !== paymentMethod.card.country) {
        this.setAddressBySelectedPaymentMethod(paymentMethod);
        this.loadPriceByCountry(this.billingInfo.country);
      } else {
        this.setAddressBySelectedPaymentMethod(paymentMethod);
        if (!this.activeCurrency) {
          this.userService.getCurrencyCodeByCountryCode(paymentMethod.card.country).subscribe((result: any) => {
            if (result.data && result.data.locations && result.data.locations.length && result.data.locations[0]["country-code"].toLowerCase() === this.billingInfo.country.toLowerCase()) {
              this.activeCurrency = result.data.locations[0]["currency-code"];
              this.calculateFinalAmount();
            }
          });
        } else {
          this.calculateFinalAmount();
        }
      }
    } else {
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: { name: this.billingInfo.firstName + " " + this.billingInfo.lastName },
          metadata: { sales_channel: channel },
        })
        .then((response) => {
          if (response.paymentMethod) {
            this.cardCountry = response.paymentMethod.card.country;
            this.newPaymentMethod = response.paymentMethod.id;
            if (this.couponParam) {
              this.coupon = this.couponParam;
              this.applyCoupon();
            }
            if (this.billingInfo.country !== response.paymentMethod.card.country) {
              //If card country doesn't match with user location
              this.billingInfo.country = response.paymentMethod.card.country;
              this.loadPriceByCountry(this.billingInfo.country);
            } else {
              this.loadingPrices = false;
              this.calculateFinalAmount();
            }
          } else {
            this.loadingPrices = false;
          }
        })
        .catch((err) => {
          this.loadingPrices = false;
        });
    }
  }

  calculateFinalAmount() {
    if ((this.coupon || this.couponParam) && this.isValidCheckout) {
      if (this.couponParam) {
        this.coupon = this.couponParam;
        this.showCouponField = true;
      }
    }
    this.setChargedMessage();
    this.setTotalAmountPayable();
    this.win.setTimeout(() => {
      this.applyCoupon();
    }, 1000);
  }

  setChargedMessage() {
    if (this.scheduleDetails && !this.s1PlusPrices) return;
    this.isTaxIncluded = this.currencyIsConverted || this.billingInfo.country === "US" ? false : true;
    let selectedPrice = this.selectedPrice?.price || this.selectedProduct?.price;
    if (this.scheduleDetails) {
      selectedPrice = this.s1PlusPrices.hybrid_upgrade.price;
    }
    if (this.billingInfo && this.billingInfo.zip && selectedPrice) {
      let amount = (this.couponData ? selectedPrice - this.couponData["coupons-total"] : selectedPrice).toFixed(2);
      if (this.scheduleDetails) amount = this.s1PlusPrices.hybrid_upgrade.price.toFixed(2);
      let taxData = {
        lines: [
          {
            number: 1,
            quantity: 1,
            amount: amount,
            taxCode: environment.tax.tax_code,
            taxIncluded: this.isTaxIncluded,
          },
        ],
        date: new Date().toISOString().split("T")[0],
        customerCode: environment.tax.customer_code,
        address: {
          line1: this.billingInfo.address,
          city: this.billingInfo.city,
          region: this.billingInfo.state,
          country: this.billingInfo.country,
          postalCode: this.billingInfo.zip,
        },
        currencyCode: this.activeCurrency,
      };
      if (
        (this.billingInfo.country == "US" && !this.billingInfo.state) || // if they havent chosen a state, dont bother calculating tax yet.
        (this.billingInfo.country == "US" && !this.userService.stateMap[this.billingInfo.state]) // dont calculate tax if their chosen state is not in the US.
      ) {
        return;
      }
      if (this.allFieldsPresent()) {
        this.loadingPrices = true;
        this.calculatingTax = true;
        if (this.scheduleDetails && this.selectedS1PlusPlan == "monthly") {
          this.taxCalculated = 0;
          this.totalCharge = 0;
          this.loadingPrices = false;
          this.calculatingTax = false;
          this.setTotalAmountPayable();
        } else {
          this.userService.calculateTax(taxData).subscribe({
            next: (result: any) => {
              this.calculatingTax = false;
              if (result) {
                this.taxedBillingInfo = { ...this.billingInfo };
                this.taxCalculated = result.totalTaxCalculated;
                if (!this.isTaxIncluded) {
                  this.totalCharge = Number((selectedPrice + this.taxCalculated).toFixed(2));
                } else {
                  this.totalCharge = 0;
                }
                this.loadingPrices = false;
                this.setTotalAmountPayable();
              }
            },
            error: (error) => {
              this.calculatingTax = false;
              this.taxCalculated = 0;
              this.loadingPrices = false;
              this.setTotalAmountPayable();
              let errorMessage = this._helperService.retrieveErrorMessage(error);
              if (errorMessage == "Address not geocoded.") {
                this.checkoutError = { type: "billing_info", message: "Please double check your billing address." };
              }
            },
          });
        }
      } else {
        this.setTotalAmountPayable();
        this.loadingPrices = false;
      }
    } else {
      this.setTotalAmountPayable();
      this.loadingPrices = false;
    }
  }

  setAddressBySelectedPaymentMethod(paymentMethod) {
    if (this.stripeCustomerAddress) {
      this.billingInfo.firstName = paymentMethod.billing_details.name?.split(" ")[0];
      this.billingInfo.lastName = paymentMethod.billing_details.name?.split(" ")[1];
      this.billingInfo.address = this.stripeCustomerAddress.line1;
      this.billingInfo.country = this.stripeCustomerAddress.country;
      this.billingInfo.city = this.stripeCustomerAddress.city;
      this.billingInfo.state = this.stripeCustomerAddress.state;
      this.billingInfo.zip = this.stripeCustomerAddress.postal_code;
    }
  }

  selectPlan(plan, initalSelect?) {
    if ((this.s1PlusPrices && plan !== this.selectedS1PlusPlan) || initalSelect) {
      this.selectedS1PlusPlan = plan;
      this.selectedPrice = this.s1PlusPrices[this.selectedS1PlusPlan];
      this.clientSecret = "";
      this.subscriptionId = "";
      this.totalCharge = 0;
      this.taxCalculated = 0;
      this.couponData = null;
      if (this.coupons && this.coupons.length) {
        this.applyHardwareCoupon(true, plan);
      } else if (this.isAutoOfferApplicable()) {
        this.applyActiveOffer(true);
        this.displayDiscountedOfferPrice();
      } else {
        this.applyCouponParam();
      }

      if (!this.isAutoOfferApplicable() && this.selectedActiveOffer) {
        // remove the selected active offer if it doesnt apply to this plan.
        this.removeActiveOffer();
      }

      this.calculateFinalAmount();
    }
  }

  removeActiveOffer() {
    this.discountedMonthlyDisplayPrice = undefined;
    this.discountedAnnualMonthlyDisplayPrice = undefined;
    this.discountedAnnualDisplayPrice = undefined;
    this.removeCoupon();
  }

  getDiscountedPrice(discountData) {
    if (!this.billingInfo.country) this.billingInfo.country = "US";
    let args: any = {
      term: discountData.term,
      currency: this.activeCurrency,
      country: this.billingInfo.country.toUpperCase(),
      coupon: discountData.coupon_code,
    };
    if (this.billingInfo.country.toUpperCase() == "US" && this.billingInfo.state) args.state = this.billingInfo.state.toUpperCase();
    this.loadingPrices = true;
    this.userService.getCartPrice(args).subscribe((result: any) => {
      this.loadingPrices = false;
      discountData.data = result.data;
    });
  }

  applyCoupon(promoCode?) {
    this.checkoutError = undefined;
    if (this.coupon || promoCode) {
      let code = promoCode ? promoCode : this.coupon;
      let selectedPrice = this.getSelectedPrice();
      let coupon: any = {
        ...(this.selectedProduct && { type: "manual_inovice" }),
        promotion_code: code,
        address: {
          line1: this.billingInfo.address,
          city: this.billingInfo.city,
          state: this.billingInfo.state,
          country: this.billingInfo.country,
          postal_code: this.billingInfo.zip,
        },
      };
      if (this.currencyIsConverted || this.selectedPrice?.id.includes("converted")) {
        const currencyHere = selectedPrice?.currency ?? this.selectedProduct.currency;
        const priceHere = selectedPrice?.price ?? this.selectedProduct.price;
        coupon.price_data = {
          ...(!this.selectedProduct && {
            recurring: {
              interval: this.selectedS1PlusPlan.includes("annual") ? "year" : "month",
            },
          }),
          product: this.selectedProduct ? this.selectedProduct : environment.stripe.subscription_product[this.selectedS1PlusPlan],
          //currency: selectedPrice.currency ? selectedPrice.currency : this.selectedProduct.currency,
          currency: currencyHere,
          //unit_amount: this.isNoDecimalCurrency(selectedPrice.currency ? selectedPrice.currency : this.selectedProduct.currency) && !this.isDivisibleBy100(selectedPrice.currency ? selectedPrice.currency : this.selectedProduct.currency) ? selectedPrice.price ? selectedPrice.price: this.selectedProduct.price : parseInt((selectedPrice.price ? selectedPrice.price: this.selectedProduct.price * 100).toFixed(0)),
          unit_amount: this.isNoDecimalCurrency(currencyHere) && !this.isDivisibleBy100(currencyHere) ? priceHere : parseInt((priceHere * 100).toFixed(0)),
          tax_behavior: "unspecified",
        };
      } else {
        coupon.price_id = this.getSelectedPrice().id;
      }

      this.applyingCoupon = true;
      this.loadingPrices = true;
      this.userService.validateCoupon(coupon).subscribe({
        next: (result: any) => {
          this.applyingCoupon = false;
          this.loadingPrices = false;
          if (result.success && result.data) {
            let total = 0;
            if (result.data.total_discount_amounts[0] && result.data.total_discount_amounts[0].amount > 0) {
              if (this.isNoDecimalCurrency(result.data.currency) && !this.isDivisibleBy100(result.data.currency)) {
                total = result.data.total_discount_amounts[0].amount;
              } else {
                total = Number((result.data.total_discount_amounts[0].amount / 100).toFixed(2));
              }
            }
            // this.selectedActiveOffer = undefined;
            this.couponData = {
              "charged-currency": result.data.currency,
              "coupons-total": total,
              "coupon-id": result.data.discount?.coupon?.id,
              data: result.data,
            };
            if (this.discountSuccessMessage) {
              this.discountSuccessMessage = this.discountSuccessMessage.replace(
                "{amount}",
                this.currencyPipe.transform(this.couponData["coupons-total"], this.couponData["charged-currency"].toUpperCase())
              );
            }
            this.setChargedMessage();
          } else {
            this.coupon = undefined;
            this.checkoutError = { type: "coupon_error", message: "Coupon not found." };
          }
        },
        error: (error) => {
          let message = error ? this._helperService.retrieveErrorMessage(error) : "Coupon not found.";
          if (this.couponParam) message = 'The coupon "' + code + '" does not apply to the selected product.';
          this.checkoutError = { type: "coupon_error", message: message };
          this.applyingCoupon = false;
          this.loadingPrices = false;
          this.coupon = undefined;
        },
      });
    }
  }

  applyHardwareCoupon(conditionChecked = false, selectedPlan = this.selectedS1PlusPlan) {
    if (conditionChecked || (this.coupons && this.coupons.length)) {
      let yearCoupons = this.coupons.filter((cpn) => cpn.toLocaleLowerCase().includes("annual"));
      let monthCoupons = this.coupons.filter((cpn) => cpn.toLocaleLowerCase().includes("monthly"));
      if (yearCoupons && yearCoupons.length) {
        let splitYearCoupons = yearCoupons[0].split("_");
        if (splitYearCoupons && splitYearCoupons.length) {
          let discountPercentYear = splitYearCoupons[splitYearCoupons.length - 1];
          if (discountPercentYear) {
            this.discountedAnnualDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - parseInt(discountPercentYear))) / 100).toFixed(2);
            this.discountedAnnualMonthlyDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - parseInt(discountPercentYear))) / 100 / 12).toFixed(2);
          }
        }
      }
      if (monthCoupons && monthCoupons.length) {
        let splitMonthCoupons = monthCoupons[0].split("_");
        if (splitMonthCoupons && splitMonthCoupons.length) {
          let discountPercentMonth = splitMonthCoupons[splitMonthCoupons.length - 1];
          if (discountPercentMonth) {
            this.discountedMonthlyDisplayPrice = ((this.s1PlusPrices["monthly"].price * (100 - parseInt(discountPercentMonth))) / 100).toFixed(2);
          }
        }
      }
      let selectedCoupon = this.coupons.filter((cpn) => cpn.toLocaleLowerCase().includes(selectedPlan.toLocaleLowerCase()));
      if (selectedCoupon && selectedCoupon.length) {
        this.coupon = selectedCoupon[0].replace(/_/g, "");
      }
    }
  }

  displayDiscountedOfferPrice() {
    if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
      let selectedCoupon = this.activeOffers
        .filter(
          (cpn) =>
            cpn.applies_to_products &&
            (cpn.applies_to_products.includes(environment.stripe.subscription_product.annual) || cpn.applies_to_products.includes(environment.stripe.subscription_product.monthly))
        )
        .sort((a, b) => a.priority - b.priority);
      if (selectedCoupon && selectedCoupon.length) {
        this.selectedActiveOffer = selectedCoupon[0];
        this.applicableActiveOffer = selectedCoupon[0];

        let startDate = selectedCoupon[0].start_date;
        let endDate = selectedCoupon[0].end_date;
        let currentDate = moment().unix();
        if (selectedCoupon[0].percentage > 0) {
          let yearCoupons = selectedCoupon.filter((cp) => cp.applies_to_products.includes(environment.stripe.subscription_product.annual));
          let monthCoupons = selectedCoupon.filter((cp) => cp.applies_to_products.includes(environment.stripe.subscription_product.monthly));
          if (selectedCoupon[0].coupon_type === "date_range") {
            if (currentDate > startDate && (currentDate < endDate || !endDate)) {
              if (yearCoupons && yearCoupons.length) {
                this.discountedAnnualDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100).toFixed(2);
                this.applicableActiveOffer.discountedAnnualDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100).toFixed(2);
                this.discountedAnnualMonthlyDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100 / 12).toFixed(2);
                this.applicableActiveOffer.discountedAnnualMonthlyDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100 / 12).toFixed(2);
              }
              if (monthCoupons && monthCoupons.length) {
                this.discountedMonthlyDisplayPrice = ((this.s1PlusPrices["monthly"].price * (100 - monthCoupons[0].percentage)) / 100).toFixed(2);
                this.applicableActiveOffer.discountedMonthlyDisplayPrice = ((this.s1PlusPrices["monthly"].price * (100 - monthCoupons[0].percentage)) / 100).toFixed(2);
              }
            }
          } else {
            if (yearCoupons && yearCoupons.length) {
              this.discountedAnnualDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100).toFixed(2);
              this.applicableActiveOffer.discountedAnnualDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100).toFixed(2);
              this.discountedAnnualMonthlyDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100 / 12).toFixed(2);
              this.applicableActiveOffer.discountedAnnualMonthlyDisplayPrice = ((this.s1PlusPrices["annual"].price * (100 - yearCoupons[0].percentage)) / 100 / 12).toFixed(2);
            }
            if (monthCoupons && monthCoupons.length) {
              this.discountedMonthlyDisplayPrice = ((this.s1PlusPrices["monthly"].price * (100 - monthCoupons[0].percentage)) / 100).toFixed(2);
              this.applicableActiveOffer.discountedMonthlyDisplayPrice = ((this.s1PlusPrices["monthly"].price * (100 - monthCoupons[0].percentage)) / 100).toFixed(2);
            }
          }
        }
      }
    }
  }

  applyActiveOffer(conditionChecked = false) {
    if (environment.features.active_offers && (conditionChecked || (this.activeOffers && this.activeOffers.length)) && this.selectedS1PlusPlan) {
      let selectedCoupon = this.activeOffers
        .filter((cpn) => {
          if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
            return cpn.applies_to_products && cpn.applies_to_products.includes(environment.stripe.subscription_product[this.selectedS1PlusPlan]);
          } else {
            return cpn.applies_to_products && cpn.applies_to_products.includes(this.selectedProduct.stripeProductId);
          }
        })
        .sort((a, b) => a.priority - b.priority);
      if (selectedCoupon && selectedCoupon.length) {
        this.selectedActiveOffer = selectedCoupon[0];
        let startDate = selectedCoupon[0].start_date;
        let endDate = selectedCoupon[0].end_date;
        let currentDate = moment().unix();
        if (selectedCoupon[0].coupon_type === "date_range") {
          if (currentDate > startDate && currentDate < endDate) {
            this.coupon = atob(selectedCoupon[0].code); //Trasnforming to normal coupon string
          }
        } else {
          this.coupon = atob(selectedCoupon[0].code); //Trasnforming to normal coupon string
        }
        this.couponApplied = {
          code: this.coupon,
          discount_percent: selectedCoupon[0].percentage,
          coupon_id: selectedCoupon[0].coupon_id,
          amount_off:
            this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+"
              ? (this.s1PlusPrices[this.selectedS1PlusPlan].price * selectedCoupon[0].percentage) / 100
              : (this.selectedProduct.price * selectedCoupon[0].percentage) / 100,
          title: selectedCoupon[0].display_message,
        };
        // this.couponData is set and calculated correctly in applyCoupon.
        // When auto-apply is set that couponData isn't set set after an address is entered
        // the tax calculation is done off the non-discounted price as opposed of the discounted price
        // I think this is a temporaty fix as I am having to add promo_code to the coupon metadata.
        // we might have to re-evaluate later.
        this.applyCoupon(selectedCoupon[0].promo_code);
      }
    }
    this.setTotalAmountPayable();
  }

  public isAutoOfferApplicable() {
    if (this.activeOffers && this.activeOffers.length && this.selectedS1PlusPlan) {
      let selectedCoupon = this.activeOffers
        .filter((cpn) => cpn.applies_to_products && cpn.applies_to_products.includes(environment.stripe.subscription_product[this.selectedS1PlusPlan]))
        .sort((a, b) => a.priority - b.priority);
      return selectedCoupon && selectedCoupon.length > 0 ? true : false;
    }
    return false;
  }

  applyCouponParam() {
    if (this.couponParam) this.applyCoupon(this.couponParam);
  }

  removeCoupon() {
    this.couponData = undefined;
    this.coupon = "";
    this.discountSuccessMessage = "";
    this.showCouponField = false;
    this.selectedActiveOffer = undefined;
    this.setChargedMessage();
    this.couponApplied = undefined;
  }

  setS1PlusPrices(term, id, price, currency) {
    if (!this.s1PlusPrices) this.s1PlusPrices = {};
    if (term === "annual") {
      this.s1PlusPrices.annual = {
        id: id,
        price: price,
        currency: currency,
        formatted_price: null,
        tax: null,
        tax_rate: null,
        productId: environment.product_ids.sphere_annual,
        title: "Studio One Pro+ (Annual)",
        stripeProductId: environment.stripe.subscription_product.annual,
        image: environment.features.studio_one_seven ? "./assets/images/s17-images/s17-bundle-annual.png" : "./assets/images/presonus-sphere-bundle.png",
      };
    } else if (term === "monthly") {
      this.s1PlusPrices.monthly = {
        id: id,
        price: price,
        currency: currency,
        formatted_price: null,
        tax: null,
        tax_rate: null,
        productId: environment.product_ids.sphere_monthly,
        title: "Studio One Pro+ (Monthly)",
        stripeProductId: environment.stripe.subscription_product.monthly,
        image: environment.features.studio_one_seven ? "./assets/images/s17-bundle-monthly.png" : "./assets/images/presonus-sphere-bundle.png",
      };
    } else {
      this.s1PlusPrices.hybrid_upgrade = {
        id: id,
        price: price,
        currency: currency,
        formatted_price: null,
        tax: null,
        tax_rate: null,
        // productId: environment.product_ids.sphere_monthly,
        title: "Studio One Pro+ Annual Upgrade",
        stripeProductId: environment.stripe.subscription_product.hybrid_upgrade,
        image: environment.features.studio_one_seven ? "./assets/images/s17-images/s17-bundle-annual.png" : "./assets/images/presonus-sphere-bundle.png",
      };
    }
    this.selectedPrice = this.s1PlusPrices[this.selectedS1PlusPlan];
    if (this.s1PlusPrices.annual && this.s1PlusPrices.monthly) this.setTotalAmountPayable();
  }

  setSelectedProduct(product, stripeProductId, price, currency) {
    this.selectedProduct = {
      id: stripeProductId,
      price: price,
      currency: currency,
      formatted_price: null,
      tax: null,
      tax_rate: null,
      title: product.title,
      productId: product.id,
      upgradeProductId: product.toPackage_id,
      stripeProductId: product.stripeProductId,
    };
    this.totalCharge = undefined;
    this.couponData = undefined;
    this.applyActiveOffer();
  }

  getActiveOffers() {
    this.loadingPrices = true;
    this.userService.getActiveOffers().subscribe({
      next: (result: any) => {
        this.loadingPrices = false;
        if (result && result.success && result.data && result.data.length) {
          this.activeOffers = result.data;
          this.offersRetrieved = true;
          this.displayDiscountedOfferPrice();
          this.applyActiveOffer(true);
        }
      },
      error: (error: any) => {
        this.applyingCoupon = false;
        this.loadingPrices = false;
        this.coupon = undefined;
      },
    });
  }

  public isCurrencySupported(currency) {
    if (this.supportedCurrencies && this.supportedCurrencies.length && currency) {
      return this.supportedCurrencies.includes(currency);
    }
    return false;
  }

  addCardToCustomer(setCardAsSubscriptionPaymentMethod?) {
    return new Promise((resolve, reject) => {
      let paymentMethod = {
        address: {
          line1: this.billingInfo.address,
          city: this.billingInfo.city,
          state: this.billingInfo.state,
          country: this.billingInfo.country,
          postal_code: this.billingInfo.zip,
        },
        is_tax_inclusive: this.isTaxIncluded,
        test_clock: environment.stripe.test_clock,
      };
      if (this.newPaymentMethod) {
        this.userService.getCardClientSecret(paymentMethod).subscribe({
          next: (result: any) => {
            if (result?.success && result.data) {
              this.clientSecret = result.data.client_secret;

              this.stripe
                .confirmCardSetup(result.data.client_secret, {
                  payment_method: this.newPaymentMethod,
                })
                .then((response) => {
                  if (response.setupIntent) {
                    if (setCardAsSubscriptionPaymentMethod) {
                      this.userService.setDefaultPaymentMethod(response.setupIntent.payment_method).subscribe({
                        next: () => {
                          resolve(response.setupIntent.payment_method);
                        },
                        error: (error) => {
                          reject(new Error(error?.message || "Failed to set default payment method."));
                        },
                      });
                    } else {
                      resolve(response.setupIntent.payment_method);
                    }
                  } else if (response.error) {
                    this.checkoutError = {
                      type: "add_card",
                      message: response.error.message,
                    };
                    reject(new Error(response.error.message || "Unknown setup error."));
                  }
                })
                .catch((error) => {
                  this.checkoutError = {
                    type: "add_card",
                    message: error.message,
                  };
                  reject(new Error(error?.message || "Failed to confirm card setup."));
                });
            } else {
              reject(new Error("Failed to retrieve card client secret."));
            }
          },
          error: (error) => {
            this.checkoutError = {
              type: "add_card",
              message: error?.message || "Failed to get card client secret.",
            };
            reject(new Error(error?.message || "Failed to get card client secret."));
          },
        });
      } else {
        reject(new Error("Please enter your credit card information."));
      }
    });
  }

  public replaceCurrency(currency) {
    if (environment.stripe.replace_currency && currency) {
      let replacedCurrency = environment.stripe.replace_currency.find((cur) => cur.from.toLocaleLowerCase() === currency.toLocaleLowerCase());
      if (replacedCurrency) {
        return replacedCurrency.to;
      }
    }
    return currency;
  }

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDifferentAddress() {
    if (this.taxedBillingInfo) {
      return Object.keys(this.billingInfo).some((key) => this.billingInfo[key] !== this.taxedBillingInfo[key]);
    }
    return true;
  }

  get isValidCheckout() {
    if (this.newPaymentMethod || this.selectedExistingPaymentMethod) {
      return true;
    } else {
      let isValid = Object.values(this.ccInfo).every((v) => v === true) && Object.values(this.billingInfo).every((b: string) => !!b && !!b.trim());
      return isValid;
    }
  }

  public setTotalAmountPayable() {
    let basePrice;
    let discount = 0;
    if (this.scheduleDetails) {
      if (this.selectedS1PlusPlan == "monthly" || this.userService.subDetails?.subscription?.subscription_levels_id == 2) {
        basePrice = 0;
      } else {
        basePrice = this.totalCharge && !this.isTaxIncluded ? this.totalCharge : this.s1PlusPrices ? this.s1PlusPrices.hybrid_upgrade.price : 0;
      }
    } else {
      if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
        basePrice = this.totalCharge && !this.isTaxIncluded ? this.totalCharge : this.s1PlusPrices ? this.s1PlusPrices[this.selectedS1PlusPlan].price : 0;
      } else {
        basePrice = (this.totalCharge && !this.isTaxIncluded ? this.totalCharge : this.selectedProduct?.price) || 0;
      }
      if (this.couponData) {
        discount = this.couponData["coupons-total"];
      } else if (this.couponApplied && this.couponApplied.amount_off > 0) {
        discount = this.couponApplied.amount_off;
      }
    }
    let amt = Number((basePrice - discount).toFixed(2));
    this.total = amt;
    return amt;
  }

  performScheduleSubscription(term) {
    this.percentDone = 33;
    this.loadingMsg = "Saving your payment method...";
    //dont do this if they have and have chosen an existing payment method.
    const continueWithPaymentMethod = (paymentMethodId) => {
      this.loadingMsg = "Saving your user details...";
      this.percentDone = 68;
      this.userService.getUserDetails(false).subscribe(() => {
        let args: any = {
          customerId: this.userService.user.stripe_id,
          paymentMethodId: paymentMethodId,
          priceId: this.s1PlusPrices[this.selectedS1PlusPlan].id,
          startDate: this.userService.subDetails.subscription.end_date,
          userId: this.userService.user.id,
          taxCode: environment.tax.tax_code,
          isTaxInclusive: this.isTaxIncluded,
          presonusProductId: this.selectedS1PlusPlan == "monthly" ? environment.product_ids.sphere_monthly : environment.product_ids.sphere_annual,
          planType: this.selectedS1PlusPlan == "monthly" ? "Monthly" : "Hybrid",
          action: "createSchedule",
        };
        this.loadingMsg = "Scheduling your subscription...";
        this.percentDone = 85;
        if (this.currencyIsConverted) {
          delete args.priceId;
          let s1PlusPriceKey = "monthly";
          if (this.selectedProduct?.stripeProductId == environment.stripe.subscription_product.hybrid_upgrade) s1PlusPriceKey = "annual";
          let priceData = {
            product: this.s1PlusPrices[s1PlusPriceKey].stripeProductId,
            currency: this.s1PlusPrices[s1PlusPriceKey].currency,
            unit_amount:
              this.isNoDecimalCurrency(this.s1PlusPrices[s1PlusPriceKey].currency) && !this.isDivisibleBy100(this.s1PlusPrices[s1PlusPriceKey].currency)
                ? this.s1PlusPrices[s1PlusPriceKey].price
                : parseInt((this.s1PlusPrices[s1PlusPriceKey].price * 100).toFixed(0)),
            tax_behavior: "unspecified",
            recurring: {
              interval: s1PlusPriceKey == "annual" ? "year" : "month",
            },
          };
          args.priceData = priceData;
        }
        this.createScheduledSubscription(args).subscribe((result) => {
          this.loadingMsg = "Baking cookies...";
          this.percentDone = 97;
          this.userService.getSubscriptionDetails(true).subscribe((result) => {
            this.loadingCheckout = false;
            this.checkoutComplete$.next({
              product: "Studio One Pro+",
            });
          });
        });
      });
    };
    if (this.selectedExistingPaymentMethod) {
      continueWithPaymentMethod(this.selectedExistingPaymentMethod);
    } else {
      this.addCardToCustomer().then((paymentMethodId) => {
        continueWithPaymentMethod(paymentMethodId);
      });
    }
  }

  checkOut() {
    if (!this.cardCountry || this.cardCountry === this.billingInfo.country) {
      if (this.isValidCheckout) {
        if (this.scheduleDetails) {
          this.loadingCheckout = true;
          if (this.selectedS1PlusPlan == "monthly") {
            this.performScheduleSubscription("monthly");
          } else {
            if (this.userService.subDetails.subscription.subscription_levels_id == 2) {
              this.performScheduleSubscription("annual");
            } else {
              // is paying for the annual upgrade.
              this.selectedProduct = this.s1PlusPrices.hybrid_upgrade;
              this.orderCompletedTrackingArgs = {
                siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
                quantity: 1,
                paymentMethod: "Credit Card",
                locale: this.billingInfo.country,
                prsId: this.userService.user.id,
                coupon: null,
                discount: null,
                tax: this.taxCalculated,
                total: this.total,
                subtotal: this.total - this.taxCalculated,
                currency: this.activeCurrency,
                currencyCode: this.activeCurrency,
                revenue: parseFloat((this.total - this.taxCalculated).toString()),
                price: parseFloat((this.total - this.taxCalculated).toString()),
              };
              this.purchaseComplete$.pipe(take(1)).subscribe((result) => {
                this.loadingMsg = "Updating your user information...";
                this.percentDone = 80;
                this.userService.getSubscriptionDetails(true).subscribe((result) => {
                  let args: any = {
                    customerId: this.userService.subDetails.stripe_customer.id,
                    paymentMethodId: this.userService.paymentMethods[0].id,
                    priceId: this.s1PlusPrices.annual.id,
                    startDate: this.scheduleDetails.annual_upgrade_subscription_start_date,
                    userId: this.userService.user.id,
                    taxCode: environment.tax.tax_code,
                    isTaxInclusive: this.isTaxIncluded,
                    bundleTimeIncrease: 6,
                    presonusProductId: environment.product_ids.sphere_annual,
                    planType: "Hybrid",
                    action: "createSchedule",
                  };
                  if (this.userService.subDetails.scheduled_stripe_subscription) args.cancelExistingScheduledSubscription = this.userService.subDetails.scheduled_stripe_subscription.id;
                  this.loadingMsg = "Scheduling your subscription...";
                  this.percentDone = 85;

                  if (this.currencyIsConverted) {
                    delete args.priceId;
                    let s1PlusPriceKey = "monthly";
                    if (this.selectedProduct?.stripeProductId == environment.stripe.subscription_product.hybrid_upgrade) s1PlusPriceKey = "annual";
                    let priceData = {
                      product: this.s1PlusPrices[s1PlusPriceKey].stripeProductId,
                      currency: this.s1PlusPrices[s1PlusPriceKey].currency,
                      unit_amount:
                        this.isNoDecimalCurrency(this.s1PlusPrices[s1PlusPriceKey].currency) && !this.isDivisibleBy100(this.s1PlusPrices[s1PlusPriceKey].currency)
                          ? this.s1PlusPrices[s1PlusPriceKey].price
                          : parseInt((this.s1PlusPrices[s1PlusPriceKey].price * 100).toFixed(0)),
                      tax_behavior: "unspecified",
                      recurring: {
                        interval: s1PlusPriceKey == "annual" ? "year" : "month",
                      },
                    };
                    args.priceData = priceData;
                  }
                  this.createScheduledSubscription(args).subscribe((result) => {
                    this.loadingMsg = "Baking cookies...";
                    this.percentDone = 97;
                    this.userService.getSubscriptionDetails(true).subscribe((result) => {
                      this.loadingCheckout = false;
                      this.checkoutComplete$.next({
                        product: "Studio One Pro+",
                      });
                    });
                  });
                });
                // now we schedule the annual subscription.
              });
              this.performPurchase("sphere_signup");
            }
          }
        } else {
          this.loadingCheckout = true;
          this.loadingMsg = "10% done...";
          this.percentDone = 10;
          this.loadingMsg = "Please wait while your payment is being processed...";
          let channel: any;
          channel = this.source ? "src_" + this.source : this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+" ? "sphere_signup" : "product_upgrade";
          if (this.clientSecret) {
            //Confirming subscription for existing subscription
            this.confirmCardPayment(channel);
          } else {
            let coupon = this.coupon;
            if (this.selectedActiveOffer) coupon = atob(this.selectedActiveOffer.code);
            let discount = 0;
            if (this.couponData) {
              discount = parseFloat(this.couponData["coupons-total"]);
            } else if (this.couponApplied) {
              discount = this.couponApplied.amount_off;
            }
            this.orderCompletedTrackingArgs = {
              siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
              quantity: 1,
              paymentMethod: "Credit Card",
              locale: this.billingInfo.country,
              prsId: this.userService.user.id,
              coupon: coupon,
              discount: discount,
              tax: this.taxCalculated,
              total: this.total,
              subtotal: this.total - this.taxCalculated,
              currency: this.activeCurrency,
              currencyCode: this.activeCurrency,
              revenue: parseFloat((this.total - this.taxCalculated).toString()),
              price: parseFloat((this.total - this.taxCalculated).toString()),
            };
            if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") {
              // if its a subscription, create the subscription.
              this.createSubscription(channel);
            } else {
              // if its not subscription, create the payment intent.
              this.performPurchase(channel);
            }
          }
        }
      } else {
        this.checkoutError = {
          type: "add_card",
          message: "Please fill out all required fields and double check your credit card info.",
        };
      }
    } else {
      this.checkoutError = {
        type: "add_card",
        message: "The card you selected does match the billing address on file. Please enter your credit card information again.",
      };
      this.addNewPaymentMethod();
    }
  }

  createScheduledSubscription(args) {
    return this.http.post(environment.paeApiUrl + `user/subscription/bundle-updates`, JSON.stringify(args), this._helperService.getHttpOptions());
  }

  cancelScheduledSubscription(args) {
    return this.http.post(environment.paeApiUrl + `user/subscription/bundle-updates`, JSON.stringify({ action: "cancelSchedule", ...args }), this._helperService.getHttpOptions());
  }

  cancelSubscription(args) {
    return this.http.post(environment.paeApiUrl + `user/subscription/bundle-updates`, JSON.stringify({ action: "cancelSubscription", ...args }), this._helperService.getHttpOptions());
  }

  getSelectedPrice() {
    let price = this.selectedPrice || this.priceList.filter((p) => p.currency.toLowerCase() === this.activeCurrency.toLowerCase() && this.selectedProduct.id == p.product)[0];
    if (!price) price = this.priceList.filter((p) => p.currency.toLowerCase() === "usd" && this.selectedProduct.id == p.product)[0];
    return price;
  }

  performPurchase(channel?) {
    this.orderCompletedTrackingArgs.products = [
      {
        price: parseFloat(this.orderCompletedTrackingArgs.subtotal),
        sku: this.selectedProduct.productId,
        productId: this.selectedProduct.productId,
        quantity: 1,
        name: this.selectedProduct.title,
      },
    ];
    let selectedProduct = this.selectedProduct;
    let selectedPrice = this.scheduleDetails ? this.s1PlusPrices.hybrid_upgrade : this.getSelectedPrice();
    let productKey: any;
    if (this.upgradingProduct?.license?.productKeyString) {
      productKey = this.upgradingProduct?.license?.productKeyString;
    } else {
      productKey = this.upgradableProducts?.data?.products?.find((p) => p.stripeProductId === this.selectedProduct.stripeProductId)?.productKeyString ?? null;
    }

    let couponId = this.couponData ? this.couponData["coupon-id"] : "";
    if (!couponId && this.couponApplied && this.couponApplied.coupon_id) couponId = this.couponApplied.coupon_id;
    let purchaseRequest: any = {
      old_product_key: productKey,
      product_id: selectedProduct.productId,
      is_tax_inclusive: this.isTaxIncluded,
      sales_channel: channel,
      currency: selectedProduct.currency,
      price_id: selectedPrice?.id,
      firstName: this.billingInfo.firstName,
      lastName: this.billingInfo.lastName,
      address: {
        line1: this.billingInfo.address || this.userService.subDetails.stripe_customer.address.line1,
        city: this.billingInfo.city || this.userService.subDetails.stripe_customer.address.city,
        state: this.billingInfo.state || this.userService.subDetails.stripe_customer.address.state,
        country: this.billingInfo.country || this.userService.subDetails.stripe_customer.address.country,
        postal_code: this.billingInfo.zip || this.userService.subDetails.stripe_customer.address.postal_code,
      },
      coupon_id: couponId,
    };
    if (this.currencyIsConverted) {
      delete purchaseRequest.price_id;
      purchaseRequest.price_data = {
        product: this.selectedProduct.stripeProductId,
        currency: selectedProduct.currency,
        unit_amount:
          this.isNoDecimalCurrency(selectedProduct.currency) && !this.isDivisibleBy100(selectedProduct.currency) ? selectedProduct.price : parseInt((selectedProduct.price * 100).toFixed(0)),
        tax_behavior: "unspecified",
      };
    }
    this.loadingMsg = "25% done...";
    this.percentDone = 25;
    this.userService.createProductInvoice(purchaseRequest).subscribe({
      next: (result: any) => {
        if (result.success && result.data) {
          if (result.data) {
            this.loadingMsg = "35% done...";
            this.percentDone = 35;
            setTimeout(() => {
              this.checkoutResume(result.data.invoice, channel);
            }, 3000);
          } else {
            this.loadingCheckout = false;
            this.checkoutError = {
              type: "add_card",
              message: "Please fill out all required fields and double check your credit card info.",
            };
          }
        } else {
          this.loadingCheckout = false;
          this.checkoutError = {
            type: "add_card",
            message: "Please fill out all required fields and double check your credit card info.",
          };
        }
      },
      error: (error) => {
        this.loadingCheckout = false;
        if (this._helperService.retrieveErrorMessage(error).includes("combine currencies on a single customer")) {
          this.checkoutError = {
            type: "report_error",
            description: this._helperService.retrieveErrorMessage(error),
            message: "You cannot use two different currencies for subscription. Current currency differ than the previous subscribed currency. Please report the issue to resolve it.",
          };
        } else {
          this.checkoutError = {
            type: "add_card",
            message: this._helperService.retrieveErrorMessage(error),
          };
        }
      },
    });
  }

  createSubscription(channel?) {
    let selectedPrice = this.s1PlusPrices[this.selectedS1PlusPlan];
    let subscribeRequest: any = {
      is_tax_inclusive: this.isTaxIncluded,
      sales_channel: channel,
      firstName: this.billingInfo.firstName,
      lastName: this.billingInfo.lastName,
      price_id: selectedPrice.id,
      address: {
        line1: this.billingInfo.address,
        city: this.billingInfo.city,
        state: this.billingInfo.state,
        country: this.billingInfo.country,
        postal_code: this.billingInfo.zip,
      },
      promotion_code: this.couponData ? this.coupon : "",
    };
    if (environment.stripe.test_clock) subscribeRequest.test_clock = environment.stripe.test_clock;
    if (this.currencyIsConverted) {
      delete subscribeRequest.price_id;
      subscribeRequest.price_data = {
        recurring: {
          interval: this.selectedS1PlusPlan.includes("annual") ? "year" : "month",
        },
        product: environment.stripe.subscription_product[this.selectedS1PlusPlan],
        currency: selectedPrice.currency,
        unit_amount: this.isNoDecimalCurrency(selectedPrice.currency) && !this.isDivisibleBy100(selectedPrice.currency) ? selectedPrice.price : parseInt((selectedPrice.price * 100).toFixed(0)),
        tax_behavior: "unspecified",
      };
    }
    this.userService.stripeSubscribe(subscribeRequest).subscribe(
      (result: any) => {
        if (result.success && result.data) {
          if (result.data.invoice) {
            this.loadingMsg = "25% done...";
            this.percentDone = 25;
            setTimeout(() => {
              this.checkoutResume(result.data.invoice, channel);
            }, 3000);
          } else {
            this.loadingCheckout = false;
            this.checkoutError = {
              type: "add_card",
              message: "Please fill out all required fields and double check your credit card info.",
            };
          }
        } else {
          this.loadingCheckout = false;
          this.checkoutError = {
            type: "add_card",
            message: "Please fill out all required fields and double check your credit card info.",
          };
        }
      },
      (error) => {
        this.loadingCheckout = false;
        if (this._helperService.retrieveErrorMessage(error).includes("combine currencies on a single customer")) {
          this.checkoutError = {
            type: "report_error",
            description: this._helperService.retrieveErrorMessage(error),
            message: "You cannot use two different currencies for subscription. Current currency differ than the previous subscribed currency. Please report the issue to resolve it.",
          };
        } else {
          this.checkoutError = {
            type: "add_card",
            message: this._helperService.retrieveErrorMessage(error),
          };
        }
      }
    );
  }

  checkoutResume(invoiceData, channel) {
    let args: any = { invoice: invoiceData };
    if (this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") args.transaction_type = "new";
    this.userService[(this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") && !this.scheduleDetails ? "subscriptionResume" : "resumeTransaction"](args).subscribe({
      next: (result: any) => {
        if (result.success && result.data) {
          this.loadingMsg = "65% done...";
          this.percentDone = 65;
          if (!result.data.clientSecret && result.data.status === "paid") {
            // this invoice has already been paid.
            this.clientSecret = "";
            if ((this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") && !this.scheduleDetails) {
              this.addCardToCustomer();
              this.clientSecret = "";
              this.subscriptionId = "";
              this.subscriptionComplete();
            } else {
              if (this.scheduleDetails) {
                this.purchaseComplete$.next(true);
              } else {
                this.purchaseComplete();
              }
            }
          } else {
            // otherwise we need to confirm the payment.
            if (result.data.subscriptionId) this.subscriptionId = result.data.subscriptionId;
            this.clientSecret = result.data.clientSecret;
            this.confirmCardPayment(channel);
          }
        }
      },
      error: (error) => {
        this.loadingCheckout = false;
        this.checkoutError = {
          type: "add_card",
          message: this._helperService.retrieveErrorMessage(error),
        };
      },
    });
  }

  confirmCardPayment(channel) {
    this.loadingMsg = "45% done...";
    this.percentDone = 45;
    this.stripe
      .confirmCardPayment(this.clientSecret, {
        payment_method: this.newPaymentMethod || this.selectedExistingPaymentMethod,
      })
      .then((response) => {
        if (response.error) {
          this.loadingMsg = undefined;
          this.percentDone = 0;
          this.loadingCheckout = false;
          this.checkoutError = {
            type: "add_card",
            message: response.error.message ? response.error.message : "Please double check your credit card info and try again.",
          };
        } else {
          this.clientSecret = "";
          this.subscriptionId = "";
          if ((this.productTitle == "Studio One+" || this.productTitle == "Studio One Pro+") && !this.scheduleDetails) {
            this.subscriptionComplete();
          } else {
            if (this.scheduleDetails) {
              this.purchaseComplete$.next(true);
            } else {
              this.purchaseComplete();
            }
          }
        }
      })
      .catch((error) => {
        this.loadingCheckout = false;
        this.loadingMsg = undefined;
        this.percentDone = 0;
        this.checkoutError = {
          type: "add_card",
          message: "Please fill out all required fields and double check your credit card info.",
        };
      });
  }

  purchaseComplete() {
    this.loadingMsg = "85% done...";
    this.percentDone = 85;
    this.clientSecret = "";
    setTimeout(() => {
      // Cooldown period to allow stipe webhook to update the product data
      if (this.orderCompletedTrackingArgs && !this.orderCompletedTrackingArgs?.products) this.orderCompletedTrackingArgs.products = [];
      this.checkoutComplete$.next({
        product: this.productTitle,
        trackingData: this.orderCompletedTrackingArgs,
      });
      if (this.orderCompletedTrackingArgs) this.storage.setItem(StorageKeys.CHECKOUT_COMPLETED_DATA, this.orderCompletedTrackingArgs);
    }, 5000);
  }

  subscriptionComplete() {
    this.loadingMsg = "85% done...";
    this.percentDone = 85;
    this.win.setTimeout(() => {
      this.loadingMsg = "90% done...";
      this.percentDone = 90;
      this.userService.getUserDetails(false).subscribe({
        next: (result) => {
          this.loadingMsg = "95% done...";
          this.percentDone = 95;
          this.productService.getProducts(true).subscribe((newProds) => {
            this.orderCompletedTrackingArgs.products = [
              {
                price: parseFloat(this.orderCompletedTrackingArgs.subtotal),
                sku: this.selectedS1PlusPlan == "annual" ? environment.product_ids.sphere_annual : environment.product_ids.sphere_monthly,
                productId: this.selectedS1PlusPlan == "annual" ? environment.product_ids.sphere_annual : environment.product_ids.sphere_monthly,
                quantity: 1,
                name: "Studio One Plus",
              },
            ];
            this.orderCompletedTrackingArgs.subProvider = this.userService.user.subscription?.provider;
            this.orderCompletedTrackingArgs.subStartDate = this.userService.user.subscription?.start_date;
            this.orderCompletedTrackingArgs.subEndDate = this.userService.user.subscription?.end_date;
            this.orderCompletedTrackingArgs.subType = this.userService.user.subscription?.type;
            this.orderCompletedTrackingArgs.prsSubStatus = this.userService.user.subscription?.status;
            this.percentDone = 100;
            if (this.orderCompletedTrackingArgs && !this.orderCompletedTrackingArgs?.products) this.orderCompletedTrackingArgs.products = [];
            this.checkoutComplete$.next({
              product: "Studio One Pro+",
              trackingData: this.orderCompletedTrackingArgs,
            });
            if (this.orderCompletedTrackingArgs) this.storage.setItem(StorageKeys.CHECKOUT_COMPLETED_DATA, this.orderCompletedTrackingArgs);
          });
        },
        error: (error) => {
          this.loadingCheckout = false;
          this.checkoutError = error;
          this.loadingMsg = undefined;
        },
      });
    }, 5000);
  }

  deletePaymentMethod(id) {
    return this.http.delete(environment.paeApiUrl + `user/paymentmethods/${id}`, this._helperService.getHttpOptions());
  }

  getCharge(id) {
    return this.http.get(environment.paeApiUrl + `user/charge/${id}`, this._helperService.getHttpOptions());
  }

  tearDown() {
    this.activeFinalPaymentAmount = undefined;
    this.calculatingTax = false;
    this.total = 0;
    this.selectedPrice = undefined;
    this.selectedProduct = undefined;
    this.taxCalculated = undefined;
    this.total = 0;
    this.totalCharge = undefined;
    this.checkoutError = undefined;
    this.selectedActiveOffer = undefined;
    this.activeOffers = undefined;
    this.offersRetrieved = false;
    this.scheduleDetails = undefined;
    this.selectedProductUpgrade = undefined;
    this.loadingMsg = undefined;
    this.percentDone = 0;
    this.removeCoupon();
    this.billingInfo = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: this.userService.user && this.userService.user.detectedCountry ? this.userService.user.detectedCountry : "US",
    };
  }
}
