<myp-inline-loader [loading]="loading" height="200px" spinnerName="workspaceDetailLoader"></myp-inline-loader>
<ng-container *ngIf="connectionData.status == 'pending' && (!connectionData.initiatedBy || connectionData.initiatedBy == user.id)">
  <div class="d-flex justify-content-center align-items-center w-full h-full">
    <div class="text-center">
      <img [src]="connectionData.photo || './assets/images/default_avatar.jpg'" class="image-border round m-b-20" style="width: 100px; height: 100px" />
      <br />
      <h2 *ngIf="connectionData.name">{{ connectionData.name }}</h2>
      <p>{{ connectionData.name || connectionData.email }} has not yet responded to your invitation.</p>
      <button class="btn btn-primary" (click)="nudge()">Nudge Them</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="connectionData.status == 'pending' && connectionData.initiatedBy && connectionData.initiatedBy != user.id">
  <div class="d-flex justify-content-center align-items-center w-full h-full">
    <div class="text-center">
      <img [src]="connectionData.photo || './assets/images/default_avatar.jpg'" class="image-border round" style="width: 100px; height: 100px" />
      <br />
      <h2 *ngIf="connectionData.name">{{ connectionData.name }}</h2>
      <p>{{ connectionData.name }} invited you to connect!</p>
      <div class="p-20 m-y-20" *ngIf="connectionData.inviteMessage" style="max-width: 600px; background-color: rgba(0, 0, 0, 0.2); border-radius: 20px">{{ connectionData.inviteMessage }}</div>
      <button class="btn btn-primary" (click)="updateInvitation(true)">Accept Invitation</button>
      &nbsp;&nbsp;&nbsp;
      <button class="btn btn-danger" (click)="updateInvitation(false)">Reject Invitation</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="connectionData.status == 'rejected'">
  <div class="d-flex justify-content-center align-items-center w-full h-full">
    <div class="text-center">
      <img [src]="connectionData.photo || './assets/images/default_avatar.jpg'" class="image-border round" style="width: 100px; height: 100px" />
      <br />
      <h2 *ngIf="connectionData.name">{{ connectionData.name }}</h2>
      <p>{{ connectionData.name }} has rejected your invitation.</p>
      <button class="btn btn-danger" (click)="deleteInvitation()">Delete Connection Request</button>
    </div>
  </div>
</ng-container>
<div class="container satellite-page" *ngIf="!loading && (connectionData.status == 'active' || connectionData.status == 'deleted')">
  <ng-container>
    <div class="d-flex d-md-flex justify-space-between align-items-center m-b-10 m-t-5">
      <div class="text-left workspace-title">
        <h4 class="gothic text-truncate content-title">Connection with {{ connectionData.name }}</h4>
      </div>
      <div class="text-right workspace-action">
        <!-- <span class="btn btn-secondary" (click)="editWorkspace()"><fa-icon icon="edit"></fa-icon>&nbsp;{{ "general.settings" | translate }}</span> -->
      </div>
    </div>
  </ng-container>

  <div class="row m-b-10 d-none d-lg-none" [class.d-block]="selectedView == 'details'">
    <div class="col">
      <div class="view-chooser-container d-flex">
        <div class="view-chooser white" style="width: 100%" *ngIf="!batchOperationMode">
          <div class="option" [class.selected]="selectedView == 'conversation'" (click)="selectView('conversation')">Conversation</div>
          <!-- <div class="option" [class.selected]="selectedView == 'files'" (click)="selectView('files')">
            {{ "sphere.workspaces.shared_files" | translate | uppercase }}
            <span class="unread-badge" *ngIf="totalUnreadFileMessages">{{ totalUnreadFileMessages }}</span>
          </div> -->
          <div class="option d-block d-lg-none" [class.selected]="selectedView == 'details'" (click)="selectView('details')">{{ "sphere.details" | translate | uppercase }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex">
    <div class="d-none d-lg-block workspace-sidebar" [style]="'width: ' + detailsWidth + '; margin-right: 15px; min-width: 300px;'" [class.d-block]="selectedView == 'details'">
      <ng-container *ngTemplateOutlet="detailContent"></ng-container>
    </div>
    <div class="d-none d-lg-block overflow-hidden flex-fill" [class.d-block]="selectedView == 'conversation' || selectedView == 'files'">
      <div class="row m-b-10">
        <div class="col">
          <div class="view-chooser-container d-flex">
            <div class="view-chooser white" style="width: 100%" *ngIf="!batchOperationMode">
              <div class="option" [class.selected]="selectedView == 'conversation'" (click)="selectView('conversation')">Conversation</div>
              <!-- <div class="option" [class.selected]="selectedView == 'files'" (click)="selectView('files')">
                {{ "sphere.workspaces.shared_files" | translate | uppercase }}
                <span class="unread-badge" *ngIf="totalUnreadFileMessages">{{ totalUnreadFileMessages }}</span>
              </div> -->
              <div class="option d-block d-lg-none" [class.selected]="selectedView == 'details'" (click)="selectView('details')">{{ "sphere.details" | translate | uppercase }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" #conversationFilesView style="overflow: hidden" [ngStyle]="{ height: conversationFilesViewHeight + 'px' }">
        <div class="col-md-12 h-full">
          <div class="discussion-files-view" [ngStyle]="{ opacity: this.connectionData.status == 'active' ? 1 : '0.4' }">
            <div class="files-note" *ngIf="batchOperationMode && !hideBatchDownloadNotice">
              {{ "sphere.workspaces.batch_download_note1" | translate }}
              <br />
              <small *ngIf="!isChrome">
                {{ "sphere.workspaces.batch_download_note2" | translate }}
                <br />
              </small>
              <a class="small-btn" *ngIf="!isChrome" href="https://chrome.com" target="_blank">{{ "sphere.workspaces.download_chrome" | translate }}</a>
              <a class="small-btn" href="https://www.howtogeek.com/428416/how-to-enabledisable-multiple-file-downloads-in-chrome/" target="_blank">{{ "general.learn_more" | translate }}</a>
              <button class="small-btn" (click)="hideBatchDownloadNotice = true">{{ "sphere.dismiss" | translate }}</button>
            </div>
            <div class="timeline-instruction-overlay" *ngIf="selectedView != 'files' && !loadingConversation && (!displayedMessages || !displayedMessages.length)">
              <div class="text-center">
                <span class="brandfont-bold">Conversation</span>
                <div *ngIf="myWorkspace && workspace.collaborators.length == 1">
                  This is where the discussion with your collaborators will happen. The first step is to invite someone to collaborate.
                  <br />
                  <br />
                  <button class="small-btn" (click)="manageCollaborators()">{{ "sphere.workspaces.manage_collaborators" | translate }}</button>
                </div>
                <div *ngIf="(myWorkspace && workspace.collaborators.length > 1) || !myWorkspace">Its awfully quiet in here. Send a message below!</div>
              </div>
            </div>

            <div *ngIf="attingCollabs && attingCollabs.length" class="atting-collabs">
              <div *ngFor="let user of attingCollabs" (click)="selectAtCollab(user)" class="pointer" [class.selected]="highlightedAtCollab.user_id == user.user_id">{{ user.name }}</div>
            </div>

            <div [class.visible]="typingUsers && typingUsers.length" class="users-typing d-flex justify-content-center align-items-center">
              <div class="m-r-10" *ngIf="typingUsers && typingUsers.length">{{ "sphere.workspaces.typing" | translate }}:</div>
              <div *ngFor="let id of typingUsers" class="m-r-10 typing-user">
                <img [src]="connectionData.photo ? connectionData.photo : './assets/images/default_avatar.jpg'" style="width: 20px; height: 20px; border-radius: 10px; margin-right: 5px" />
                {{ connectionData.name }}
              </div>
            </div>
            <div class="h-full">
              <div class="d-flex justify-content-center align-items-center t-30 h-full" *ngIf="loadingConversation && selectedView != 'files'">
                <myp-inline-loader [loading]="true" height="200px" spinnerName="conversationLoader"></myp-inline-loader>
              </div>
              <div class="h-full" *ngIf="selectedView != 'files'">
                <div #chatWindow class="discussion-container h-full" [ngStyle]="{ 'border-radius': '10px 10px 0px 0px' }">
                  <div class="text-center" *ngIf="!showingAllMessages && !loadingConversation">
                    <button class="small-btn" *ngIf="displayedMessages.length == 100" (click)="initMessages(true)">Get Older Messages</button>
                  </div>
                  <ng-container *ngFor="let msg of displayedMessages">
                    <div *ngIf="msg.type == 'message'" class="msg-container" [class.me]="msg.author.id == user.id" [class.them]="msg.author.id != user.id">
                      <img [src]="msg.author.photo || './assets/images/default_avatar.jpg'" class="profile-photo" />
                      <div class="msg">
                        <span class="text" *ngIf="msg.type == 'message'" [innerHTML]="msg.message"></span>
                        <span class="timestamp d-block">
                          {{ msg.timestamp.toDate() | date: "MMM Do [at] hh:mm" : true }} &nbsp;
                          <span *ngIf="msg.author.id != user.id">{{ msg.author.name }}&nbsp;</span>
                          <span *ngIf="msg.edited" class="c-gray">Edited&nbsp;&nbsp;</span>
                          <span *ngIf="msg.author.id == user.id" class="msg-options-link" (click)="msgOptions(msg)">{{ "sphere.options" | translate }}</span>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="msg-input">
        <myp-message-input
          #msgInput
          [type]="'span'"
          inputID="main-input"
          (valueChanged)="messageValueChange($event)"
          (returnPressed)="addMessage($event)"
          placeholder="Enter a message..."
          [includeSendButton]="true"
          ariaLabelBy="comment-label"
        ></myp-message-input>
      </div>
    </div>
  </div>
</div>

<ng-template #detailContent>
  <div class="image-container metro-profile-photo">
    <img class="w-full border-radius10 pointer image-border round metro-profile-photo" *ngIf="connectionData.photo" (click)="showImage(connectionData.photo)" [src]="connectionData.photo" />
  </div>
  <div class="text-center m-t-30">
    <span class="t-20 bold d-block m-b-10">{{ connectionData.name }}</span>
    <span class="t-16 bold d-block m-b-20">{{ connectionData.email }}</span>
    <a [routerLink]="['/profile', connectionData.userId]" class="small-btn">View Profile</a>
  </div>
  <div class="m-y-20 p-10 text-center" style="background-color: red; border-radius: 10px" *ngIf="this.connectionData.status == 'deleted'">
    THIS CONNECTION HAS BEEN DELETED BY {{ connectionData.name.toUpperCase() }}
    <br />
    <br />
    <button class="btn btn-danger" (click)="deleteInvitation()">Remove Connection</button>
  </div>
</ng-template>
