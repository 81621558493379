import { Component, Input } from "@angular/core";

import { AdminService, BaseComponent, ContentService, Course, EventBusService, LearnGroup, Lesson, ProductService, UserService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { ItemSpec } from "@nativescript/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, Subject } from "rxjs";
import { skip, take, takeUntil, throttle } from "rxjs/operators";
import { UploadFileComponent } from "../upload-file/upload-file.component";

@Component({
  selector: "myp-add-edit-learn-group",
  templateUrl: "add-edit-learn-group.component.html",
})
export class AddEditLearnGroupComponent extends BaseComponent {
  @Input() learnGroup: LearnGroup = {
    title: "",
    description: "",
    snippet: "",
    level: -1,
    status: "pending",
  };

  @Input() type: string;
  @Input() video: string;

  public selectedSection: string = "associate";

  public learnGroupLevel: number;
  public associationQuery: string;
  public associationResults: Array<any>;
  public flagshipProductResults: Array<any>;
  private _flagshipProductQuery: string;
  private _flagshipProductQueryStream$: Subject<any> = new Subject();
  set flagshipProductQuery(query) {
    this._flagshipProductQuery = query;
    if (this._flagshipProductQuery) {
      this._flagshipProductQueryStream$.next(query);
    } else {
      this.flagshipProductResults = undefined;
    }
  }

  get flagshipProductQuery() {
    return this._flagshipProductQuery;
  }

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public contentService: ContentService,
    public appService: AppService,
    public eventbusService: EventBusService,
    public productService: ProductService,
    public adminService: AdminService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.learnGroup.id) this.learnGroup.type = this.type;
    if (this.learnGroup.id && !this.learnGroup.level) this.learnGroup.level == -1;
    if (this.type) this.learnGroup.type = this.type;
    if (this.video) this.addVideo(this.video);
    if (this.learnGroup.type == "folder") this.learnGroup.status = "approved";
    this._flagshipProductQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });
  }

  productAutocomplete() {
    if (this.flagshipProductQuery) {
      this.productService.search(this.flagshipProductQuery).subscribe((result: Array<any>) => {
        this.flagshipProductResults = result;
      });
    } else {
      this.flagshipProductResults = [];
    }
  }

  setAsFlagship(product) {
    this.flagshipProductQuery = "";
    this.flagshipProductResults = [];
    let args: any = {
      productId: product.id,
    };
    args[this.learnGroup.type == "course" ? "courseId" : "lessonId"] = this.learnGroup.id;

    this.adminService.updateProductFlagship(args).subscribe((result) => {
      if (!this.learnGroup.flagship_products) this.learnGroup.flagship_products = [];
      this.learnGroup.flagship_products.push(product);
    });
  }

  unsetAsFlagship(product) {
    let args: any = {
      productId: product.id,
    };
    args[this.learnGroup.type == "course" ? "courseId" : "lessonId"] = null;
    this.adminService.updateProductFlagship(args).subscribe((result) => {
      this.learnGroup.flagship_products = this.learnGroup.flagship_products.filter((item) => item.id != product.id);
    });
  }

  filterItems(type) {
    if (type == "videos") {
      let results = this.contentService.videos.filter(
        (item, index) =>
          (item.id == this.associationQuery || item.title.toLowerCase().indexOf(this.associationQuery) > -1) && (!this.learnGroup.video_ids || !this.learnGroup.video_ids.includes(item.id))
      );
      this.associationResults = results.filter((item, index) => index < 10);
    } else if (type == "lessons") {
      let results = this.contentService.lessons.filter(
        (item, index) =>
          (item.id == parseInt(this.associationQuery) || item.title.toLowerCase().indexOf(this.associationQuery) > -1) && (!this.learnGroup.lesson_ids || !this.learnGroup.lesson_ids.includes(item.id))
      );
      this.associationResults = results.filter((item, index) => index < 10);
    }
  }

  addVideo(video) {
    if (this.learnGroup.type == "lesson" && this.learnGroup.status == "approved" && video.status != "approved") {
      alert("You cannot add an unapproved video to an approved lesson. Click the yellow PENDING button to approve this video before adding to the lesson.");
    } else {
      if (!this.learnGroup.videos) this.learnGroup.videos = [];
      if (!this.learnGroup.video_ids) this.learnGroup.video_ids = [];
      this.learnGroup.videos.push(video);
      this.learnGroup.video_ids.push(video.id);
      this.associationResults = this.associationResults.filter((item) => item.id != video.id);
      // this.associationQuery = '';
      // this.associationResults = [];
    }
  }

  sortVideos() {
    this.learnGroup.videos.sort((a: any, b: any) => {
      return new Date(a.created) > new Date(b.created) ? -1 : 1;
    });
  }

  removeVideo(video) {
    this.learnGroup.videos = this.learnGroup.videos.filter((item) => item.id != video.id);
    this.learnGroup.video_ids = this.learnGroup.video_ids.filter((id) => id != video.id);
  }

  addLesson(lesson) {
    if (this.learnGroup.type == "course" && this.learnGroup.status == "approved" && lesson.status != "approved") {
      alert("You cannot add an unapproved lesson to an approved course. Click the yellow PENDING button to approve this lesson before adding to the course.");
    } else {
      if (!this.learnGroup.lessons) this.learnGroup.lessons = [];
      if (!this.learnGroup.lesson_ids) this.learnGroup.lesson_ids = [];
      this.learnGroup.lessons.push(lesson);
      this.learnGroup.lesson_ids.push(lesson.id);
      this.associationQuery = "";
      this.associationResults = [];
    }
  }

  removeLesson(lesson) {
    this.learnGroup.lessons = this.learnGroup.lessons.filter((item) => item.id != lesson.id);
    this.learnGroup.lesson_ids = this.learnGroup.lesson_ids.filter((id) => id != lesson.id);
  }

  addImage() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_learn_group_image";
    modalRef.componentInstance.cropRatio = "1.77777777777";
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      if (result.url) this.learnGroup.image = result.url;
    });
  }

  addResources() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_learn_group_resources";
    modalRef.componentInstance.allowedTypes = ["application/octet-stream", "application/x-compress", "application/x-compressed", "application/x-zip-compressed", "application/zip", "multipart/x-zip"];
    modalRef.componentInstance.allowedFormatString = ".zip";
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      if (result.url) this.learnGroup.resources_link = result.url;
    });
  }

  validate(args) {
    if (!args.title) {
      alert("Please fill out all the required fields.");
      return false;
    } else if (args.type != "folder" && (!args.description || !args.snippet)) {
      alert("Please fill out all the required fields.");
      return false;
    } else if (args.type == "lesson" && args.status == "approved" && !args.video_ids.length) {
      alert("You cannot approve a lesson that has no videos.");
      return false;
    } else if (args.type == "course" && args.status == "approved" && !args.lesson_ids.length) {
      alert("You cannot approve a course that has no lessons.");
      return false;
    }
    return true;
  }

  delete() {
    let message = "Are you sure you  want to delete this " + this.learnGroup.type + "? ";
    if (this.learnGroup.type != "folder") message += "Any users who have enrolled in this " + this.learnGroup.type + " will still have access until they complete it.";
    if (confirm(message)) {
      this.contentService.deleteLearnGroup(this.learnGroup.id).subscribe((result) => {
        this.activeModal.close(true);
      });
    }
  }

  save() {
    let args: any = {
      title: this.learnGroup.title,
      description: this.learnGroup.description,
      snippet: this.learnGroup.snippet,
      type: this.learnGroup.type,
      status: this.learnGroup.status,
      level: this.learnGroup.level == -1 ? null : this.learnGroup.level,
      subscription_only: this.learnGroup.subscription_only,
    };

    if (this.learnGroup.image) args.image = this.learnGroup.image;
    if (this.learnGroup.id) args.id = this.learnGroup.id;
    if (this.learnGroup.resources_link) args.resources_link = this.learnGroup.resources_link;
    if (this.learnGroup.type == "lesson" || this.learnGroup.type == "folder") {
      if (this.learnGroup.video_ids) args.video_ids = [];
      if (this.learnGroup.videos && this.learnGroup.videos.length) {
        this.learnGroup.videos.forEach((item) => {
          args.video_ids.push(item.id);
        });
      }
    } else if (this.learnGroup.type == "course") {
      if (this.learnGroup.lesson_ids) args.lesson_ids = [];
      if (this.learnGroup.lessons && this.learnGroup.lessons.length) {
        this.learnGroup.lessons.forEach((item) => {
          args.lesson_ids.push(item.id);
        });
      }
    }
    if (args.level == -1) args.level = null;
    if (this.validate(args)) {
      this.contentService.addOrUpdateLearnGroup(args).subscribe((result) => {
        this.activeModal.close(true);
      });
    }
  }
}
