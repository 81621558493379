import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseComponent, CommunityService, ContentService, environment, EventBusService, HelperService, UserService, Video } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs/operators";
import { ChooseLessonComponent, EditVideoComponent } from "../modals";

@Component({
  selector: "myp-video-grid-item",
  templateUrl: "video-grid-item.component.html",
})
export class VideoGridItemComponent extends BaseComponent {
  @Input() video: Video;
  @Input() admin: boolean;
  @Input() selectable: boolean;
  @Input() showBorder: boolean;
  @Input() showRemoveFromPost: boolean = false;
  @Input() showRemove: boolean = false;
  @Input() showAdd: boolean = false;
  @Input() showRemoveFromKeepWatching: boolean = false;
  @Input() showChevron: boolean = true;
  @Input() showCopyLink: boolean = true;
  @Input() showAddToLesson: boolean = false;
  @Input() showDate: boolean = true;
  @Input() numbered: any;
  @Input() lessonID: any;
  @Input() hideActionButtons: boolean = true;
  @Output() videoClickedEvent: EventEmitter<Video> = new EventEmitter();
  @Output() added: EventEmitter<void> = new EventEmitter();
  @Output() removed: EventEmitter<void> = new EventEmitter();
  @Output() tagSelected: EventEmitter<Video> = new EventEmitter();
  @Output() removeFromPostClicked: EventEmitter<any> = new EventEmitter();
  public status: string;
  public displayStatus: string;

  private _performingListAction: boolean;
  set performingListAction(value) {
    this._performingListAction = value;
    setTimeout(() => {
      this._performingListAction = false;
    }, 500);
  }

  get performingListAction() {
    return this._performingListAction;
  }

  constructor(
    userService: UserService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    public communityService: CommunityService,
    public contentService: ContentService,
    public helperService: HelperService,
    public eventBusService: EventBusService,
    public router: Router
  ) {
    super(userService);
  }

  copyLink(video, event) {
    this.performingListAction = true;
    this._clipboardService.copy(environment.mypresonusUrl + "learn/browse?viewingVideo=" + this.video.id);
    event.stopPropagation();
  }

  chooseLessonOrFolder(type) {
    const modalRef = this.appService.showModal(ChooseLessonComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = this.video;
    modalRef.componentInstance.type = type;
  }

  findParentLessons() {
    let parents = [];
    this.contentService.lessons.forEach((lesson) => {
      if (lesson.video_ids && lesson.video_ids.includes(this.video.id)) parents.push(lesson);
    });
    return parents;
  }

  toggleApprove(event) {
    this.performingListAction = true;
    if (this.video.status == "pending") {
      this.video.status = "approved";
      this.contentService.saveVideo({ id: this.video.id, status: this.video.status }).subscribe();
    } else {
      let isPartOfApprovedLesson = false;
      let parents = this.findParentLessons();
      if (parents && parents.length) {
        parents.forEach((lesson) => {
          if (lesson.status == "approved") isPartOfApprovedLesson = true;
        });
      }
      if (isPartOfApprovedLesson) {
        alert("This video is part of an approved lesson. You cannot unapprove a video that is part of an active curriculum.");
      } else {
        this.video.status = this.video.status == "approved" ? "pending" : "approved";
        this.contentService.saveVideo({ id: this.video.id, status: this.video.status }).subscribe();
      }
    }
    event.stopPropagation();
  }

  add(event) {
    this.performingListAction = true;
    this.added.next();
    event.stopPropagation();
  }

  remove(event) {
    this.performingListAction = true;
    this.removed.next();
    event.stopPropagation();
  }

  pin(event) {
    this.performingListAction = true;
    this.contentService
      .saveUserVideoData({
        user_id: this.user.id,
        video_id: this.video.id,
        saved: this.contentService.userLearnData.videos[this.video.id] && this.contentService.userLearnData.videos[this.video.id].saved ? false : true,
      })
      .subscribe({
        next: (result) => {},
        error: (error) => {
          this.appService.alertError(error);
        },
      });
    event.stopPropagation();
  }

  removeFromKeepWatching() {
    this.performingListAction = true;
    this.contentService
      .saveUserVideoData({
        user_id: this.user.id,
        video_id: this.video.id,
        marked_done: true,
      })
      .subscribe({
        next: (result) => {},
        error: (error) => {
          this.appService.alertError(error);
        },
      });
    event.stopPropagation();
  }

  editVideo() {
    this.performingListAction = true;
    const modalRef = this.appService.showModal(EditVideoComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = this.video;
    modalRef.result.then(
      (result) => {},
      (error) => {}
    );
  }

  selectTag(tag, e) {
    this.performingListAction = true;
    e.stopPropagation();
    this.router.navigate(["/learn/videos/" + tag.string_id]);
  }

  addTo(event) {
    this.performingListAction = true;
    event.stopPropagation();
    let args: any = {
      title: this.video.title,
      actions: ["Add to Lesson", "Add to Folder"],
    };
    this.eventBusService.emit(this.eventBusService.types.showActionChooser, args);
    this.eventBusService
      .observe(this.eventBusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.chooseLessonOrFolder(result == "Add to Lesson" ? "lessons" : "folders");
        }
      });
  }

  videoClicked(video, event) {
    if (!this.performingListAction) {
      if (this.admin || this.showAdd || this.showRemove || this.selectable) {
        this.videoClickedEvent.next(video);
      } else {
        let args: any = {
          id: this.video.platform_id,
          title: this.video.title,
          type: this.video.platform,
          thumb: this.video.image,
          presonus_id: this.video.id,
        };
        if (this.lessonID) args.lesson = this.contentService.lessonMap[this.lessonID];
        this.eventBusService.emit(this.eventBusService.types.playVideo, args);
      }
      if (event) event.stopPropagation();
    }
  }
}
