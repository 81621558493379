<div
  class="create-post-container"
  [class.create-post-active]="createPostActive"
  (onFileDrop)="droppedFile($event)"
  ng2FileDrop
  [uploader]="uploader"
  (fileOver)="fileOverBase($event)"
  [class.hovering]="hasBaseDropZoneOver"
>
  <ng-container *ngIf="(communityService.profile?.public && user.active_subscription) || betaStringId">
    <div class="d-flex">
      <!-- <img [src]="userService.profilePicture" class="profile-photo" alt="" /> -->

      <div class="w-full form-group m-b-0">
        <label id="start-post-label" class="control-label" for="post-input">{{ "com.start_new_post" | translate }}</label>
        <div class="m-b-10" *ngIf="betaStringId">
          <myp-message-input
            #subjectInput
            [type]="'span'"
            inputID="post-subject-input"
            (valueChanged)="postSubjectContentChanged($event)"
            (focus)="postInputFocus('subject')"
            (blur)="postInputBlur()"
            [placeholder]="'Enter a subject for your post...'"
            [backgroundColor]="appService.activeTheme == 'dark' ? '#2e2e2e' : '#e9e9e9'"
            [shouldDetectContent]="false"
            [includeSendButton]="false"
            [mentionSource]="betaStringId ? false : 'community_profiles'"
          ></myp-message-input>
        </div>
        <myp-message-input
          #msgInput
          [type]="'span'"
          inputID="post-input"
          (valueChanged)="postContentChanged($event)"
          (returnPressed)="sendPost()"
          (focus)="postInputFocus('post')"
          (blur)="postInputBlur()"
          [useQuill]="betaStringId"
          [placeholder]="betaStringId ? 'Enter a body for your post...' : 'Start typing...'"
          [shouldDetectContent]="true"
          [includeSendButton]="false"
          [backgroundColor]="appService.activeTheme == 'dark' ? '#2e2e2e' : '#e9e9e9'"
          [mentionSource]="betaStringId ? false : 'community_profiles'"
          ariaLabelBy="start-post-label"
        ></myp-message-input>
      </div>
    </div>

    <ng-container *ngIf="elligibleMentions && elligibleMentions.length">
      <button class="mention-option d-flex m-y-10 align-items-center" [class.selected]="user.selected" (click)="selectMention(user)" *ngFor="let user of elligibleMentions">
        <img [src]="user.photo || './assets/images/default_avatar.jpg'" class="profile-photo tiny m-r-5" alt="user photo" />
        <span class="bold t-14">{{ user.firstName + " " + user.lastName }}</span>
      </button>
    </ng-container>

    <div *ngIf="msgInput.selectedArticlesInMessage && msgInput.selectedArticlesInMessage.length">
      <button class="comment-article-attachment" (click)="showArticle(item)" *ngFor="let item of msgInput.selectedArticlesInMessage">
        <fa-icon icon="newspaper" class="icon"></fa-icon>
        <div>
          <span class="bold">{{ item.article.name }}</span>
          <br />
          <span class="t-12 text-muted">{{ item.article.created_at_pretty }}</span>
        </div>
        <button class="close-btn fa-button" (click)="removeArticle(item, $event)" aria-label="remove article">
          <fa-icon icon="times-circle"></fa-icon>
        </button>
      </button>
    </div>

    <div class="m-y-20" *ngIf="msgInput.selectedLearnContentInMessage && msgInput.selectedLearnContentInMessage.length">
      <ng-container *ngFor="let item of msgInput.selectedLearnContentInMessage">
        <div class="grid-container wider pad-10" *ngIf="item.content.type == 'video'">
          <myp-video-grid-item [showBorder]="true" [video]="item.content" [showRemoveFromPost]="true" (removeFromPostClicked)="removeLearnContent(item, $event)"></myp-video-grid-item>
        </div>
        <ng-container *ngIf="item.content.type != 'video'">
          <myp-learn-list-item
            [shouldShowButtons]="false"
            [showRemoveFromPost]="true"
            [item]="item.content"
            [preventNav]="true"
            (removeFromPostClicked)="removeLearnContent(item, $event)"
          ></myp-learn-list-item>
        </ng-container>
      </ng-container>
    </div>

    <div class="m-y-20" *ngIf="msgInput.publicFileLinksInMessage && msgInput.publicFileLinksInMessage.length">
      <ng-container *ngFor="let item of msgInput.publicFileLinksInMessage">
        <div class="comment-article-attachment" *ngFor="let item of msgInput.publicFileLinksInMessage">
          <fa-icon [icon]="workspaceService.audioFiles[item.publicFile.extension] ? 'play-circle' : 'file'" class="icon"></fa-icon>
          <div>
            <span class="bold">{{ item.publicFile.filename }}</span>
            <br />
            <span class="t-12 text-muted">{{ item.publicFile.description }}</span>
          </div>
          <button class="close-btn fa-button" (click)="removePublicFile(item, $event)" aria-label="Remove public file">
            <fa-icon icon="times-circle"></fa-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <div class="d-flex flex-column" *ngIf="msgInput.messageEmbeds && msgInput.messageEmbeds.length">
      <ng-container *ngFor="let embed of msgInput.messageEmbeds">
        <div class="message-embed message-embed--{{ embed.type }} message-embed--{{ embed.subtype }}">
          <button class="close-btn fa-button" (click)="removeEmbed(item, $event)" aria-label="Remove Embed">
            <fa-icon icon="times-circle"></fa-icon>
          </button>
          <iframe width="560" height="315" [src]="embed.embedSrc" frameborder="0" allowfullscreen></iframe>
        </div>
      </ng-container>
    </div>

    <div class="w-100" *ngIf="uploadingFiles && uploadingFiles.length && createPostActive">
      <ng-container *ngFor="let file of uploadingFiles">
        <span class="text-muted t-14">{{ "general.uploading" | translate }}: {{ file.filename }} {{ file.progress }}%</span>
        <div class="post-progress-bar" [ngStyle]="{ width: (file.progress ? file.progress : 0) + '%' }"></div>
      </ng-container>
    </div>
    <div class="d-flex flex-wrap" *ngIf="uploadedAttachments && uploadedAttachments.length && createPostActive">
      <div class="attachment" *ngFor="let attachment of uploadedAttachments">
        <img [src]="attachment.url" *ngIf="workspaceService.imageFiles[attachment.extension]" class="image-border" style="width: 200px" alt="attachment image" />
        <audio id="audio_player" *ngIf="workspaceService.audioFiles[attachment.extension]" controls="true">
          <source [src]="attachment.url" type="audio/wav" />
        </audio>
        <span class="gothic t-18" *ngIf="!workspaceService.audioFiles[attachment.extension] && !workspaceService.imageFiles[attachment.extension]">
          <fa-icon icon="file" class="m-r-10"></fa-icon>
          <span>{{ attachment.filename }}</span>
        </span>
        <button class="remove fa-button" (click)="removeAttachment(attachment)"><fa-icon icon="times-circle" class="c-red"></fa-icon></button>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center m-y-10" *ngIf="createPostActive">
      <div *ngIf="!uploadingFile">
        <ul class="d-flex align-items-center attachment-list">
          <li>
            <span class="d-block m-r-10 t-12 bold">Attach:</span>
          </li>
          <li>
            <button class="fa-button" (click)="attachFile()" [ngbTooltip]="'File'">
              <fa-icon class="circle-icon-btn m-r-5" icon="file"></fa-icon>
              <span class="sr-only">File</span>
            </button>
          </li>
          <li>
            <button class="fa-button" (click)="showAttach('videos')" [ngbTooltip]="'Video'">
              <fa-icon class="circle-icon-btn m-r-5" icon="video"></fa-icon>
              <span class="sr-only">Video</span>
            </button>
          </li>
          <li>
            <button class="fa-button" (click)="showAttach('articles')" [ngbTooltip]="'Article'">
              <fa-icon class="circle-icon-btn m-r-5" icon="newspaper"></fa-icon>
              <span class="sr-only">Article</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="t-12" *ngIf="userService.user.is_sphere_admin || userService.isEmployee">
        <div *ngIf="userService.user.is_sphere_admin && !betaStringId">
          <label>
            <input type="checkbox" [(ngModel)]="sphereOnlyPost" />
            &nbsp; Only show this post in {{ env.studioOnePlusBrandName }}
          </label>
        </div>
        <div *ngIf="userService.isEmployee && !betaStringId">
          <label>
            <input type="checkbox" [(ngModel)]="employeeOnlyPost" />
            &nbsp; Only show to employees
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="betaStringId && isBetaManager && createPostActive" class="t-12">
      <div class="m-b-10">
        <button class="small-btn" (click)="populateBetaPostTemplate()">Inject Issue Template</button>
      </div>
      <div>
        <label>
          <input type="checkbox" [(ngModel)]="pinToBetaProgram" />
          &nbsp; Pin to top of Beta Program
        </label>
      </div>
      <div>
        <label>
          <input type="checkbox" [(ngModel)]="sendBetaProgramAnnouncementEmail" />
          &nbsp; Send an email with the contents of this post to every participant of {{ betaStringId }} program.
        </label>
      </div>
    </div>
    <div class="m-t-10 d-flex align-items-end justify-content-between position-relative" *ngIf="createPostActive">
      <div class="d-flex flex-wrap align-items-center">
        <ng-container *ngIf="topic">
          <span class="t-12 text-muted">Topic:</span>
          <span class="create-post-selected-channel">{{ topic.title }}</span>
        </ng-container>
        <span class="t-12 text-muted" [class.m-l-10]="topic">{{ "com.channels" | translate }}:</span>
        <span class="create-post-selected-channel" *ngIf="!betaStringId && !topic && !selectedChannels.length">{{ "com.skills.general" | translate }}</span>
        <ng-container *ngFor="let channel of selectedChannels">
          <span class="create-post-selected-channel">
            # {{ betaStringId ? channel.title : ("com.skills." + channel.string_id | translate) }}
            <button class="m-l-5 fa-button" (click)="removeChannel(channel)">
              <fa-icon icon="times-circle"></fa-icon>
            </button>
          </span>
        </ng-container>
        <button
          class="add-channel-button c-lblue bold pointer"
          aria-controls="channel-list"
          [attr.aria-expanded]="shouldShowChannelSelector"
          (click)="shouldShowChannelSelector = !shouldShowChannelSelector"
          *ngIf="!selectedChannels || selectedChannels.length < 3"
        >
          {{ "com.add_channel" | translate }}
          <fa-icon icon="plus-circle" class="c-lblue t-14"></fa-icon>
        </button>
      </div>
      <button class="send-btn no-margin" (click)="sendPost()" [class.disabled]="!postContent">{{ "general.send" | translate | uppercase }}</button>
    </div>
    <!-- <div class="d-flex justify-content-end" *ngIf="createPostActive"></div> -->
    <div class="channel-selector" id="channel-list" *ngIf="shouldShowChannelSelector">
      <div class="channel-list overflow-auto h-100" *ngIf="!betaStringId">
        <button class="channel-button bold pointer t-14 p-1" *ngFor="let skill of communityService.taggableChannels" (click)="addChannel(skill)">
          <fa-icon icon="hashtag" class="c-lblue"></fa-icon>
          {{ "com.skills." + skill.string_id | translate }}
        </button>
      </div>
      <div class="channel-list overflow-auto h-100" *ngIf="betaStringId">
        <button class="channel-button bold pointer t-14 p-1" *ngFor="let tag of betaService.communityTags" (click)="addChannel(tag)">
          <fa-icon icon="hashtag" class="c-lblue"></fa-icon>
          {{ tag.title }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="user.active_subscription && communityService.profile && !communityService.profile.public">
    <div class="warning text-center">
      <p>{{ "com.profile_needs_public" | translate }}</p>
      <a class="small-btn blue" role="link" (click)="makePublic()">{{ "com.make_public" | translate }}</a>
    </div>
  </ng-container>
  <ng-container *ngIf="!user.active_subscription && !betaStringId">
    <div class="warning text-center">
      <h5 class="brandfont-bold">{{ "sphere.welcome.welcome" | translate }}</h5>
      <p>{{ "com.free_browse" | translate }}</p>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a class="small-btn blue" role="link" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</a>
        </li>
        <li class="list-inline-item">
          <a class="small-btn blue" role="link" (click)="watchVideo()">{{ "newdash.watch_short_video" | translate }}</a>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="createPostActive">
    <button type="button" class="btn btn-transparent p-0" style="position: absolute; top: -11px; right: -6px" (click)="cancelCreatePost()">
      <fa-icon icon="times-circle" class="t-20" aria-hidden="true"></fa-icon>
      <span class="sr-only">cancel create post</span>
    </button>
  </ng-container>
</div>

<ng-template #confirmPublicPostModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="hardware-history-details">Please confirm</h4>
    <button ngbAutofocus class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    You are currently attempting to post in the public Community. This means that your post will be visible to all users. You are enrolled in the following Beta Programs, so please confirm that you
    would like to post publicly.

    <div class="m-y-20" *ngFor="let beta of betaService.enrolledBetaFeatures">
      <strong>{{ beta.title }}</strong>
      <a class="btn btn-primary" [routerLink]="['/beta/' + beta.string_id + '/channels/all_posts']" (click)="moveToBeta(); modal.dismiss('Cross click')">Post in {{ beta.title }} instead.</a>
    </div>

    <hr />
    <br />
    <button class="btn btn-primary" (click)="modal.dismiss('Cross click'); sendPost(false, true)">Post in Public Community</button>
  </div>
</ng-template>
