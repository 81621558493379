import { Injectable } from "@angular/core";
// app
import { WindowService } from "./window.service";
import * as moment from "moment";
import SecureLS from "secure-ls";
import { isObject } from "@mypxplat/xplat/utils";

const PREFIX = "myp";
const SEPERATOR = ".";
const PREFIX_KEY = `${PREFIX}${SEPERATOR}`;

declare interface Timestamp {
  value: string;
  timeout: number;
}

// all the keys ever persisted across web and mobile
export interface IStorageKeys {
  LOCALE: string;
  REDIRECT_AFTER_LOGIN: string;
  TOKEN: string;
  ACCESSTOKEN: string;
  IDTOKEN: string;
  TOKENEXPIRY: string;
  REFRESHTOKEN: string;
  USER: string;
  PRODUCTS: string;
  PRODUCTIMAGESMAP: string;
  TRAINING: string;
  EDUCATIONDATA: string;
  ADMINEDUCATIONDATA: string;
  EXCLUSIVECONTENT: string;
  VIMEOFOLDERS: string;
  VIMEOVIDEOSBYFOLDER: string;
  PRODUCTVIDEOS: string;
  ORDERS: string;
  NEWS_UPDATES: string;
  EXCHANGECATEGORIES: string;
  PRODUCTDETAILSMAP: string;
  THEME: string;
  PREFERSGRID: string;
  SORTGROUPBYTYPE: string;
  SORTDIRECTION: string;
  SORTBY: string;
  EXCHANGEPREFERSGRID: string;
  TIMESTAMPS: string;
  ZD_CATEGORIES: string;
  ZD_TICKETFIELDS: string;
  ZD_SECTIONS: string;
  ZD_ARTICLES: string;
  ZD_OPENTICKETS: string;
  ZD_CLOSEDTICKETS: string;
  LANGUAGE: string;
  WORKSPACESCOLLABS: string;
  WORKSPACEDETAILSMAP: string;
  EVENTS: string;
  REDEEMABLE_PRODUCTS: string;
  BETA_PROGRAMS: string;
  ENROLLED_BETA_MAP: string;
  RETURN_AUTHORIZATION: string;
  HAS_SETUP_COMMUNITY: string;
  COMMUNITY_PROFILE: string;
  AVAILABLE_SKILLS: string;
  USER_SKILLS: string;
  USER_STORAGE: string;
  USER_PAYMENT_METHODS: string;
  BADGE_USERS: string;
  LOGGED_IN_EVENT_TRIGGERED: string;
  SUBSCRIPTION_PROVIDER: string;
  HASINITNOTIFICATIONS: string;
  LOCALAUDIOFILES: string;
  DOWNLOADED_FILES: string;
  CONNECTIONS: string;
  SUBSCRIPTION_DETAILS: string;
  CHECKOUT_COMPLETED_DATA: string;
  SSO_PARAMS: string;
  COMMUNITY_POST_DRAFT: string;
  BETA_COMMUNITY_POST_DRAFT_MAP: string;
  POST_REPLY_DRAFT_MAP: string;
  // TODO: add more (refactor rest of codebase to use these)
}

export const StorageKeys: IStorageKeys = {
  LOCALE: `${PREFIX_KEY}locale`,
  REDIRECT_AFTER_LOGIN: `${PREFIX_KEY}redirect-to-after-login`,
  TOKEN: `${PREFIX_KEY}user-token`,
  ACCESSTOKEN: `${PREFIX_KEY}user-accesstoken`,
  REFRESHTOKEN: `${PREFIX_KEY}user-refreshtoken`,
  IDTOKEN: `${PREFIX_KEY}user-idtoken`,
  TOKENEXPIRY: `${PREFIX_KEY}user-tokenexpiry`,
  USER: `${PREFIX_KEY}current-user`,
  PRODUCTS: `${PREFIX_KEY}products`,
  PRODUCTIMAGESMAP: `${PREFIX_KEY}productimagesmap`,
  TRAINING: `${PREFIX_KEY}training`,
  EDUCATIONDATA: `${PREFIX_KEY}educationdata`,
  ADMINEDUCATIONDATA: `${PREFIX_KEY}admineducationdata`,
  EXCLUSIVECONTENT: `${PREFIX_KEY}exclusivecontent`,
  VIMEOFOLDERS: `${PREFIX_KEY}vimeofolders`,
  VIMEOVIDEOSBYFOLDER: `${PREFIX_KEY}vimeovideosbyfolder`,
  PRODUCTVIDEOS: `${PREFIX_KEY}productvideos`,
  ORDERS: `${PREFIX_KEY}orders`,
  NEWS_UPDATES: `${PREFIX_KEY}news_updates`,
  EXCHANGECATEGORIES: `${PREFIX_KEY}exchangecategories`,
  PRODUCTDETAILSMAP: `${PREFIX_KEY}productdetailsmap`,
  THEME: `${PREFIX_KEY}theme`,
  PREFERSGRID: `${PREFIX_KEY}prefersgrid`,
  SORTGROUPBYTYPE: `${PREFIX_KEY}sortgroupbytype`,
  SORTDIRECTION: `${PREFIX_KEY}sortdirection`,
  SORTBY: `${PREFIX_KEY}sortby`,
  EXCHANGEPREFERSGRID: `${PREFIX_KEY}exchangeprefersgrid`,
  TIMESTAMPS: `${PREFIX_KEY}timestamps`,
  ZD_CATEGORIES: `${PREFIX_KEY}zd_categories`,
  ZD_TICKETFIELDS: `${PREFIX_KEY}zd_ticketfields`,
  ZD_SECTIONS: `${PREFIX_KEY}zd_sections`,
  ZD_ARTICLES: `${PREFIX_KEY}zd_articles`,
  ZD_OPENTICKETS: `${PREFIX_KEY}zd_opentickets`,
  ZD_CLOSEDTICKETS: `${PREFIX_KEY}zd_closedtickets`,
  LANGUAGE: `${PREFIX_KEY}language`,
  WORKSPACESCOLLABS: `${PREFIX_KEY}workspacescollabs`,
  WORKSPACEDETAILSMAP: `${PREFIX_KEY}workspacedetailsmap`,
  EVENTS: `${PREFIX_KEY}events`,
  REDEEMABLE_PRODUCTS: `${PREFIX_KEY}redeemable_products`,
  BETA_PROGRAMS: `${PREFIX_KEY}beta_programs`,
  ENROLLED_BETA_MAP: `${PREFIX_KEY}enrolled_beta_map`,
  RETURN_AUTHORIZATION: `${PREFIX_KEY}return_authorization`,
  HAS_SETUP_COMMUNITY: `${PREFIX_KEY}has_setup_community`,
  COMMUNITY_PROFILE: `${PREFIX_KEY}community_profile`,
  AVAILABLE_SKILLS: `${PREFIX_KEY}available_skills`,
  USER_SKILLS: `${PREFIX_KEY}user_skills`,
  USER_STORAGE: `${PREFIX_KEY}user_storage`,
  USER_PAYMENT_METHODS: `${PREFIX_KEY}user_payment_methods`,
  BADGE_USERS: `${PREFIX_KEY}badge_users`,
  LOGGED_IN_EVENT_TRIGGERED: `${PREFIX_KEY}logged_in_event_triggered`,
  SUBSCRIPTION_PROVIDER: `${PREFIX_KEY}subscription_provider`,
  HASINITNOTIFICATIONS: `${PREFIX_KEY}hasinitnotifications`,
  LOCALAUDIOFILES: `${PREFIX_KEY}localaudiofiles`,
  DOWNLOADED_FILES: `${PREFIX_KEY}downloaded_files`,
  CONNECTIONS: `${PREFIX_KEY}connections`,
  SUBSCRIPTION_DETAILS: `${PREFIX_KEY}subscription_details`,
  CHECKOUT_COMPLETED_DATA: `${PREFIX_KEY}checkout_completed_data`,
  SSO_PARAMS: `${PREFIX_KEY}sso_params`,
  COMMUNITY_POST_DRAFT: `${PREFIX_KEY}community_post_draft`,
  BETA_COMMUNITY_POST_DRAFT_MAP: `${PREFIX_KEY}beta_community_post_draft_map`,
  POST_REPLY_DRAFT_MAP: `${PREFIX_KEY}post_reply_draft_map`,
};

const encryptKeys = {
  [StorageKeys.TOKEN]: true,
  [StorageKeys.ACCESSTOKEN]: true,
  [StorageKeys.IDTOKEN]: true,
  [StorageKeys.USER]: true,
  [StorageKeys.PRODUCTS]: true,
  [StorageKeys.PRODUCTIMAGESMAP]: true,
  [StorageKeys.TRAINING]: true,
  [StorageKeys.ORDERS]: true,
  [StorageKeys.PRODUCTDETAILSMAP]: true,
  [StorageKeys.ZD_OPENTICKETS]: true,
  [StorageKeys.ZD_CLOSEDTICKETS]: true,
  [StorageKeys.WORKSPACESCOLLABS]: true,
  [StorageKeys.WORKSPACEDETAILSMAP]: true,
  [StorageKeys.BADGE_USERS]: true,
  [StorageKeys.SUBSCRIPTION_DETAILS]: true,
  [StorageKeys.USER_PAYMENT_METHODS]: true,
  [StorageKeys.CHECKOUT_COMPLETED_DATA]: true,
};

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public storageType: any;
  ls: any;

  constructor(public win: WindowService) {
    this.storageType = this.win && this.win["localStorage"] != null ? this.win["localStorage"] : null;
    this.ls = new SecureLS({ encodingType: "aes" });
  }

  public persistDataOnLogout = {
    NEWS_UPDATES: true,
    EXCHANGECATEGORIES: true,
    EVENTS: true,
    BADGE_USERS: true,
    AVAILABLE_SKILLS: true,
    LOCALAUDIOFILES: true,
  };

  public setItem(key: string, value: any): void {
    try {
      if (this.storageType) {
        if (encryptKeys[key] && this.ls) {
          this.ls.set(key, JSON.stringify(value));
        } else {
          this.storageType.setItem(key, JSON.stringify(value));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  public getItem(key: string): any {
    //console.log('***********retrieving ' + key + ' from storage')
    try {
      if (this.storageType) {
        let item;
        if (encryptKeys[key] && this.ls) {
          item = this.ls.get(key);
        } else {
          item = this.storageType.getItem(key);
        }
        if (item) {
          try {
            return JSON.parse(item);
          } catch (err) {
            console.log(err);
          }
        }
      }
      return undefined;
    } catch (err) {
      //console.log(err);
      return undefined;
    }
  }

  public removeItem(key: string): void {
    try {
      if (this.storageType) {
        if (this.ls) {
          this.ls.remove(key);
        } else {
          this.storageType.removeItem(key);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  public clearAll(): void {
    try {
      if (this.storageType) {
        if (this.ls) this.ls.removeAll();
        this.storageType.clear();
      }
    } catch (err) {
      console.log(err);
    }
  }

  public isAvailable(): boolean {
    try {
      if (this.storageType) {
        const x = `${PREFIX}__test__`;
        this.storageType.setItem(x, x);
        this.storageType.removeItem(x);
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  public setTimestamp(key: string, hoursTimeout: number) {
    let timeout = moment().add(hoursTimeout, "hours").unix();
    let timestamp: Timestamp = {
      value: key,
      timeout: timeout,
    };
    if (this.storageType) {
      let stamp = this.storageType.getItem("TIMESTAMP");
      let savedTimestamps = stamp ? JSON.parse(stamp) : {};
      savedTimestamps[key] = timestamp;
      this.storageType.setItem("TIMESTAMP", JSON.stringify(savedTimestamps));
    }
  }

  public isDataFresh(key: string) {
    return this.checkTimestamp(key);
  }

  public checkTimestamp(key: string) {
    if (this.storageType) {
      let timestamps = JSON.parse(this.storageType.getItem("TIMESTAMP"));
      if (!timestamps || !timestamps[key]) {
        return false;
      } else {
        let timeout = timestamps[key].timeout;
        let now = moment().unix();
        if (timeout < moment().unix()) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
}

export interface ICache {
  key: string;

  cache(value: any): void;

  cache(): any;

  findById(id: any, altProp?: string): any;

  clear(): void;
}

/**
 * Base class
 * Standardizes caching
 */
export class Cache implements ICache {
  // defaults to storing collections
  // override by setting the following:
  public isObjectCache = false;
  // optional function to fire before adding to cache
  public preAddFn: Function;
  // sub-classes should define their key
  private _key: string = null;
  // can optionally define a collection of keys they have access to
  private _keys: Array<string>;
  private ls: SecureLS;

  constructor(public storage: StorageService) {
    this.ls = new SecureLS({ encodingType: "aes" });
  }

  public get key() {
    return this._key;
  }

  public set key(value: string) {
    this._key = value;
  }

  public get keys() {
    return this._keys;
  }

  public set keys(value: Array<string>) {
    this._keys = value;
  }

  /**
   * Grab value from browser/device storage
   **/
  public get cache(): any {
    if (this._valid()) {
      return this.ls ? this.ls.get(this.key) : this.storage.getItem(this.key);
    }
    return undefined;
  }

  /**
   * Grab specific key (if managing multiple) value from browser/device storage
   **/
  public cacheForKey(key: string): any {
    if (this._valid()) {
      if (this.keys && this.keys.find((k) => k === key)) {
        return this.ls ? this.ls.get(key) : this.storage.getItem(key);
      } else {
        console.error(`Cache: '${key}' is not part of supported keys.`);
      }
    }
    return undefined;
  }

  /**
   * Store value in browser/device storage.
   * If using default collection, you can pass Array to re-cache entire collection.
   * Or you can pass object with id to find and update inside collection.
   * If object is not found by id, it is pushed onto the collection.
   * You can optionally pass value = {id: any, clearCache: true} to remove particular
   * object from the collection by it's id.
   * @param {any} value - The value to cache or update (if using default collection)
   **/
  public set cache(value: any) {
    if (this.key) {
      this._cacheValue(value);
    } else {
      this._logError();
    }
  }

  /**
   * Store value for specific key (if multiple)
   */
  public cacheKey(key: string, value: any) {
    this._cacheValue(value, key);
  }

  /**
   * Find object in cache collection.
   * @param {any} id - id searching for a match with.
   * @param {string} key - Optionally specify specific key (if using multiple)
   * @param {string} altProp Optionally fallback to match a differnet property
   * @returns {any} - The matching object or `undefined`.
   */
  public findById(id: any, key?: string, altProp?: string): any {
    const c = key ? this.cacheForKey(key) : this.cache;
    if (this._valid() && c) {
      return c.find((i) => {
        let match = i.id === id;
        if (!match && altProp) {
          // optionally fallback to match a differnet property
          match = i[altProp] === id;
        }
        return match;
      });
    }
    return undefined;
  }

  /**
   * Clear cache completely: remove from storage
   */
  public clear(): void {
    const keys = this.keys || [this.key];
    for (const key of keys) {
      if (this.ls) {
        this.ls.remove(key);
      } else {
        this.storage.removeItem(key);
      }
    }
  }

  private _cacheValue(val: any, key?: string) {
    let c: any;
    const specificKey = typeof key === "string" ? key : undefined;

    const value = this._serialize(val);

    if (this._valid()) {
      if (this.isObjectCache || Array.isArray(value)) {
        // re-store object everytime
        // ...or re-store incoming collections evertime (like resetting)
        c = value;
      } else {
        // incoming object, cache is default collection
        // get existing to deal with updating by id
        if (specificKey) {
          c = this.cacheForKey(specificKey);
        } else {
          c = this.cache;
        }
        if (c && Array.isArray(c)) {
          // find in array to update
          let removeIndex = -1;
          let updated = false;
          for (let i = 0; i < c.length; i++) {
            if (typeof c[i] !== "object") {
              console.error(`Cache: invalid value (not an object) in collection.`);
              console.error(c[i]);
              return;
            } else {
              if ((<any>c[i]).id === value.id) {
                if (value.clearCache) {
                  // remove from collection
                  removeIndex = i;
                } else {
                  // update value
                  c[i] = value;
                  updated = true;
                }
                break;
              }
            }
          }
          if (removeIndex > -1) {
            c.splice(removeIndex, 1);
          } else if (!updated) {
            if (this.preAddFn) {
              this.preAddFn(c);
            }
            // add new value to collection
            c.push(value);
          }
        } else {
          // default store values wrapped as collections
          c = [value];
        }
      }
      this.storage.setItem(specificKey || this.key, c);
    }
  }

  // auto serialize api models
  // IMPORTANT: This must always return immutable objects
  private _serialize(val: any) {
    let value = val;
    if (Array.isArray(val)) {
      // do not mutate original (this creates a new Array from the source)
      value = [...val];
      // for ( let i = 0; i < value.length; i++ ) {
      //   if ( value[i].serialize ) {
      //     // serialization supported
      //     // Pnp backend model instance
      //     value[i] = value[i].serialize();
      //   }
      // }
    } else if (val && isObject(val)) {
      // && val.serialize ) {
      // object supports a serialize method
      // likely PnP backend model instance
      // immutability - serialize returns a new object
      value = { ...val }; //val.serialize();
    }
    return value;
  }

  private _valid(): boolean {
    if (typeof this.key === "string" || this.keys) {
      return true;
    }
    this._logError(true);
    return false;
  }

  private _logError(both?: boolean) {
    console.error(`Cache: key ${both ? "or keys " : ""}must be set.`);
  }
}
